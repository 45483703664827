<template>
        <ul v-if="breadcrumbs" class="v-breadcrumbs custom-breadcrumbs theme--light">
            <li v-for="(item, idx) in breadcrumbs" :key="idx">
                <a href="javascript:void(0)" class="v-breadcrumbs__item text-truncate" style="display:block;max-width:150px;color:#9e9e9e;">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <router-link :class="{'text-underline' : item.underline}" v-if="!item.disabled" :to="item.href"><span v-bind="attrs" v-on="on">{{ item.text }}</span></router-link>
                            <span v-else v-bind="attrs" v-on="on">{{ item.text }}</span>
                        </template>
                        <span>{{ item.text }}</span>
                    </v-tooltip>
                </a>
                <i v-if="idx !== breadcrumbs.length - 1" aria-hidden="true" class="ml-2 v-icon notranslate mdi mdi-chevron-right theme--light"></i>
            </li>
            <li>
                <v-btn :disabled="disableBack" style="position:fixed;right:24px;" @click="back">戻る</v-btn>
            </li>
        </ul>
        <ul v-else class="pt-8 pb-8 v-breadcrumbs custom-breadcrumbs theme--light">
            <li>
                <v-btn :disabled="disableBack" style="position:fixed;right:24px;" @click="back">戻る</v-btn>
            </li>
        </ul>
</template>

<script>

export default {
  name: 'Subheader',
  props: {
      breadcrumbs: Array,
      disableBack: Boolean,
      backLink: String
  },
  data(){
    return {
      
    }
  },
  methods: {
      back() {
          if(this.backLink) {
              this.$router.push(this.backLink);
          } else {
              this.$router.go(-1);
          }
      }
  }
}

</script>

<style src="./Subheader.scss" lang="scss"></style>


