import ApiService from "@/services/api.service";
import config from "@/config";

export default {

    fetch_master(context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/api/campaign/getMaster")
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_CAMPAIGN_MASTER', response.data.data);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    fetch_address(context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/api/campaign/getMaster/address")
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_CAMPAIGN_ADDRESS', response.data.data ? response.data.data.address : []);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    fetch_facilities(context, payload) {
        return new Promise((resolve, reject) => {
            const { q } = payload;
            ApiService.get(`/api/campaign/getMaster/facility?name=${q || ''}`)
                .then(response => {
                    if(response.data.success) {
                        resolve(response.data.data.facilities);
                    } else {
                        resolve([]);
                    }
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    fetch_list_campaigns(context, payload) {
        let { page, client_id, sort_by, sort_type } = payload;
        if(!sort_by) sort_by = 'id';
        if(!sort_type) sort_type = 'desc';
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/campaign/list/${client_id}?page=${page}&sort=${sort_by}&order_by=${sort_type}`)
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_LIST_CAMPAIGNS', response.data.data);
                        context.commit('SET_CAMPAIGNS_META', response.data.meta);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    fetch_campaign_by_id(context, payload) {
        const { id } = payload;
        context.commit('SET_CAMPAIGN', {});
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/campaign/${id}`)
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_CAMPAIGN', response.data.data);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    create_campaign(context, payload) {
        const { client_id, name, campaign_category, campaign_type, release_start_at, release_end_at, imp_expected, imp_price, location_id, district_id, toilet_type, category_type, year, sex, facility_id, $toast, $router } = payload;
        return new Promise((resolve, reject) => {
            ApiService.post("/api/campaign", { client_id, name, campaign_category, campaign_type, release_start_at, release_end_at, imp_expected, imp_price, location_id, district_id, toilet_type, category_type, year, sex, facility_id })
                .then(response => {
                    if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                        $router.go(-1);
                    }
                    resolve(response);
                })
                .catch(e => {
                    if(e.response.data) {
                        if(e.response.data.message) {
                            if(e.response.data.message.name) {
                                $toast.error(e.response.data.message.name[0], config.toast);
                            }
                        }
                    }
                    reject(e.response);
                });
        });
    },
    update_campaign(context, payload) {
        const { uuid, client_id, name, campaign_category, campaign_type, release_start_at, release_end_at, imp_expected, imp_price, location_id, district_id, toilet_type, category_type, year, sex, facility_id, $toast, $router } = payload;
        return new Promise((resolve, reject) => {
            ApiService.put(`/api/campaign/${uuid}`, { client_id, name, campaign_category, campaign_type, release_start_at, release_end_at, imp_expected, imp_price, location_id, district_id, toilet_type, category_type, year, sex, facility_id })
                .then(response => {
                    if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                        $router.go(-1);
                    }
                    resolve(response);
                })
                .catch(e => {
                    if(e.response.data) {
                        if(e.response.data.message) {
                            if(e.response.data.message.name) {
                                $toast.error(e.response.data.message.name[0], config.toast);
                            }
                        }
                    }
                    reject(e.response);
                });
        });
    },
    update_enable(context, payload) {
        const { uuid, $toast } = payload;
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/campaign/isUpdateEnable/${uuid}`)
                .then(response => {
                    if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                        // $router.go(-1);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    empty(context) {
        context.commit('SET_LIST_CAMPAIGNS', []);
        context.commit('SET_CAMPAIGNS_META', {});
    }
}
