export default {
    SET_FACILITIES_META(state, data) {
        state.facilities_meta = data;
    },
    SET_LIST_FACILITIES(state, data) {
        state.list_facilities = data;
    },
    SET_FACILITIES_META_BY_CAMPAIGN(state, data) {
        state.facilities_meta_by_campaign = data;
    },
    SET_LIST_FACILITIES_BY_CAMPAIGN(state, data) {
        state.list_facilities_by_campaign = data;
    },
    SET_FACILITY(state, data) {
        state.facility = data;
    },
    SET_DESIRED_CAMPAIGNS(state, data) {
        state.desired_campaigns = data;
    },
    SET_FACILITY_CAMPAIGN(state, data) {
        state.facility_campaign = data;
    }
}