import Vue from 'vue';
import Vuex from 'vuex';

import auth from "@/store/auth/moduleAuth";
import client from "@/store/client/moduleClient";
import campaign from "@/store/campaign/moduleCampaign";
import creative from "@/store/creative/moduleCreative";
import facility from "@/store/facility/moduleFacility";
import toilet from "@/store/toilet/moduleToilet";
import upload from "@/store/upload/moduleUpload";
import download from "@/store/download/moduleDownload";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    client,
    campaign,
    creative,
    facility,
    toilet,
    upload,
    download
  },
  namespace: true,
  state: {
    drawer: true
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    }
  },
  actions: {
    TOGGLE_DRAWER({ commit }) {
      commit('toggleDrawer');
    }
  },
  getters: {
    DRAWER_STATE(state) {
      return state.drawer;
    }
  }
});
