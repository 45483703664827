<template>
    <v-dialog v-model="show" persistent max-width="600px">
        <v-card>
            <v-card-title>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row class="px-6 py-6 d-flex" style="flex-direction:column;">
                        <div class="text-center">
                            <h3>媒体施設 絞り込み</h3>
                        </div>
                        <div class="d-flex align-center">
                            <table>
                                <tr>
                                    <td :style="{width: '100px'}">施設名</td>
                                    <td>
                                        <v-text-field :style="{width: '300px'}" v-model="name"></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td :style="{width: '100px'}">住所</td>
                                    <td>
                                        <v-text-field :style="{width: '300px'}" v-model="address"></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td :style="{width: '100px'}">施設区分</td>
                                    <td class="d-flex gap-20">
                                        <v-checkbox v-model="type" label="商業" value="3"></v-checkbox>
                                        <v-checkbox v-model="type" label="オフィス" value="2"></v-checkbox>
                                        <v-checkbox v-model="type" label="飲食店" value="1"></v-checkbox>
                                    </td>
                                </tr>
                            </table>
                            
                        </div>
                        <div class="mt-10 d-flex justify-center gap-20">
                            <v-btn @click="show=false">
                                キャンセル
                            </v-btn>
                            <v-btn color="primary" @click="filter">
                                絞り込み
                            </v-btn>
                        </div>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>


export default {
    name: "FilterModal",
    props: {
        value: Boolean
    },
    data() {
        return {
            name: '',
            address: '',
            type: []
        }
    },
    computed: {
        show: {
            get () {
                return this.value;
            },
            set (value) {
                this.$emit('input', value);
            }
        },
    },
    methods: {
        filter() {
            this.$emit('onFilter', {name: this.name, address: this.address, type: this.type});
        }
    }
};
</script>

