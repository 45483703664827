<template>
  <v-container fluid>
    <Subheader :breadcrumbs="breadcrumbs" :backLink="backLink" />
    <ConfirmModal ref="cfmodal" />
    <div class="tables-basic">
      <h4 class="page-title mt-10 mb-6"></h4>

        <v-dialog v-model="show_edit_status" persistent max-width="600px">
            <v-card>
                <v-card-title>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <v-row class="px-6 py-6">
                        <!-- ng_category -->
                        <v-select
                            v-model="status"
                            :items="facility_status_options"
                            item-text="text"
                            item-value="value"
                            label="ステータス"
                            no-data-text="データがありません"
                        ></v-select>
                    </v-row>
                </v-container>
                </v-card-text>
                <v-card-actions class="px-6 py-6">
                    <v-spacer></v-spacer>
                    <v-btn @click="show_edit_status=false">
                        閉じる
                    </v-btn>
                    <v-btn color="primary" @click="change_professions()">
                        保存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-form ref="form">
              <v-container>
                <v-row class="flex-column" align="center" justify="center">
                  <div class="col d-flex">
                    <h4 class="px-3 pt-2 font-weight-medium text-truncate" style="max-width:80%;">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{ name }}</span>
                            </template>
                            <span>{{ name }}</span>
                        </v-tooltip>
                    </h4>
                  </div>

                        <div class="col">
                            <v-col cols="12">
                                <p>施設固有動画一覧</p>
                            </v-col>
                            <input id="file" type="file" @change="onFileChange">
                            <v-col cols="12">
                                <v-btn class="text-capitalize mt-6" color="primary" width="100%" type="button" @click="csvImport">CSVインポート</v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-btn class="text-capitalize mt-6" color="primary" width="100%" type="button" @click="downloadCSV">CSVファイルをダウンロード</v-btn>
                            </v-col>
                        </div>

            <v-tabs grow>
                <v-tabs-slider></v-tabs-slider>
                <v-tab :href="`#manager`">施設固有動画一覧</v-tab>
                <!-- <v-tab :href="`#suggest`">提案機能</v-tab>
                <v-tab :href="`#report`">レポーティング</v-tab> -->

                <v-tab-item :value="'manager'" >
                    <v-card class="employee-list mb-1">
                        <div class="my-4 mx-6">
                            <v-btn color="primary" @click="show_filter">絞り込み</v-btn>
                        </div>
                        <v-data-table
                        :headers="headers"
                        :items="creative_data"
                        :loading="loading"
                        loading-text="読み込み中"
                        :options.sync="options"
                        @pagination="update_page"
                        :server-items-length="total"
                        :items-per-page="items_per_page"
                        :page="page"
                        :footer-props="{
                            'disable-items-per-page': true,
                            'items-per-page-options': [ 50, 100 ],
                            'items-per-page-text':'ページごとに表示する件数'
                        }"
                        item-key="id"
                        >
                            <template v-slot:[`item.sex_display`]="{ item }">
                                {{ item.sex_display ? item.sex_display.name : '' }}
                            </template>
                            <template v-slot:[`item.facility_name`]="{ item }">
                                {{ item.facility ? item.facility.name : '' }}
                            </template>
                            <template v-slot:[`item.address`]="{ item }">
                                {{ item.facility ? item.facility.address : '' }}
                            </template>
                            <template v-slot:[`item.toilet_id`]="{ item }">
                                <div class="text-truncate" style="max-width: 150px;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ item.toilet_id }}</span>
                                        </template>
                                        <span>{{ item.toilet_id }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.gateway_id`]="{ item }">
                                <div class="text-truncate" style="max-width: 150px;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ item.gateway_id }}</span>
                                        </template>
                                        <span>{{ item.gateway_id }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-menu :key="item.id" offset-y open-on-hover>
                                    <template v-slot:activator="{ attrs, on }">
                                        <span class="text-actions" v-bind="attrs" v-on="on">アクション</span>
                                    </template>

                                    <v-list>
                                        <v-list-item v-for="act in [{text: '施設詳細', link: `/facilities/${item.facility.id}/detail`}]" :key="act.text" link :to="act.link">
                                            <v-list-item-title>
                                                {{ act.text }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                            <template v-slot:no-data>
                                データがありません
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>
            </v-tabs>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.19.0/axios.min.js"></script>
<script>

import Subheader from '@/components/Subheader/Subheader'
import { bank_options, branch_options } from '@/constants/bank'
import ConfirmModal from '@/components/ConfirmModal'

export default {
  name: "MovieFacility",
  components: {
      Subheader,
      ConfirmModal
  },
  data() {
    return {
              headers: [
            { text: "No", align: "start", value: "No", width: "150px", sortable: true },
            { text: "施設クリエイティブ名", value: "creative_name", sortable: true },
            { text: "ステータス", value: "status", sortable: true },
            { text: "imp数", value: "imp_num", sortable: true },
            { text: "アクション", value: "actions", width: "120px", sortable: false },
        ],
      breadcrumbs: [
            {
                text: '媒体施設一覧',
                disabled: false,
                underline: true,
                href: '/facilities?page=1',
            },
            {
                text: '媒体施設詳細',
                disabled: false,
                underline: true,
                href: `/facilities/${this.$route.params.id}/detail`,
            },
            {
                text: '施設動画管理',
                disabled: true,
                underline: false,
                href: '#',
            },
        ],

      id: null,
      name: "",
      type: "",
      options:{},
      loading: false,
      location_id: null,
      location_name: "",
      district_id: null,
      district_name: "",
      zip_code: null,
      house_number: "",
      building_name: "",
      working_hours: null,
      phone_number: null,
      email: "",
      person_in_charge: "",
      bank: "",
      branch_bank: "",
      currency: null,
      start_business_hours: "",
      end_business_hours: "",
      account_number: null,
    //   min_age: null,
    //   max_age: null,
      target_age: "",
      revenue_share_percent: null,
      status: null,
      ng_profession_id: null,
      professions: null,

      type_display: null,
      location_display: null,
      district_display: null,

      facility_type_options: [
        { text: '商業', value: 1 },
        { text: 'オフィス', value: 2 },
        { text: 'コンビニ', value: 3 },
        { text: 'カフェ', value: 4 },
        { text: 'パチンコ', value: 5 },
        { text: '飲食', value: 6 },
      ],
      facility_status_options: [
        { text: '稼働中', value: 1 },
        { text: '停止中', value: 2 },
      ],
      currencies_options: [
        { text: '普通', value: '普通' },
        { text: '当座', value: '当座' },
      ],
      district_options: [],
      branch_select_options: [],

      min_age_validation: [v => !!v || '必須項目のため入力してください'],
      max_age_validation: [v => !!v || '必須項目のため入力してください'],

      show_edit: false,
      show_edit_type: false,
      show_edit_status: false,
      csvFile: null,
      csvErrors: [],
      csvData:[],
      creative_data: [],

    };
  },
  watch: {
      list_creatives(val) {
        console.log(val)
        for(var i in val) {
            val[i]["no"] = parseInt(i) + 1;
            val[i]["created_at"] = val[i]["created_at"] ? `${new Date(val[i]["created_at"]).toLocaleDateString("ja-JP")}` : null;
            val[i]["updated_at"] = val[i]["updated_at"] ? `${new Date(val[i]["updated_at"]).toLocaleDateString("ja-JP")}` : null;
        }
        this.creative_data = val;
    },
    options: {
        handler () {
          this.fetch_list_creatives()
        },
        deep: true,
      },
  },
  computed: {
      page() {
          if(this.$route.query.page) {
              return parseInt(this.$route.query.page);
          }
          const meta = this.$store.getters['creative/get_creatives_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.current_page) {
                      return meta.pagination.current_page;
                  }
              }
          }
          return 1;
      },
      total() {
          const meta = this.$store.getters['creative/get_creatives_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.total) {
                      return meta.pagination.total;
                  }
              }
          }
          return 0;
      },
      items_per_page() {
          const meta = this.$store.getters['creative/get_creatives_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.per_page) {
                      return meta.pagination.per_page;
                  }
              }
          }
          return 0;
      },
    fetch_list_creatives() {
        const self = this;
        self.loading = true;
        const page = this.$route.query.page || 1;
        // if(!page) return;
        const payload = { page, client_id: this.client_id, campaign_uuid: this.campaign_uuid };
        
        const { sortBy, sortDesc } = this.options
        payload.sort_by = sortBy[0] ? sortBy[0] : 'id';
        payload.sort_type = sortDesc[0] ? 'desc' : 'asc';

        this.$router.push({
            name: "MovieFacility",
            query: { page, client_id: this.client_id, campaign_id: this.campaign_id, campaign_uuid: this.campaign_uuid }
        }).catch(() => { });

        // this.$store.dispatch("creative/fetch_list_creatives", payload).then(() => {
        //     self.loading = false;
        // }).catch(() => {
        //     self.loading = false;
        // });
    },
    update_page(pagination) {
        const page  = pagination.page;

        // this.$router.push({
        //     name: "MovieFacility",
        //     query: { page, client_id: this.client_id, campaign_id: this.campaign_id, campaign_uuid: this.campaign_uuid }
        // }).catch(() => { });
        // this.fetch_list_creatives();
    },
      ng_category_options() {
          return this.$store.getters['client/get_list_professions'];
      },
      location_options() {
        let locations = this.$store.getters['campaign/get_campaign_address'];
        locations = locations.filter(e => e.districts && e.districts.length > 0);
        return locations;
      },
      facility() {
          return this.$store.getters['facility/get_facility'];
      },
      number_male_toilet() {
        var toilets = this.$store.getters['facility/get_facility'] ? this.$store.getters['facility/get_facility'].toilets : [];
        var men_toilets = toilets ? toilets.filter(el => el.sex == 1) : [];
        return men_toilets ? men_toilets.length : 0;
      },
      number_female_toilet() {
        var toilets = this.$store.getters['facility/get_facility'] ? this.$store.getters['facility/get_facility'].toilets : [];
        var men_toilets = toilets ? toilets.filter(el => el.sex == 2) : [];
        return men_toilets ? men_toilets.length : 0;
      },
      number_ostomate_toilet() {
        var toilets = this.$store.getters['facility/get_facility'] ? this.$store.getters['facility/get_facility'].toilets : [];
        var men_toilets = toilets ? toilets.filter(el => el.sex == 3) : [];
        return men_toilets ? men_toilets.length : 0;
      },
      backLink() {
        return '/facilities?page=1';
      },
      bank_select_options() {
        return bank_options;
      },
    business_hours() {
        var business_hours = [];
        for (let index = 7; index < 24; index++) {
          business_hours.push(index+":"+"00",index+":"+"30");
        }
        return business_hours;
      },
      target_age_options() {
        return ["10代","20代","30代","40代","50代","60代","70代","80代",]
      }
  },
  methods: {
    show_filter() {
        this.showFilterModal = true;
    },
    csvImport() {
        this.csvData = [] // csvDataを初期化
        const csvfile = this.csvFile
        const reader = new FileReader()

        const loadCSV = () => {
          // const lines = reader.result.split('\n') // 改行毎にデータを分ける
          const lines = reader.result // 改行毎にデータを分ける
          var jsonLines = JSON.stringify(lines);
          console.log(jsonLines);
          // // csvファイルの各行をcsvDataにオブジェクトとしてpushする
          // lines.forEach((element, index) => {
          //   var workerData = element.split(',') // 区切り文字はカンマ
          //   this.csvData.push(workerData)
          // })
                    this.csvErrors = [];
          // console.log(jsonCsvData)
          this.$store.dispatch("facility/creative_csv_import",
                {
                    id: this.id,
                    csvData:jsonLines,
                    $toast: this.$toast,
                    $router: this.$router
                }
            ).then(() => {
                console.log("これ")
            });
        }
        reader.onload = loadCSV
        reader.readAsText(csvfile)
    },
    onFileChange(e) {
      this.csvFile = e.target.files[0];
    },
    downloadCSV() {
    this.$store.dispatch("download/csv", { url: `/api/facility/${this.id}/download/csv`}).then((res) => {
        const contentDisposition = res.headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch.length === 2)
                fileName = fileNameMatch[1];
        }
        const download_url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = download_url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }).catch((e) => {
        console.log(e)
    });
},
  },
  created() {
    this.id = this.$route.params.id;
  }
};
</script>

<style src="./Facility.scss" lang="scss"/>