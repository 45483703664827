import ApiService from "@/services/api.service";
import config from "@/config";

export default {

    fetch_list_creatives(context, payload) {
        let { page, per_page, client_id, campaign_uuid, sort_by, sort_type } = payload;
        if(!sort_by) sort_by = 'id';
        if(!sort_type) sort_type = 'desc';
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/creative/list/${client_id}/${campaign_uuid}?page=${page}&per_page=${per_page || 50}&sort=${sort_by}&order_by=${sort_type}`)
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_LIST_CREATIVES', response.data.data);
                        context.commit('SET_CREATIVES_META', response.data.meta);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    fetch_list_creatives_from_ok_campaign(context, payload) {
        let { page, per_page, client_id, facility_id, campaign_uuid, sort_by, sort_type } = payload;
        if(!sort_by) sort_by = 'id';
        if(!sort_type) sort_type = 'desc';
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/creative/list_from_ok_campaign/${facility_id}/${client_id}/${campaign_uuid}?page=${page}&per_page=${per_page || 50}&sort=${sort_by}&order_by=${sort_type}`)
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_LIST_CREATIVES_FROM_OK_CAMPAIGN', response.data.data);
                        context.commit('SET_CREATIVES_META_FROM_OK_CAMPAIGN', response.data.meta);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    fetch_creative_by_id(context, payload) {
        const { id } = payload;
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/creative/${id}`)
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_CREATIVE', response.data.data);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    create_creative(context, payload) {
        const {
            name,
            client_id,
            campaign_id,
            file_name_video,
            content_type_video,
            size_video,
            file_name_thumbnail,
            content_type_thumbnail,
            size_thumbnail,
            file_name_video_2,
            content_type_video_2,
            size_video_2,
            file_name_video_3,
            content_type_video_3,
            size_video_3,
            file_name_video_4,
            content_type_video_4,
            size_video_4,
            file_name_video_5,
            content_type_video_5,
            size_video_5,
            file_name_video_6,
            content_type_video_6,
            size_video_6,
            file_name_video_7,
            content_type_video_7,
            size_video_7,
            file_name_video_8,
            content_type_video_8,
            size_video_8,
            file_name_video_9,
            content_type_video_9,
            size_video_9,
            file_name_video_10,
            content_type_video_10,
            size_video_10,
            file_name_video_11,
            content_type_video_11,
            size_video_11,
            file_name_video_12,
            content_type_video_12,
            size_video_12,
            file_name_video_13,
            content_type_video_13,
            size_video_13,
            file_name_video_14,
            content_type_video_14,
            size_video_14,
            file_name_video_15,
            content_type_video_15,
            size_video_15,
            file_name_audio,
            content_type_audio,
            size_audio,

            // $toast, $router
         } = payload;
        return new Promise((resolve, reject) => {
            ApiService.post("/api/creative", {
                name,
                client_id,
                campaign_id,
                file_name_video,
                content_type_video,
                size_video,
                file_name_thumbnail,
                content_type_thumbnail,
                size_thumbnail,
                file_name_video_2,
                content_type_video_2,
                size_video_2,
                file_name_video_3,
                content_type_video_3,
                size_video_3,
                file_name_video_4,
                content_type_video_4,
                size_video_4,
                file_name_video_5,
                content_type_video_5,
                size_video_5,
                file_name_video_6,
                content_type_video_6,
                size_video_6,
                file_name_video_7,
                content_type_video_7,
                size_video_7,
                file_name_video_8,
                content_type_video_8,
                size_video_8,
                file_name_video_9,
                content_type_video_9,
                size_video_9,
                file_name_video_10,
                content_type_video_10,
                size_video_10,
                file_name_video_11,
                content_type_video_11,
                size_video_11,
                file_name_video_12,
                content_type_video_12,
                size_video_12,
                file_name_video_13,
                content_type_video_13,
                size_video_13,
                file_name_video_14,
                content_type_video_14,
                size_video_14,
                file_name_video_15,
                content_type_video_15,
                size_video_15,
                file_name_audio,
                content_type_audio,
                size_audio,
            })
                .then(response => {
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    update_creative(context, payload) {
        const { id, name, client_id, campaign_id, file_name_video, content_type_video, size_video, file_name_thumbnail, content_type_thumbnail, size_thumbnail, path_thumbnail, path_video,
            // $toast, $router
         } = payload;
        return new Promise((resolve, reject) => {
            ApiService.put(`/api/creative/${id}`, { name, client_id, campaign_id, file_name_video, content_type_video, size_video, file_name_thumbnail, path_thumbnail, path_video, content_type_thumbnail, size_thumbnail })
                .then(response => {
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    update_get_presigned_thumbnail(context, payload) {
        const { file_name_thumbnail, content_type_thumbnail, size_thumbnail } = payload;
        return new Promise((resolve, reject) => {
            ApiService.post('/api/creative/getPreSigned', { type: 2, file_name_thumbnail, content_type_thumbnail, size_thumbnail })
                .then(response => {
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    update_get_presigned_video(context, payload) {
        const { file_name_video, content_type_video, size_video } = payload;
        return new Promise((resolve, reject) => {
            ApiService.post('/api/creative/getPreSigned', { type: 1, file_name_video, content_type_video, size_video })
                .then(response => {
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    update_enable(context, payload) {
        const { id, $toast } = payload;
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/creative/isUpdateEnable/${id}`)
                .then(response => {
                    if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                        // $router.go(-1);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    fetch_s3_link(context, payload) {
        const { creative_id } = payload;
        return new Promise((resolve, reject) => {
            ApiService.get(`api/creative/getTemporaryURL/${creative_id}`)
                .then(response => {
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    empty(context) {
        context.commit('SET_LIST_CREATIVES', []);
        context.commit('SET_CREATIVES_META', {});
    }
}
