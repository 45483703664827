<template>
  <v-container fluid>
    <Subheader />
    <loading :active.sync="isLoading" :is-full-page="true" color="#8089ff" loader="bars"></loading>
    <div class="tables-basic">
      <h4 class="page-title mt-10 mb-6"></h4>
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-form ref="form">
              <v-container>
                <v-row class="flex-column" align="center" justify="center">
                  <v-col>
                    <p class="login-slogan display-1 text-center font-weight-medium mt-6 mb-6">
                      クリエイティブを編集する
                    </p>
                  </v-col>
                  <v-col cols="6" class="mb-10">
                    <v-text-field
                      v-model="name"
                      :rules="[v => !!v || '必須項目のため入力してください']"
                      label="クリエイティブ名"
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="file_name_video"
                      append-icon="mdi-paperclip"
                      :rules="[video_file != null ? video_file.type.indexOf('video') > -1 || '動画ファイル形式以外のファイルが選択されています。' : true]"
                      label="クリエイティブファイルアップロード"
                      readonly
                      @click="select_video_file"
                    ></v-text-field>
                    <v-text-field
                      v-model="file_name_thumbnail"
                      append-icon="mdi-paperclip"
                      :rules="[thumbnail_file != null ? thumbnail_file.type.indexOf('image') > -1 || '画像ファイル形式以外のファイルが選択されています。' : true]"
                      label="クリエイティブファイルアップロード"
                      readonly
                      @click="select_thumbnail_file"
                    ></v-text-field>
                     <input accept="video/mp4,video/x-m4v,video/*" type="file" ref="input_video" style="display:none" @change="handle_upload_file">
                     <input accept="image/*" type="file" ref="input_thumbnail" style="display:none" @change="handle_upload_thumbnail">
                    <v-btn
                      class="text-capitalize mt-6"
                      color="primary"
                      width="100%"
                      @click="submit"
                    >
                      保存</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Loading from 'vue-loading-overlay';
import config from "@/config";
import Subheader from '@/components/Subheader/Subheader'

export default {
  name: "CreateCreative",
  components: { Loading, Subheader },
  data() {
    return {
      isLoading: false,
      id: null,
      name: "",
      campaign_uuid: "",
      client_id: "",
      file_name_video: "",
      content_type_video: "",
      size_video: 0,
      path_video: "",
      file_name_thumbnail: "",
      content_type_thumbnail: "",
      size_thumbnail: 0,
      path_thumbnail: "",
      video_file: null,
      thumbnail_file: null,
    };
  },
  computed: {
    creative() {
        return this.$store.getters['creative/get_creative'];
    }
  },
  watch: {
      creative(val) {
          this.id = val.id;
          this.name = val.name;
          this.campaign_uuid = val.campaign_id;
          this.client_id = val.client_id;
          
          if(val.uploads) {
              for(var i in val.uploads) {
                  if(val.uploads[i].content_type.indexOf('video') > -1) {
                      this.file_name_video = val.uploads[i].file_name;
                      this.content_type_video = val.uploads[i].content_type;
                      this.size_video = val.uploads[i].size;
                      this.path_video = val.uploads[i].path;
                  }
                  if(val.uploads[i].content_type.indexOf('image') > -1) {
                      this.file_name_thumbnail = val.uploads[i].file_name;
                      this.content_type_thumbnail = val.uploads[i].content_type;
                      this.size_thumbnail = val.uploads[i].size;
                      this.path_thumbnail = val.uploads[i].path;
                  }
              }
          }
      }
  },
  methods: {
    async handle_upload_file(e) {
        try {
            var self = this;
            self.isLoading = true;
            var file = e.target.files ? e.target.files[0] : null
            if(file) {
                this.video_file = file;
                this.file_name_video = file.name;
                this.content_type_video = file.type;
                this.size_video = file.size;

                const data = await this.$store.dispatch("creative/update_get_presigned_video", {file_name_video: this.file_name_video, content_type_video: this.content_type_video, size_video: this.size_video});
                const presigned_url = data.data.data.pre_signed_video;
                const file_path_video = data.data.data.file_path_video;
                this.path_video = file_path_video;

                this.$store.dispatch("upload/to_s3", {url: presigned_url, data: self.video_file, content_type: self.content_type_video})
                .then(res => {
                  if(res.status == 200) {
                    self.isLoading = false;
                  } else {
                    self.isLoading = false;
                    this.$toast.error("アップロードに失敗しました", config.toast);
                  }
                })
                .catch(e => {
                  console.log(e)
                  self.isLoading = false;
                  this.$toast.error("アップロードに失敗しました", config.toast);
                });
            }
        } catch(e) {
            console.log(e)
            this.$toast.error("アップロードに失敗しました", config.toast);
        }
        
    },
    async handle_upload_thumbnail(e) {
        try {
            var self = this;
            self.isLoading = true;
            var file = e.target.files ? e.target.files[0] : null
            if(file) {
                this.thumbnail_file = file;
                this.file_name_thumbnail = file.name;
                this.content_type_thumbnail = file.type;
                this.size_thumbnail = file.size;

                const data = await this.$store.dispatch("creative/update_get_presigned_thumbnail", {file_name_thumbnail: this.file_name_thumbnail, content_type_thumbnail: this.content_type_thumbnail, size_thumbnail: this.size_thumbnail});
                const presigned_url = data.data.data.pre_signed_thumbnail;
                const file_path_thumbnail = data.data.data.file_path_thumbnail;
                this.path_thumbnail = file_path_thumbnail;

                this.$store.dispatch("upload/to_s3", {url: presigned_url, data: self.thumbnail_file, content_type: self.content_type_thumbnail})
                .then(res => {
                  if(res.status == 200) {
                    self.isLoading = false;
                  } else {
                    self.isLoading = false;
                    this.$toast.error("アップロードに失敗しました", config.toast);
                  }
                })
                .catch(e => {
                  console.log(e)
                  self.isLoading = false;
                  this.$toast.error("アップロードに失敗しました", config.toast);
                });
            }
        } catch(e) {
            console.log(e)
            this.$toast.error("アップロードに失敗しました", config.toast);
        }
    },
    select_video_file() {
        this.$refs.input_video.click();
    },
    select_thumbnail_file() {
        this.$refs.input_thumbnail.click();
    },
    submit() {
      if(this.$refs.form.validate()) {
        const self = this;
        this.isLoading = true;
        this.$store.dispatch("creative/update_creative", { id: this.id, name: this.name, client_id: this.client_id, campaign_id: this.campaign_uuid,
        file_name_video: this.file_name_video, content_type_video: this.content_type_video, size_video: this.size_video,
        file_name_thumbnail: this.file_name_thumbnail, content_type_thumbnail: this.content_type_thumbnail, size_thumbnail: this.size_thumbnail,
        path_thumbnail: this.path_thumbnail, path_video: this.path_video,
        $toast: this.$toast, $router: this.$router })
        .then(response => {
            if(response.data.success) {
                self.isLoading = false;
                this.$toast.success("正常に処理が完了しました!", config.toast);
                this.$router.go(-1);
            } else {
                this.$toast.error("アップロードに失敗しました", config.toast);
            }
        })
        .catch(e => {
          console.log(e);
        });
      }
    },
    formatDate(date) {
        if(!date) return '';
        return `${new Date(date).getFullYear()}/${new Date(date).getMonth() < 9 ? '0' + (new Date(date).getMonth() + 1) : (new Date(date).getMonth() + 1)}/${new Date(date).getDate() < 10 ? '0' + new Date(date).getDate() : new Date(date).getDate()}`;
    },
  },
  async created() {
    if(this.$route.params.id) {
        this.isLoading = true;
        await this.$store.dispatch("creative/fetch_creative_by_id", { id: this.$route.params.id })
        this.isLoading = false;
    }

    // this.$store.dispatch("client/fetch_client_by_id", { id: this.client_id })
    // this.$store.dispatch("campaign/fetch_campaign_by_id", { id: this.campaign_id })
  }
};
</script>

<style src="./Creative.scss" lang="scss"></style>
