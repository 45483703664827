<template>
  <v-app>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" class="login-part d-flex align-center justify-center">
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper pt-md-4 pt-0">
                <v-form>
                      <v-container>
                        <v-row class="flex-column">
                          <v-col>
                            <p class="login-slogan display-2 text-center font-weight-medium mb-10">ログイン</p>
                          </v-col>
                          <v-form ref="loginForm">
                            <v-col>
                              <v-text-field
                                  v-model="email"
                                  :rules="emailRules"
                                  label="メールアドレス"
                                  required
                              ></v-text-field>
                              <v-text-field
                                  v-model="password"
                                  :rules="passRules"
                                  type="password"
                                  label="パスワード"
                                  required
                              ></v-text-field>

                            </v-col>
                            
                          </v-form>
                          <v-col class="d-flex justify-center">
                              <v-btn
                                  class="text-capitalize"
                                  large
                                  :disabled="password.length === 0 || email.length === 0"
                                  color="primary"
                                  @click="login"
                              >
                                ログイン</v-btn>
                              <!-- <v-btn large text class="text-capitalize primary--text">Forget Password</v-btn> -->
                            </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import jwtService from '@/services/jwt.service'
  export default {
    name: 'Login',
    data() {
      return {
        email: 'admin@admin.com',
        emailRules: [
          v => !!v || 'メールが必要です',
          v => /.+@.+/.test(v) || 'メールのフォーマットが正しくありません',
        ],
        password: '123456',
        passRules: [
          v => !!v || 'パスワードが必要です',
          v => v.length >= 6 || '半角英数字6桁以上で入力してください'
        ]
      }
    },
    methods: {
      login(){
        if(this.$refs.loginForm.validate()) {
          this.$store.dispatch("auth/sign_in", { email: this.email, password: this.password, $toast: this.$toast, $router: this.$router })
        }
      }
    },
    created() {
      if (jwtService.getToken()) {
        this.$router.push('/clients');
      }
    }
  }

</script>

<style src="./Login.scss" lang="scss"/>
