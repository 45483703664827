import ApiService from "@/services/api.service";

export default {

    csv(context, payload) {
        const { url } = payload;
        return new Promise((resolve, reject) => {
            ApiService.get_download(url)
                .then(response => {
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
}
