<template>
    <v-dialog v-model="show" persistent max-width="600px">
        <v-form ref="dlform">
        <v-card>
            <v-card-title>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row class="px-6 py-6 d-flex" style="flex-direction:column;">
                        <div class="text-center">
                            <h3>{{ title }}</h3>
                            <h5>{{ subtitle }}</h5>
                        </div>
                        <div v-if="extendLabel && extendValue" class="d-flex mt-8 align-center gap-20">
                            <span>{{ extendLabel }}</span>
                            <span>{{ extendValue }}</span>
                        </div>
                        <div class="d-flex align-center gap-20">
                            <span>期間</span>
                            <v-menu
                                ref="menu1"
                                v-model="menu1"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    v-model="dateRangeText"
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[v => !!v || '必須項目のため入力してください', v => dates ? ((dates[0] && dates[1]) ? new Date(dates[0]) <= new Date(dates[1]) || '開始期間が終了期間より後の日付になっています。' : true) : true]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="dates"
                                    range
                                ></v-date-picker>
                            </v-menu>
                            
                        </div>
                        <div class="mt-10 d-flex justify-center gap-20">
                            <v-btn @click="show=false">
                                キャンセル
                            </v-btn>
                            <v-btn color="primary" @click="downloadReport">
                                CSVをエクスポート
                            </v-btn>
                        </div>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </v-card>
        </v-form>
    </v-dialog>
</template>

<script>


export default {
    name: "DLModal",
    props: {
        value: Boolean,
        url: String,
        title: String,
        subtitle: String,
        extendLabel: String,
        extendValue: String,
    },
    data() {
        return {
            menu1: false,
            dates: []
        }
    },
    computed: {
        show: {
            get () {
                return this.value;
            },
            set (value) {
                if(!value) this.dates = [];
                this.$emit('input', value);
            }
        },
        dateRangeText() {
            return this.dates.join(' ~ ')
        }
    },
    methods: {
        downloadReport() {
            if(!this.url) return;
            if(this.$refs.dlform.validate()) {
                this.$store.dispatch("download/csv", { url: `${this.url}?start=${encodeURIComponent(this.dates[0] + ' 00:00:00')}&end=${encodeURIComponent(this.dates[1] + ' 23:59:59')}`}).then((res) => {
                    const contentDisposition = res.headers['content-disposition'];
                    let fileName = 'unknown';
                    if (contentDisposition) {
                        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                        if (fileNameMatch.length === 2)
                            fileName = fileNameMatch[1];
                    }
                    const download_url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = download_url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                }).catch((e) => {
                    console.log(e)
                });
            }
        }
    }
};
</script>

