import ApiService from "@/services/api.service";
import config from "@/config";

export default {

    sign_in(context, payload) {
        return new Promise((resolve, reject) => {
            var { email, password, $toast, $router } = payload;
            ApiService.post("/api/user/login-password", { email, password })
                .then(response => {
                    if(response.data.messages) {
                        $toast.clear();
                        $toast.error(config.translateText[response.data.messages[0]] || response.data.messages[0], config.toast);
                    } else {
                        if(response.data.token) {
                            $toast.clear();
                            $toast.success("正常にログイン", config.toast);
                            context.commit('SET_AUTH', { token: response.data.token });
                            $router.push('/clients');
                        }
                    }
                    resolve(response);
                })
                .catch(e => {
                    if(e.response.data.messages) {
                        $toast.clear();
                        $toast.error(config.translateText[e.response.data.messages[0]] || e.response.data.messages[0], config.toast);
                    }
                    reject(e.response);
                });
        });
    },
}
