<template>
  <v-container fluid>
    <Subheader />
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#8089ff"
      loader="bars"
    ></loading>
    <div class="tables-basic">
      <h4 class="page-title mt-10 mb-6"></h4>
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-form ref="form">
              <v-container>
                <v-row class="flex-column" align="center" justify="center">
                  <v-col>
                    <p
                      class="login-slogan display-1 text-center font-weight-medium mt-6 mb-6"
                    >
                      クリエイティブ新規追加
                    </p>
                  </v-col>
                  <v-col cols="6" class="mb-10">
                    <div class="create-creative__info">
                      登録先クライアント名:
                      {{ client_info ? client_info.name : "" }}
                    </div>
                    <div class="create-creative__info">
                      登録先キャンペーン名:
                      {{ campaign_info ? campaign_info.name : "" }}
                    </div>
                    <div class="create-creative__info">
                      配信期間:
                      {{
                        campaign_info
                          ? `${formatDate(
                              campaign_info.release_start_at
                            )} - ${formatDate(campaign_info.release_end_at)}`
                          : ""
                      }}
                    </div>
                    <v-text-field
                      v-model="name"
                      :rules="[(v) => !!v || '必須項目のため入力してください']"
                      label="クリエイティブ名"
                      required
                    ></v-text-field>
                    <v-file-input
                      accept="image/*"
                      label="サムネイルアップロード"
                      append-icon="mdi-paperclip"
                      prepend-icon=""
                      :rules="[
                        (v) => !!v || '必須項目のため入力してください',
                        (v) =>
                          v
                            ? v.type.indexOf('image') > -1 ||
                              '画像ファイル形式以外のファイルが選択されています。'
                            : '',
                      ]"
                      @change="handle_upload_thumbnail"
                    ></v-file-input>
                    <v-file-input
                      accept="audio/*"
                      label="音声ファイル"
                      append-icon="mdi-paperclip"
                      prepend-icon=""
                      @change="handle_upload_file_audio"
                    ></v-file-input>
                    <v-file-input
                      accept="video/mp4,video/x-m4v,video/*"
                      label="クリエイティブファイルアップロード1"
                      append-icon="mdi-paperclip"
                      prepend-icon=""
                      :rules="[
                        (v) => !!v || '必須項目のため入力してください',
                        (v) =>
                          v
                            ? v.type.indexOf('video') > -1 ||
                              '動画ファイル形式以外のファイルが選択されています。'
                            : '',
                      ]"
                      @change="handle_upload_file"
                    ></v-file-input>
                    <v-file-input
                      v-for="n in 14"
                      :key="n"
                      accept="video/mp4,video/x-m4v,video/*"
                      :label="`クリエイティブファイルアップロード ${n + 1}`"
                      append-icon="mdi-paperclip"
                      prepend-icon=""
                      @change="handle_upload_files($event, n + 1)"
                    ></v-file-input>
                    <v-btn
                      class="text-capitalize mt-6"
                      color="primary"
                      width="100%"
                      @click="submit"
                    >
                      クリエイティブ登録する</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import config from "@/config";
import Subheader from "@/components/Subheader/Subheader";

export default {
  name: "CreateCreative",
  components: { Loading, Subheader },
  data() {
    return {
      isLoading: false,
      name: "",
      file_name_video: "",
      content_type_video: "",
      size_video: 0,
      file_name_thumbnail: "",
      content_type_thumbnail: "",
      size_thumbnail: 0,
      audio_file: null,
      size_audio:0,
      content_type_audio:"",
      video_file: null,
      video_file_2: null,
      video_file_3: null,
      video_file_4: null,
      video_file_5: null,
      video_file_6: null,
      video_file_7: null,
      video_file_8: null,
      video_file_9: null,
      video_file_10: null,
      video_file_11: null,
      video_file_12: null,
      video_file_13: null,
      video_file_14: null,
      video_file_15: null,
      thumbnail_file: null,
    };
  },
  computed: {
    client_id() {
      return this.$route.query.client_id;
    },
    campaign_uuid() {
      return this.$route.query.campaign_uuid;
    },
    campaign_id() {
      return this.$route.query.campaign_id;
    },
    client_info() {
      return this.$store.getters["client/get_client"];
    },
    campaign_info() {
      return this.$store.getters["campaign/get_campaign"];
    },
  },
  methods: {
      handle_upload_file_audio(file) {
      if (file) {
        this.audio_file = file;
        this.file_name_audio = file.name;
        this.content_type_audio = file.type;
        this.size_audio = file.size;
      }
    },
    handle_upload_file(file) {
      if (file) {
        this.video_file = file;
        this.file_name_video = file.name;
        this.content_type_video = file.type;
        this.size_video = file.size;
      }
    },
    handle_upload_files(file, n) {
      if (file) {
        this[`video_file_${n}`] = file;
        this[`file_name_video_${n}`] = file.name;
        this[`content_type_video_${n}`] = file.type;
        this[`size_video_${n}`] = file.size;
      }
      console.log(this.video_file_15);
    },
    handle_upload_thumbnail(file) {
      if (file) {
        this.thumbnail_file = file;
        this.file_name_thumbnail = file.name;
        this.content_type_thumbnail = file.type;
        this.size_thumbnail = file.size;
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        const self = this;
        this.isLoading = true;
        this.$store
          .dispatch("creative/create_creative", {
            name: this.name,
            client_id: this.client_id,
            campaign_id: this.campaign_uuid,
            file_name_video: this.file_name_video,
            content_type_video: this.content_type_video,
            size_video: this.size_video,
            file_name_thumbnail: this.file_name_thumbnail,
            content_type_thumbnail: this.content_type_thumbnail,
            size_thumbnail: this.size_thumbnail,
            $toast: this.$toast,
            $router: this.$router,
            file_name_video_2: this.file_name_video_2,
            content_type_video_2: this.content_type_video_2,
            size_video_2: this.size_video_2,
            file_name_video_3: this.file_name_video_3,
            content_type_video_3: this.content_type_video_3,
            size_video_3: this.size_video_3,
            file_name_video_4: this.file_name_video_4,
            content_type_video_4: this.content_type_video_4,
            size_video_4: this.size_video_4,
            file_name_video_5: this.file_name_video_5,
            content_type_video_5: this.content_type_video_5,
            size_video_5: this.size_video_5,
            file_name_video_6: this.file_name_video_6,
            content_type_video_6: this.content_type_video_6,
            size_video_6: this.size_video_6,
            file_name_video_7: this.file_name_video_7,
            content_type_video_7: this.content_type_video_7,
            size_video_7: this.size_video_7,
            file_name_video_8: this.file_name_video_8,
            content_type_video_8: this.content_type_video_8,
            size_video_8: this.size_video_8,
            file_name_video_9: this.file_name_video_9,
            content_type_video_9: this.content_type_video_9,
            size_video_9: this.size_video_9,
            file_name_video_10: this.file_name_video_10,
            content_type_video_10: this.content_type_video_10,
            size_video_10: this.size_video_10,
            file_name_video_11: this.file_name_video_11,
            content_type_video_11: this.content_type_video_11,
            size_video_11: this.size_video_11,
            file_name_video_12: this.file_name_video_12,
            content_type_video_12: this.content_type_video_12,
            size_video_12: this.size_video_12,
            file_name_video_13: this.file_name_video_13,
            content_type_video_13: this.content_type_video_13,
            size_video_13: this.size_video_13,
            file_name_video_14: this.file_name_video_14,
            content_type_video_14: this.content_type_video_14,
            size_video_14: this.size_video_14,
            file_name_video_15: this.file_name_video_15,
            content_type_video_15: this.content_type_video_15,
            size_video_15: this.size_video_15,
            file_name_audio: this.file_name_audio,
            content_type_audio: this.content_type_audio,
            size_audio: this.size_audio,
          })
          .then((response) => {
            let { pre_signed_video, pre_signed_video_2,pre_signed_video_3,pre_signed_video_4,pre_signed_video_5,pre_signed_video_6,pre_signed_video_7,pre_signed_video_8,pre_signed_video_9,pre_signed_video_10,pre_signed_video_11,pre_signed_video_12,pre_signed_video_13,pre_signed_video_14,pre_signed_video_15, pre_signed_thumbnail,pre_signed_audio } =
              response.data.data;
            // console.log(response.data);
            if (pre_signed_video) {
              this.$store
                .dispatch("upload/to_s3", {
                  url: pre_signed_video,
                  data: self.video_file,
                  content_type: self.content_type_video,
                })
                .then((res) => {
                    if (pre_signed_audio != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_audio,
                      data: self.audio_file,
                      content_type: self.content_type_audio,
                    });
                  }
                  if (pre_signed_video_2 != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_video_2,
                      data: self.video_file_2,
                      content_type: self.content_type_video_2,
                    });
                  }
                  if (pre_signed_video_3 != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_video_3,
                      data: self.video_file_3,
                      content_type: self.content_type_video_3,
                    });
                  }
                  if (pre_signed_video_4 != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_video_4,
                      data: self.video_file_4,
                      content_type: self.content_type_video_4,
                    });
                  }
                  if (pre_signed_video_5 != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_video_5,
                      data: self.video_file_5,
                      content_type: self.content_type_video_5,
                    });
                  }
                  if (pre_signed_video_6 != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_video_6,
                      data: self.video_file_6,
                      content_type: self.content_type_video_6,
                    });
                  }
                  if (pre_signed_video_7 != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_video_7,
                      data: self.video_file_7,
                      content_type: self.content_type_video_7,
                    });
                  }
                  if (pre_signed_video_8 != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_video_8,
                      data: self.video_file_8,
                      content_type: self.content_type_video_8,
                    });
                  }
                  if (pre_signed_video_9 != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_video_9,
                      data: self.video_file_9,
                      content_type: self.content_type_video_9,
                    });
                  }
                  if (pre_signed_video_10 != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_video_10,
                      data: self.video_file_10,
                      content_type: self.content_type_video_10,
                    });
                  }
                  if (pre_signed_video_11 != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_video_11,
                      data: self.video_file_11,
                      content_type: self.content_type_video_11,
                    });
                  }
                  if (pre_signed_video_12 != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_video_12,
                      data: self.video_file_12,
                      content_type: self.content_type_video_12,
                    });
                  }
                  if (pre_signed_video_13 != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_video_13,
                      data: self.video_file_13,
                      content_type: self.content_type_video_13,
                    });
                  }
                  if (pre_signed_video_14 != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_video_14,
                      data: self.video_file_14,
                      content_type: self.content_type_video_14,
                    });
                  }
                  if (pre_signed_video_15 != "") {
                    this.$store.dispatch("upload/to_s3", {
                      url: pre_signed_video_15,
                      data: self.video_file_15,
                      content_type: self.content_type_video_15,
                    });
                  }

                  if (res.status == 200) {
                    this.$store
                      .dispatch("upload/to_s3", {
                        url: pre_signed_thumbnail,
                        data: self.thumbnail_file,
                        content_type: self.content_type_thumbnail,
                      })
                      .then((res) => {
                        if (res.status == 200) {
                          self.isLoading = false;
                          this.$toast.success(
                            "正常に処理が完了しました!",
                            config.toast
                          );
                          this.$router.go(-1);
                        } else {
                          // that bai
                          this.$toast.error(
                            "アップロードに失敗しました",
                            config.toast
                          );
                        }
                      })
                      .catch((e) => {
                        console.log("eeeee ===>", e);
                        this.$toast.error(
                          "アップロードに失敗しました",
                          config.toast
                        );
                      });
                  } else {
                    // that bai
                    this.$toast.error(
                      "アップロードに失敗しました",
                      config.toast
                    );
                  }
                })
                .catch((e) => {
                  console.log("eeeee ===>", e);
                  this.$toast.error("アップロードに失敗しました", config.toast);
                });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    formatDate(date) {
      if (!date) return "";
      return `${new Date(date).getFullYear()}/${
        new Date(date).getMonth() < 9
          ? "0" + (new Date(date).getMonth() + 1)
          : new Date(date).getMonth() + 1
      }/${
        new Date(date).getDate() < 10
          ? "0" + new Date(date).getDate()
          : new Date(date).getDate()
      }`;
    },
  },
  created() {
    this.$store.dispatch("client/fetch_client_by_id", { id: this.client_id });
    this.$store.dispatch("campaign/fetch_campaign_by_id", {
      id: this.campaign_id,
    });
  },
};
</script>

<style src="./Creative.scss" lang="scss"></style>
