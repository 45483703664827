<template>
  <v-container fluid>
      <Subheader :breadcrumbs="breadcrumbs" :backLink="backLink" />
      <DLModal v-model="showDLModal" :url="downloadURL" :title="titleDL" :subtitle="subtitleDL" />
      <ConfirmShutdown v-model="showConfirmShutdown" @stop="stopState" :stopItem="stopItem"/>
    <div class="tables-basic">
      <v-row>
        <v-col cols="12">
            <v-tabs grow>
                <v-tabs-slider></v-tabs-slider>
                <v-tab :href="`#manager`">キャンペーン一覧</v-tab>
                <!-- <v-tab :href="`#suggest`">提案機能</v-tab>
                <v-tab :href="`#report`">レポーティング</v-tab> -->

                <v-tab-item :value="'manager'" >
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                        <router-link :to="`/campaigns/create?client_id=${client_id}`"><v-btn color="primary"><v-icon>mdi-plus</v-icon> キャンペーン登録する</v-btn></router-link>
                        <v-spacer></v-spacer>
                        <v-btn @click="clickDLTotalCampaignReport" color="primary">キャンペーン一覧レポートDL</v-btn>
                        </v-card-title>
                        <v-data-table
                        :headers="headers"
                        :items="list_campaigns"
                        :loading="loading"
                        loading-text="読み込み中"
                        :options.sync="options"
                        @pagination="update_page"
                        :server-items-length="total"
                        :items-per-page="items_per_page"
                        :page="page"
                        :footer-props="{
                            'disable-items-per-page': true,
                            'items-per-page-options': [ 50, 100 ],
                            'items-per-page-text':'ページごとに表示する件数'
                        }"
                        item-key="id"
                        >
                            <template v-slot:[`item.state`]="{ item }">
                                <v-switch
                                    v-model="item.enable"
                                    @change="changeState(item)"
                                ></v-switch>
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                <div class="text-truncate" style="max-width: 200px;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                                        </template>
                                        <span>{{ item.name }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                {{ get_status_text(item.status) }}
                            </template>
                            <template v-slot:[`item.campaign_category`]="{ item }">
                                {{ get_campaign_category_text(item.campaign_category) }}
                            </template>
                            <template v-slot:[`item.campaign_type`]="{ item }">
                                {{ get_campaign_type_text(item.campaign_type) }}
                            </template>
                            <template v-slot:[`item.release_start_at`]="{ item }">
                                {{ formatDate(item.release_start_at) }}
                            </template>
                            <template v-slot:[`item.release_end_at`]="{ item }">
                                {{ formatDate(item.release_end_at) }}
                            </template>
                            <template v-slot:[`item.locations`]="{ item }">
                                <div class="text-truncate" style="max-width: 200px;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ display_location(item) }}</span>
                                        </template>
                                        <span>{{ display_location(item) }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.toilet_type_display`]="{ item }">
                                {{ join_text_array(item.toilet_type_display) }}
                            </template>
                            <template v-slot:[`item.category_type_display`]="{ item }">
                                <div class="text-truncate" style="max-width: 200px;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ join_text_array(item.category_type_display) }}</span>
                                        </template>
                                        <span>{{ join_text_array(item.category_type_display) }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.year_display`]="{ item }">
                                <div class="text-truncate" style="max-width: 200px;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ join_text_array(item.year_display) }}</span>
                                        </template>
                                        <span>{{ join_text_array(item.year_display) }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.sex_display`]="{ item }">
                                <div class="text-truncate" style="max-width: 200px;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ join_text_array(item.sex_display) }}</span>
                                        </template>
                                        <span>{{ join_text_array(item.sex_display) }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.facilities`]="{ item }">
                                <div class="text-truncate" style="max-width: 200px;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ join_text_array(item.facilities) }}</span>
                                        </template>
                                        <span>{{ join_text_array(item.facilities) }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.edit`]="{ item }">
                                <div class="d-flex gap-10">
                                    <router-link :to="`/campaigns/edit/${item.id}`" class="v-btn v-size--small primary v-btn--is-elevated">編集</router-link>
                                    <v-btn @click="download_row(item)" small>データDL</v-btn>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-menu :key="item.id" offset-y open-on-hover>
                                    <template v-slot:activator="{ attrs, on }">
                                        <span class="text-actions" v-bind="attrs" v-on="on">アクション</span>
                                    </template>

                                    <v-list>
                                        <v-list-item v-for="act in [{text: 'キャンペーン編集', link: `/campaigns/edit/${item.id}`}, {text: 'クリエイティブ一覧', link: `/creatives?page=1&client_id=${client_id}&campaign_id=${item.id}&campaign_uuid=${item.uuid}`}, {text: '個別レポートDL', link: '', is_dl: true}, {text: '配信先一覧', link: `/clients/campaign/facilities/${item.uuid}?page=1`}]" :key="act.text" link :to="act.link">
                                            <v-list-item-title v-if="!act.is_dl">
                                                {{ act.text }}
                                            </v-list-item-title>
                                            <v-list-item-title v-else @click="clickDLCampaignReportById(item)">
                                                <span class="action-item">{{ act.text }}</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                            <template v-slot:[`item.avg_img`]="{ item }">
                                <span>{{ Math.round(parseFloat(item.avg_img) * 100 * 100) / 100 }}%</span>
                            </template>
                            <template v-slot:[`item.imp_month`]="{ item }">
                                <span>{{ Math.round(parseFloat(item.imp_month) * 100 * 100) / 100 }}%</span>
                            </template>
                            <template v-slot:no-data>
                                データがありません
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import Subheader from '@/components/Subheader/Subheader'
import DLModal from '@/components/DLModal'
import ConfirmShutdown from '@/components/Popup/ConfirmShutdown'

export default {
  name: "ListCampaign",
  components: {
      Subheader,
      DLModal,
      ConfirmShutdown
  },
  data() {
    return {
        sampleSwitch: false,
        headers: [
            { text: "id", align: "start", value: "id", width: "100px", sortable: true },
            { text: "キャンペーン名",  value: "name", width: "200px", sortable: true },
            { text: "ステータス", value: "status", width: "120px", sortable: true },
            { text: "キャンペーン区分", value: "campaign_category", width: "150px", sortable: true },
            { text: "配信開始日", value: "release_start_at", width: "120px", sortable: true },
            { text: "配信終了日", value: "release_end_at", width: "120px", sortable: true },
            { text: "購入imp数", value: "imp_expected", width: "120px", sortable: true },
            { text: "imp数", value: "sum_imp_this_month", width: "100px", sortable: true },
            { text: "imp消化率（絶対数）", value: "avg_img", width: "170px", sortable: true },
            { text: "imp消化率（対日付）", value: "imp_month", width: "170px", sortable: true },
            { text: "imp単価", value: "imp_price", width: "170px", sortable: true },
            // { text: "場所", value: "locations", width: "250px", sortable: false },
            // { text: "トイレ区分", value: "toilet_type_display", width: "120px", sortable: false },
            // { text: "施設区分", value: "category_type_display", width: "120px", sortable: false },
            // { text: "年代", value: "year_display", width: "160px", sortable: false },
            // { text: "性別", value: "sex_display", width: "160px", sortable: false },
            // { text: "配信施設", value: "facilities", width: "160px", sortable: false },
            // { text: "", value: "edit", sortable: false },
            { text: "緊急停止ボタン", value: "state", width: "150px", sortable: false },
            { text: "アクション", value: "actions", width: "120px", sortable: false },
        ],
        loading: false,
        options: {},
        showDLModal: false,
        downloadURL: '',
        titleDL: '',
        subtitleDL: '',
        showConfirmShutdown:false,
        stopItem:{}
    };
  },
  computed: {
      client_id() {
          return this.$route.query.client_id;
      },
      client_name() {
          const client_info = this.$store.getters['client/get_client'];
          return client_info ? client_info.name : '';
      },
      breadcrumbs() {
          return [
            {
                text: '広告登録・数値管理',
                disabled: true,
                href: '#',
            },
            {
                text: this.client_name,
                disabled: false,
                underline: false,
                href: '/clients?page=1',
            },
            {
                text: 'キャンペーン一覧',
                disabled: false,
                underline: true,
                href: `/campaigns?page=1&client_id=${this.client_id}`,
            },
          ]
      },
      backLink() {
        return '/clients?page=1';
      },
      list_campaigns() {
          return this.$store.getters['campaign/get_list_campaigns'];
      },
      page() {
          if(this.$route.query.page) {
              return parseInt(this.$route.query.page);
          }
          const meta = this.$store.getters['campaign/get_campaigns_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.current_page) {
                      return meta.pagination.current_page;
                  }
              }
          }
          return 1;
      },
      total() {
          const meta = this.$store.getters['campaign/get_campaigns_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.total) {
                      return meta.pagination.total;
                  }
              }
          }
          return 0;
      },
      items_per_page() {
          const meta = this.$store.getters['campaign/get_campaigns_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.per_page) {
                      return meta.pagination.per_page;
                  }
              }
          }
          return 0;
      }
  },
  watch: {
      options: {
        handler () {
          this.fetch_list_campaigns()
        },
        deep: true,
      },
  },
  methods: {
    fetch_list_campaigns() {
        const self = this;
        self.loading = true;
        const page = this.$route.query.page || 1;
        const payload = { page, client_id: this.client_id };

        const { sortBy, sortDesc } = this.options
        payload.sort_by = sortBy[0] ? sortBy[0] : 'id';
        payload.sort_type = sortDesc[0] ? 'desc' : 'asc';

        this.$router.push({
            name: "ListCampaign",
            query: { page, client_id: this.client_id }
        }).catch(() => { });

        this.$store.dispatch("campaign/fetch_list_campaigns", payload).then(() => {
            self.loading = false;
        }).catch(() => {
            self.loading = false;
        });
    },

    //     changeState(item) {
    //     const self = this;
    //     self.loading = true;
    //     this.$store.dispatch("campaign/update_enable", { uuid: item.uuid, $toast: this.$toast }).then(() => {
    //         self.loading = false;
    //         self.fetch_list_campaigns();
    //     }).catch(() => {
    //         self.loading = false;
    //     });
    // },

    changeState(item) {
        const self = this;
        self.loading = true;
        if(item.enable) {
            this.$store.dispatch("campaign/update_enable", { uuid: item.uuid, $toast: this.$toast }).then(() => {
                self.loading = false;
                self.fetch_list_campaigns();
            }).catch(() => {
                self.loading = false;
            });
            item.enable = true;
        } else {
            this.stopItem = item;
            this.show_confirmShutdown();
            self.loading = false;
        }
    },
    stopState(data) {
        if(data.confirm) {
            this.$store.dispatch("campaign/update_enable", { uuid: data.item.uuid, $toast: this.$toast }).then(() => {
                self.loading = false;
                self.fetch_list_campaigns();
            }).catch(() => {
                self.loading = false;
            });
            data.item.enable = false;
        } else {
            this.showConfirmShutdown = false;
            data.item.enable = true;
        }
        this.showConfirmShutdown = false;
    },
    show_confirmShutdown() {
        this.showConfirmShutdown = true;
    },
    update_page(pagination) {
        const page  = pagination.page;

        this.$router.push({
            name: "ListCampaign",
            query: { page, client_id: this.client_id }
        }).catch(() => { });
        this.fetch_list_campaigns();
        // window.location.href = `/clients/?page=${page}`
    },
    get_status_text(status) {
        switch(status) {
            case 1:
                return "配信中";
            case 2:
                return "掲載終了（期間）";
            case 3:
                return "強制停止中";
            case 4:
                return "未配信";
            default:
                return '';
        }
    },
    get_campaign_category_text(cate) {
        switch(cate) {
            case 1:
                return "セグメント";
            case 2:
                return "施設選択";
            default:
                return '';
        }
    },
    get_campaign_type_text(type) {
        switch(type) {
            case 1:
                return "プレミアム";
            case 2:
                return "ノーマル";
            default:
                return '';
        }
    },
    formatDate(date) {
        if(!date) return '';
        return `${new Date(date).getFullYear()}/${new Date(date).getMonth() < 9 ? '0' + (new Date(date).getMonth() + 1) : (new Date(date).getMonth() + 1)}/${new Date(date).getDate() < 10 ? '0' + new Date(date).getDate() : new Date(date).getDate()}`;
    },
    join_text_array(items) {
        if(!items) return '';
        if(!items.length) return '';
        return items.map(function(elem){
            return elem.name;
        }).join(", ");
    },
    join_text_object(items) {
        if(!items) return '';
        let ret = [];
        for(var i in items) {
            ret.push(items[i]);
        }
        return ret.join(", ");
    },
    download() {
        this.$store.dispatch("download/csv", { url: `/api/campaign/download/csv/${this.client_id}`}).then((res) => {
            const contentDisposition = res.headers['content-disposition'];
            let fileName = 'unknown';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }
            const download_url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = download_url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }).catch((e) => {
            console.log(e)
        });
    },
    display_location(item) {
        const locations = item.locations || []
        const districts = item.districts || []
        return this.join_text_array(locations.concat(districts));
    },
    download_row(item) {
        this.$store.dispatch("download/csv", { url: `/api/campaign/download/csv/${this.client_id}/${item.uuid}`}).then((res) => {
            const contentDisposition = res.headers['content-disposition'];
            let fileName = 'unknown';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }
            const download_url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = download_url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }).catch((e) => {
            console.log(e)
        });
    },
    clickDLTotalCampaignReport() {
        this.downloadURL = `/api/campaign/download/csv/${this.client_id}`;
        this.showDLModal = true;
        this.titleDL = 'キャンペーンレポートDL';
    },
    clickDLCampaignReportById(item) {
        this.downloadURL = `/api/campaign/download/csv/${this.client_id}/${item.uuid}`;
        this.showDLModal = true;
        this.titleDL = item.name;
        this.subtitleDL = '個別レポート　レポート';
    }
  },
  created() {
    this.$store.dispatch("campaign/empty");
    this.$store.dispatch("client/fetch_client_by_id", { id: this.client_id })
  }
};
</script>

