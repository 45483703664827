export default {
    SET_TOILET_BY_FACILITY_ID_META(state, data) {
        state.toilet_by_facility_id_meta = data;
    },
    SET_LIST_TOILET_BY_FACILITY_ID(state, data) {
        state.list_toilet_by_facility_id = data;
    },
    SET_LIST_TOILETS(state, data) {
        state.list_toilets = data;
    },
    SET_TOILETS_META(state, data) {
        state.toilets_meta = data;
    },
    SET_TOILET(state, data) {
        state.toilet = data;
    },
    SET_LIST_SEX(state, data) {
        state.list_sex = data;
    }
}