<template>
  <v-container fluid>
    <Subheader :breadcrumbs="breadcrumbs" :backLink="backLink" />
    <ConfirmModal ref="cfmodal" />
    <div class="tables-basic">
      <h4 class="page-title mt-10 mb-6"></h4>

        <v-dialog v-model="show_edit_type" persistent max-width="600px">
            <v-card>
                <v-card-title>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <v-row class="px-6 py-6">
                        <!-- ng_category -->
                        <v-select
                            v-model="ng_profession_id"
                            :items="ng_category_options"
                            item-text="name"
                            item-value="id"
                            label="NGカテゴリ"
                            no-data-text="データがありません"
                            multiple
                        ></v-select>
                    </v-row>
                </v-container>
                </v-card-text>
                <v-card-actions class="px-6 py-6">
                    <v-spacer></v-spacer>
                    <v-btn @click="show_edit_type=false">
                        閉じる
                    </v-btn>
                    <v-btn color="primary" @click="update_professions">
                        保存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="show_edit_status" persistent max-width="600px">
            <v-card>
                <v-card-title>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <v-row class="px-6 py-6">
                        <!-- ng_category -->
                        <v-select
                            v-model="status"
                            :items="facility_status_options"
                            item-text="text"
                            item-value="value"
                            label="ステータス"
                            no-data-text="データがありません"
                        ></v-select>
                    </v-row>
                </v-container>
                </v-card-text>
                <v-card-actions class="px-6 py-6">
                    <v-spacer></v-spacer>
                    <v-btn @click="show_edit_status=false">
                        閉じる
                    </v-btn>
                    <v-btn color="primary" @click="change_professions()">
                        保存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-form ref="form">
              <v-container>
                <v-row class="flex-column" align="center" justify="center">
                  <div class="col d-flex">
                    <h4 class="px-3 pt-2 font-weight-medium text-truncate" style="max-width:80%;">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{ name }}</span>
                            </template>
                            <span>{{ name }}</span>
                        </v-tooltip>
                    </h4>
                  </div>

                        <div class="col d-flex">
                            <v-col cols="6">
                                <div class="detail-area py-6">
                                    <span @click="show_edit_status=true" class="detail-text-editable"><span class="text-underline">稼働ステータス</span> <v-icon color="red" dense>mdi-pencil</v-icon>： <span class="text-underline">{{ get_status_text(status) }}</span></span>
                                </div>
                                <div class="detail-area py-6">
                                    <span v-if="!show_edit" class="detail-text-editable" @click="show_edit=true"><span class="text-underline">基本情報</span> <v-icon color="red" dense>mdi-pencil</v-icon></span>
                                    <span v-else class="detail-text-editable" @click="update_info"><span class="text-underline">基本情報</span> <v-icon color="green" dense>mdi-check-outline</v-icon></span>
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <router-link :to="`/facilities/${this.$route.params.id}/toilet`">
                                    <v-btn class="text-capitalize mt-6" color="primary" width="100%">
                                        <v-icon>mdi-plus</v-icon>トイレ登録／編集
                                    </v-btn>
                                </router-link>
                                <router-link :to="`/facilities/${this.$route.params.id}/movie`">
                                    <v-btn class="text-capitalize mt-6" color="primary" width="100%">
                                        施設固有動画 登録／編集
                                    </v-btn>
                                </router-link>
                                <router-link :to="`/facilities/${this.$route.params.id}/campaign/desired`">
                                    <v-btn class="text-capitalize mt-6" color="primary" width="100%">
                                        出稿希望キャンペーンを確認する
                                    </v-btn>
                                </router-link>
                            </v-col>
                        </div>

                        <div class="col d-flex">
                            <v-col cols="6">
                                <!-- table detail -->
                                <table class="detail-table">
                                    <tr>
                                        <td>郵便番号 *</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">{{ zip_code }}</td>
                                        <td v-else>
                                            <v-text-field
                                            v-model="zip_code"
                                            type="number"
                                            :rules="[v => !!v || '必須項目のため入力してください']"
                                            @keypress="validate_number"
                                            required
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>都道府県 *</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">{{ location_display ? location_display.name : '' }}</td>
                                        <td v-else>
                                            <v-select
                                                v-model="location_id"
                                                :items="location_options"
                                                :rules="[v => !!v || '必須項目のため入力してください']"
                                                item-text="name"
                                                item-value="id"
                                                no-data-text="データがありません"
                                                required
                                            ></v-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>市区町村 *</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">{{ district_display ? district_display.name : '' }}</td>
                                        <td v-else>
                                            <v-select
                                                v-model="district_id"
                                                :items="district_options"
                                                :rules="[v => !!v || '必須項目のため入力してください']"
                                                item-text="name"
                                                item-value="id"
                                                no-data-text="データがありません"
                                                required
                                            ></v-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>番地</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">{{ house_number }}</td>
                                        <td v-else>
                                            <v-text-field
                                            v-model="house_number"
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>階数・建物名</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">{{ building_name }}</td>
                                        <td v-else>
                                            <v-text-field
                                            v-model="building_name"
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>営業時間 *</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">
                                            {{ type_display ? start_business_hours : '' }}〜{{ type_display ? end_business_hours : '' }}
                                        </td>
                                        <td v-else>
                                            <v-row>
                                                <v-col>
                                                <v-select
                                                    v-model="start_business_hours"
                                                    :items="business_hours"
                                                    :rules="[v => !!v || '必須項目のため入力してください']"
                                                    item-text="text"
                                                    item-value="value"
                                                    label="営業開始時間*"
                                                    required
                                                ></v-select>
                                                </v-col>
                                                <v-col>
                                                <v-select
                                                v-model="end_business_hours"
                                                :items="business_hours"
                                                :rules="[v => !!v || '必須項目のため入力してください']"
                                                item-text="text"
                                                item-value="value"
                                                label="営業終了時間*"
                                                required
                                            ></v-select>
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>週稼働時間 *</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">{{ working_hours }}</td>
                                        <td v-else>
                                            <v-text-field
                                            v-model="working_hours"
                                            type="number"
                                            :rules="[v => !!v || '必須項目のため入力してください']"
                                            @keypress="validate_number"
                                            required
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>週稼働時間 *</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">{{ working_hours }}</td>
                                        <td v-else>
                                            <v-text-field
                                            v-model="working_hours"
                                            type="number"
                                            :rules="[v => !!v || '必須項目のため入力してください']"
                                            @keypress="validate_number"
                                            required
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>電話番号 *</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">{{ phone_number }}</td>
                                        <td v-else>
                                            <v-text-field
                                            v-model="phone_number"
                                            type="number"
                                            :rules="[v => !!v || '必須項目のため入力してください']"
                                            @keypress="validate_phone_number"
                                            required
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>メールアドレス *</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">{{ email }}</td>
                                        <td v-else>
                                            <v-text-field
                                            v-model="email"
                                            type="email"
                                            :rules="[v => !!v || '必須項目のため入力してください', v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'メールは有効である必要があります']"
                                            required
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>担当者名</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">{{ person_in_charge }}</td>
                                        <td v-else>
                                            <v-text-field
                                            v-model="person_in_charge"
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                </table>
                            </v-col>

                            <v-col cols="6">
                                <!-- table detail -->
                                <table class="detail-table">
                                    <tr>
                                        <td>銀行名 *</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">{{ bank }}</td>
                                        <td v-else>
                                            <v-select
                                                v-model="bank"
                                                :items="bank_select_options"
                                                :rules="[v => !!v || '必須項目のため入力してください']"
                                                item-text="text"
                                                item-value="value"
                                                label="銀行名*"
                                                required
                                            ></v-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>支店名 *</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">{{ branch_bank }}</td>
                                        <td v-else>
                                            <v-select
                                                v-model="branch_bank"
                                                :items="branch_select_options"
                                                :rules="[v => !!v || '必須項目のため入力してください']"
                                                item-text="text"
                                                item-value="value"
                                                label="支店名*"
                                                required
                                            ></v-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>預金種目 *</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">{{ currency }}</td>
                                        <td v-else>
                                            <v-select
                                                v-model="currency"
                                                :items="currencies_options"
                                                :rules="[v => !!v || '必須項目のため入力してください']"
                                                item-text="text"
                                                item-value="value"
                                                required
                                            ></v-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>口座番号 *</td>
                                        <td v-if="!show_edit" class="text-truncate max-width-200">{{ account_number }}</td>
                                        <td v-else>
                                            <v-text-field
                                            v-model="account_number"
                                            type="number"
                                            :rules="[v => !!v || '必須項目のため入力してください']"
                                            @keypress="validate_account_number"
                                            required
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>男性トイレ数</td>
                                        <td>{{ number_male_toilet }}</td>
                                    </tr>
                                    <tr>
                                        <td>女性トイレ数</td>
                                        <td>{{ number_female_toilet }}</td>
                                    </tr>
                                    <tr>
                                        <td>オストメイト数</td>
                                        <td>{{ number_ostomate_toilet }}</td>
                                    </tr>
                                    <tr>
                                    <td>年代 *</td>
                                        <td v-if="!show_edit">{{ target_age }}</td>
                                        <td v-else>
                                            <v-select
                                            v-model="target_age"
                                            type="number"
                                            :items="target_age_options"
                                            :rules="[v => !!v || '必須項目のため入力してください']"
                                            no-data-text="データがありません"
                                            required
                                            ></v-select>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <td>最小年代 *</td>
                                        <td v-if="!show_edit">{{ min_age }}</td>
                                        <td v-else>
                                            <v-text-field
                                            v-model="min_age"
                                            type="number"
                                            :rules="min_age_validation"
                                            @keypress="validate_number"
                                            @input="validate_min_max_age"
                                            required
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>最大年代 *</td>
                                        <td v-if="!show_edit">{{ max_age }}</td>
                                        <td v-else>
                                            <v-text-field
                                            v-model="max_age"
                                            type="number"
                                            :rules="max_age_validation"
                                            @keypress="validate_number"
                                            @input="validate_min_max_age"
                                            required
                                            ></v-text-field>
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <td>レベニューシェア率 *</td>
                                        <td v-if="!show_edit">{{ revenue_share_percent }}</td>
                                        <td v-else>
                                            <v-text-field
                                            v-model="revenue_share_percent"
                                            type="number"
                                            :rules="[v => !!v || '必須項目のため入力してください']"
                                            @keypress="validate_number"
                                            required
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>音声の有無 *</td>
                                        <td v-if="!show_edit">{{ getAudioText }}</td>
                                        <td v-else>
                                            <v-select
                                            v-model="audio"
                                            :items="audio_options"
                                            :rules="[v => !!v || '必須項目のため入力してください']"
                                            no-data-text="データがありません"
                                            required
                                            ></v-select>
                                        </td>
                                    </tr>
                                </table>
                                
                            </v-col>
                        </div>

                        <div class="col d-flex">
                            <v-col cols="12">
                                <span class="detail-text-editable" @click="show_edit_type=true"><span class="text-underline">NGカテゴリ</span> <v-icon color="red" dense>mdi-pencil</v-icon>：</span>
                                <div class="mt-6 d-flex flex-wrap">
                                    <div class="col col-3 border-grey d-flex align-center" v-for="item in professions" :key="item.id">
                                        {{ item.name }}
                                    </div>
                                </div>
                            </v-col>
                        </div>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import Subheader from '@/components/Subheader/Subheader'
import { bank_options, branch_options } from '@/constants/bank'
import ConfirmModal from '@/components/ConfirmModal'

export default {
  name: "DetailFacility",
  components: {
      Subheader,
      ConfirmModal
  },
  data() {
    return {
      breadcrumbs: [
            {
                text: '媒体施設一覧',
                disabled: false,
                underline: true,
                href: '/facilities?page=1',
            },
            {
                text: '媒体施設詳細',
                disabled: true,
                underline: false,
                href: '#',
            },
        ],

      id: null,
      name: "",
      type: "",
      location_id: null,
      location_name: "",
      district_id: null,
      district_name: "",
      zip_code: null,
      house_number: "",
      building_name: "",
      working_hours: null,
      phone_number: null,
      email: "",
      person_in_charge: "",
      bank: "",
      branch_bank: "",
      currency: null,
      start_business_hours: "",
      end_business_hours: "",
      account_number: null,
      audio: "",
      audio_options: [
         {text:'有り',value: 1},
         {text:'無し',value: 2},
      ],
    //   min_age: null,
    //   max_age: null,
      target_age: "",
      revenue_share_percent: null,
      status: null,
      ng_profession_id: null,
      professions: null,

      type_display: null,
      location_display: null,
      district_display: null,

      facility_type_options: [
        { text: '商業', value: 1 },
        { text: 'オフィス', value: 2 },
        { text: 'コンビニ', value: 3 },
        { text: 'カフェ', value: 4 },
        { text: 'パチンコ', value: 5 },
        { text: '飲食', value: 6 },
      ],
      facility_status_options: [
        { text: '稼働中', value: 1 },
        { text: '停止中', value: 2 },
      ],
      currencies_options: [
        { text: '普通', value: '普通' },
        { text: '当座', value: '当座' },
      ],
      district_options: [],
      branch_select_options: [],

      min_age_validation: [v => !!v || '必須項目のため入力してください'],
      max_age_validation: [v => !!v || '必須項目のため入力してください'],

      show_edit: false,
      show_edit_type: false,
      show_edit_status: false,
    };
  },
  watch: {
    location_id(val) {
      const list_locations = this.$store.getters['campaign/get_campaign_address'];
      for(var i = 0; i < list_locations.length; i++) {
        if(list_locations[i].id == val) {
          this.district_options = list_locations[i].districts;
          break;
        }
      }
    },
    facility(val) {
        this.id = val.id;
        this.name = val.name;
        this.type_display = val.type_display;
        this.type = val.type_display ? val.type_display.id : null;
        this.zip_code = val.zip_code;
        this.location_display = val.locations;
        this.location_id = val.locations ? val.locations.id : null;
        this.district_id = val.districts ? val.districts.id : null;
        this.district_display = val.districts;
        this.phone_number = val.phone_number;
        this.email = val.email;
        this.working_hours = val.working_hours;
        this.house_number = val.house_number;
        this.building_name = val.building_name;
        this.person_in_charge = val.person_in_charge;
        this.start_business_hours = val.start_business_hours;
        this.end_business_hours = val.end_business_hours;
        this.currency = val.currency;
        this.bank = val.bank;
        this.branch_bank = val.branch_bank;
        this.account_number = val.account_number;
        this.min_age = val.min_age;
        this.max_age = val.max_age;
        this.target_age = val.target_age;
        this.revenue_share_percent = val.revenue_share_percent;
        this.professions = val.professions;
        this.status = val.status;
        this.audio = val.audio;

        this.ng_profession_id = val.professions ? val.professions.map(e => e.id) : [];
    },
    bank(val) {
      var bank_id = null;
      for(var i = 0; i < bank_options.length; i++) {
        if(bank_options[i].value == val) {
          bank_id = bank_options[i].id;
          break;
        }
      }
      if(bank_id) {
        for(var j = 0; j < branch_options.length; j++) {
          if(branch_options[j].parent_id == bank_id) {
            this.branch_select_options = branch_options[j].list;
            break;
          }
        }
      } else {
        this.branch_select_options = [];
      }
    }
  },
  computed: {
        getAudioText() {
      const option = this.audio_options.find(option => option.value === this.audio);
      return option ? option.text : '未選択';
    },
      ng_category_options() {
          return this.$store.getters['client/get_list_professions'];
      },
      location_options() {
        let locations = this.$store.getters['campaign/get_campaign_address'];
        locations = locations.filter(e => e.districts && e.districts.length > 0);
        return locations;
      },
      facility() {
          return this.$store.getters['facility/get_facility'];
      },
      number_male_toilet() {
        var toilets = this.$store.getters['facility/get_facility'] ? this.$store.getters['facility/get_facility'].toilets : [];
        var men_toilets = toilets ? toilets.filter(el => el.sex == 1) : [];
        return men_toilets ? men_toilets.length : 0;
      },
      number_female_toilet() {
        var toilets = this.$store.getters['facility/get_facility'] ? this.$store.getters['facility/get_facility'].toilets : [];
        var men_toilets = toilets ? toilets.filter(el => el.sex == 2) : [];
        return men_toilets ? men_toilets.length : 0;
      },
      number_ostomate_toilet() {
        var toilets = this.$store.getters['facility/get_facility'] ? this.$store.getters['facility/get_facility'].toilets : [];
        var men_toilets = toilets ? toilets.filter(el => el.sex == 3) : [];
        return men_toilets ? men_toilets.length : 0;
      },
      backLink() {
        return '/facilities?page=1';
      },
      bank_select_options() {
        return bank_options;
      },
    business_hours() {
        var business_hours = [];
        for (let index = 7; index < 24; index++) {
          business_hours.push(index+":"+"00",index+":"+"30");
        }
        return business_hours;
      },
      target_age_options() {
        return ["10代","20代","30代","40代","50代","60代","70代","80代",]
      }
  },
  methods: {
    fetch_list_professions() {
      this.$store.dispatch("client/fetch_list_professions");
    },
    fetch_address () {
      this.$store.dispatch("campaign/fetch_address");
    },
    validate_number: function(evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!/^[0-9]*$/.test(expect) || expect.length >= 10) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validate_min_max_age: function() {
      const valid = parseInt(this.min_age) < parseInt(this.max_age) || this.min_age === null || this.max_age === null;
      console.log('valid', valid, this.min_age, this.max_age)
      if(valid) {
        this.min_age_validation = [v => !!v || '必須項目のため入力してください'];
        this.max_age_validation = [v => !!v || '必須項目のため入力してください'];
      } else {
        this.min_age_validation = [v => !!v || '必須項目のため入力してください', '最小年代を最大年代より小さく入力してください'];
        this.max_age_validation = [v => !!v || '必須項目のため入力してください', '最大年代を 最小年代より大きく入力してください'];
      }
    },
    validate_phone_number: function(evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!/^[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validate_account_number: function(evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!/^[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    update_info() {
        if(this.$refs.form.validate()) {
            this.$store.dispatch("facility/update_info", 
                {
                    id: this.id,
                    type: this.type,
                    location_id: this.location_id,
                    district_id: this.district_id,
                    zip_code: this.zip_code,
                    house_number: this.house_number,
                    building_name: this.building_name,
                    working_hours: this.working_hours,
                    phone_number: this.phone_number,
                    email: this.email,
                    person_in_charge: this.person_in_charge,
                    bank: this.bank,
                    branch_bank: this.branch_bank,
                    currency: this.currency,
                    start_business_hours: this.start_business_hours,
                    end_business_hours: this.end_business_hours,
                    account_number: this.account_number,
                    // min_age: this.min_age,
                    // max_age: this.max_age,
                    target_age: this.target_age,
                    revenue_share_percent: this.revenue_share_percent,
                    audio: this.audio,
                    $toast: this.$toast,
                    $router: this.$router
                }
            ).then(() => {
                this.show_edit = false;
                this.$store.dispatch("facility/fetch_facility_by_id", { id: this.$route.params.id });
            });
        }
    },

    async change_professions() {
        if (await this.$refs.cfmodal.open('ステータスの変更', '稼働ステータスを変更してもよろしいでしょうか？', { color: '#ed5249' })) {
            this.update_status();
            this.show_edit_status=false;
        }
        else {
            this.$refs.cfmodal.cancel();
        }
    },

    update_professions() {
        this.$store.dispatch("facility/update_professions", 
            {
                id: this.id,
                ng_profession_id: this.ng_profession_id,
                $toast: this.$toast,
                $router: this.$router
            }
        ).then(() => {
            this.show_edit_type = false;
            this.$store.dispatch("facility/fetch_facility_by_id", { id: this.$route.params.id });
        });
    },

    update_status() {
        this.$store.dispatch("facility/update_status", 
            {
                id: this.id,
                status: this.status,
                $toast: this.$toast,
                $router: this.$router
            }
        ).then(() => {
            this.show_edit_status = false;
            this.$store.dispatch("facility/fetch_facility_by_id", { id: this.$route.params.id });
        });
    },
    get_status_text(status) {
        switch(status) {
            case 1:
                return "稼働中";
            case 2:
                return "停止中";
            default:
                return '';
        }
    },
  },
  created() {
      this.fetch_list_professions();
      this.fetch_address();
      if(this.$route.params.id) {
          this.$store.dispatch("facility/fetch_facility_by_id", { id: this.$route.params.id })
      }
  }
};
</script>

<style src="./Facility.scss" lang="scss"/>