<template>
  <v-container fluid>
      <Subheader :breadcrumbs="breadcrumbs" :backLink="backLink" />
      <DLModal v-model="showDLModal" :url="downloadURL" />
      <ConfirmModal ref="cfmodal" />
      <input type="file" ref="input_csv" :style="{ display: 'none' }" @change="handle_upload" />
    <div class="tables-basic">
      <v-row>
        <v-col cols="12">
            <v-card class="employee-list mb-1">
                <v-card-title class="pa-6 pb-3 d-flex justify-center">
                    <!-- <router-link to="/facilities/create"><v-btn color="primary"><v-icon>mdi-plus</v-icon>施設登録する</v-btn></router-link>
                    <v-spacer></v-spacer>
                    <v-btn color="primary">施設一覧成果DL</v-btn> -->
                    <p class="login-slogan display-1 text-center font-weight-medium">
                      トイレID 追加・編集
                    </p>
                </v-card-title>
                <div class="d-flex flex-column align-center">
                    <span>※トイレIDは手前のトイレから順に数値を振る</span>
                    <span>※性別・階数に対してそれぞれ１から順に数値を振る</span>
                </div>
                <div class="my-4 mx-6 d-flex align-center">
                    <span v-if="facility.name && facility.name.length < 20" class="subtitle-1">{{ facility.name }}</span>
                    <div class="d-flex justify-center gap-20 flex-grow-1">
                        <v-btn color="primary" @click="browse_file">CSVファイルをアップロード</v-btn>
                        <v-btn color="primary" @click="downloadCSV">CSVファイルをダウンロード</v-btn>
                    </div>
                </div>
                <div class="px-4 py-2 d-flex justify-end">
                    <span v-if="facility.name && facility.name.length >= 20" class="subtitle-1">{{ facility.name }}</span>
                </div>
                <v-data-table
                :headers="headers"
                :items="toilet_data"
                :loading="loading"
                loading-text="読み込み中"
                :hide-default-footer="true"
                :items-per-page="toilet_data.length"
                item-key="id"
                class="toilet-table"
                >
                    <template v-slot:body="{ items, headers }">
                        <tbody>
                            <tr v-for="(item,idx) in items" :key="idx" :style="get_background(item)">
                                <td class="max-width-200" v-for="(header,key) in headers" :key="key">
                                    <v-edit-dialog
                                    v-if="['sex_display','toilet_id','gateway_id','address','number_floor'].indexOf(header.value) > -1"
                                    :return-value.sync="item[header.value]"
                                    @save="save(item)"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    cancel-text="キャンセル"
                                    save-text="設定"
                                    large
                                    > <span>{{ header.value != 'sex_display' ? item[header.value] : get_text(item[header.value]) }}<v-icon v-if="!item[header.value]" color="red" dense>mdi-pencil</v-icon></span>
                                    <template v-slot:input>
                                        <v-text-field
                                        v-if="header.value === 'toilet_id' || header.value === 'gateway_id' || header.value === 'address'"
                                        v-model="item[header.value]"
                                        label="Edit"
                                        single-line
                                        ></v-text-field>
                                        <v-text-field
                                        v-if="header.value === 'number_floor'"
                                        v-model="item[header.value]"
                                        label="Edit"
                                        type="number"
                                        single-line
                                        ></v-text-field>
                                        <v-select
                                            v-if="header.value === 'sex_display'"
                                            v-model="item[header.value]"
                                            :items="list_sex"
                                            :menu-props="{ maxHeight: '200', closeOnClick: true }"
                                            label="性別"
                                            item-text="name"
                                            return-object
                                        ></v-select>
                                    </template>
                                    </v-edit-dialog>
                                    <span v-else-if="header.value === 'actions'">
                                        <a href="javascript:void(0);" @click="confirm_delete(item)">削除</a>
                                    </span>
                                    <span v-else-if="header.value === 'id'">
                                        {{ item[header.value] ? (item[header.value].toString().indexOf('temp') > -1 ? null : item[header.value]) : null }}
                                    </span>
                                    <span v-else>{{ item[header.value] }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                    <template v-slot:no-data>
                        データがありません
                    </template>
                </v-data-table>
                <div class="d-flex justify-center mb-8 mt-2">
                    <v-btn style="width:300px;" @click="add_row">行を追加する</v-btn>
                </div>
                <div class="d-flex justify-center pb-6">
                    <v-btn style="width: 450px;" color="primary" @click="update">更新する</v-btn>
                </div>
            </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import Subheader from '@/components/Subheader/Subheader'
import DLModal from '@/components/DLModal'
import ConfirmModal from '@/components/ConfirmModal'
import config from "@/config";
import * as XLSX from "xlsx"
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, setDoc, deleteDoc, doc } from 'firebase/firestore';
const firebaseConfig = {
  apiKey: "AIzaSyAjd4zzuet63K4uPk4ftkM8eY1rDj8fkqY",
  authDomain: "toiletxadbox.firebaseapp.com",
  projectId: "toiletxadbox",
  storageBucket: "toiletxadbox.appspot.com",
  messagingSenderId: "7803925986",
  appId: "1:7803925986:web:c169ee8487d233bdd035bb"
};
const this_collection = process.env.VUE_APP_FIREBASE_COLLECTION?process.env.VUE_APP_FIREBASE_COLLECTION:"stg-terminals";
// const this_collection = "terminals";
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export default {
  name: "ListFacility",
  components: {
      Subheader,
      DLModal,
      ConfirmModal
  },
  data() {
    return {
        sampleSwitch: false,
        headers: [
            { text: "作成日時", align: "start", value: "created_at", width: "200px", sortable: true },
            { text: "更新日時", value: "updated_at", sortable: true },
            { text: "トイレ ID", value: "toilet_id", sortable: true },
            { text: "ゲートウェイID", value: "gateway_id", sortable: true },
            { text: "フロア内エリア", value: "address", sortable: true },
            { text: "性別区分", value: "sex_display", sortable: true },
            { text: "階数", value: "number_floor", sortable: false },
            // { text: "トイレNo", value: "no", sortable: false },
            { text: "", value: "actions", sortable: false },
        ],
        toilet_data: [],
        breadcrumbs: [
            {
                text: '媒体施設一覧',
                disabled: false,
                underline: true,
                href: '/facilities?page=1',
            },
            {
                text: '施設詳細',
                disabled: false,
                underline: true,
                href: `/facilities/${this.$route.params.id}/detail`,
            },
            {
                text: 'トイレ追加 ',
                disabled: true,
                underline: false,
                href: '#',
            },
        ],
        itemUpdate: [],
        options: {},
        loading: false,
        showDLModal: false,
        downloadURL: '',
        imported_file: null,
    };
  },
  computed: {
      list_sex() {
          return this.$store.getters['toilet/get_list_sex'];
      },
      list_toilets() {
        // console.log(this.$store.getters['toilet/get_list_toilet_by_facility_id']);
          return this.$store.getters['toilet/get_list_toilet_by_facility_id'];
      },
      facility() {
          return this.$store.getters['facility/get_facility'];
      },
      backLink() {
          return `/facilities/${this.$route.params.id}/detail`;
      },
      facility_id() {
          return this.$route.params.id;
      }
  },
  watch: {
      list_toilets(val) {
          for(var i in val) {
            //   val[i]["no"] = parseInt(i) + 1;
              val[i]["no"] = val[i]["id"]
              val[i]["created_at"] = val[i]["created_at"] ? `${new Date(val[i]["created_at"]).toLocaleDateString("ja-JP")}` : null;
              val[i]["updated_at"] = val[i]["updated_at"] ? `${new Date(val[i]["updated_at"]).toLocaleDateString("ja-JP")}` : null;
          }
          this.toilet_data = val;
      }
  },
  methods: {
    async readData(){
        console.log(this_collection);
        const querySnapshot = await getDocs(collection(db, this_collection));
        querySnapshot.forEach((doc) => {
        doc.data()
        // this.firestore_data.push(doc.data().name)
      });
    },
    save(item) {
        if(this.itemUpdate.map(e => e.id).indexOf(item.id) === -1) {
            this.itemUpdate = this.itemUpdate.concat([item]);
        }
        
        console.log('update item:', this.itemUpdate);
    },
    cancel() {},
    open() {},
    close() {},
    get_background(item) {
        if(this.itemUpdate.map(e => e.id).indexOf(item.id) !== -1) {
            return { background: 'rgba(240,219,79,0.6)' }
        }
        return {}
    },
    get_text(item) {
        if(!item) return '';
        return item.name;
    },
    add_row() {
        this.toilet_data = this.toilet_data.concat([
            {
                id: `temp_${new Date().getTime()}`,
                created_at: null,
                updated_at: null,
                toilet_id: null,
                gateway_id: null,
                address: null,
                sex_display: null,
                number_floor: null,
                no: this.toilet_data ? this.toilet_data.length + 1 : 1,
                is_new: true
            }
        ]);
    },
    async update() {
        let toilets = this.itemUpdate;
        let currentdate = new Date()
        var time = currentdate.getHours() + ':' + currentdate.getMinutes() + ':' + currentdate.getSeconds()
        var Month = currentdate.getMonth()+ 1;
        var date = currentdate.getFullYear() + '/' + Month + '/' + currentdate.getDate()
        var now = date+" "+time;
        for(let i = 0; i < toilets.length; i++) {
            if(toilets[i].is_new) {
                const addDataRef = doc(db, this_collection,toilets[i].gateway_id)
                const data = {
                    id : toilets[i].gateway_id,
                    nowPlaying : false,
                    sources : [],
                    staticImage : "https://delivery.video.xadbox.cloud/static_image_uploads/static_image_test.jpg",
                    detection_time : "",
                    created : now,
                    updated : now
                };
                const res = await setDoc(addDataRef, data);
                console.log(res)
                await this.$store.dispatch("toilet/create_toilet", 
                    {
                        toilet_id: toilets[i].toilet_id,
                        gateway_id: toilets[i].gateway_id,
                        address: toilets[i].address,
                        sex: toilets[i].sex_display ? toilets[i].sex_display.id : null,
                        number_floor: toilets[i].number_floor,
                        facility_id: this.$route.params.id,
                        $toast: this.$toast,
                        $router: this.$router
                    }
                );
                // toilets[i].is_new = false;
            } else {
                await this.$store.dispatch("toilet/update_toilet", 
                    {
                        id: toilets[i].id,
                        toilet_id: toilets[i].toilet_id,
                        gateway_id: toilets[i].gateway_id,
                        address: toilets[i].address,
                        sex: toilets[i].sex_display ? toilets[i].sex_display.id : null,
                        number_floor: toilets[i].number_floor,
                        facility_id: this.$route.params.id,
                        $toast: this.$toast,
                        $router: this.$router
                    }
                );
            }
        }

        this.$toast.clear();
        this.$toast.success("正常に処理が完了しました!", config.toast);
        // this.$router.go();
        this.$store.dispatch("toilet/fetch_list_toilet_by_facility_id", { facility_id: this.$route.params.id });
    },
    async confirm_delete(item) {
        if (await this.$refs.cfmodal.open('トイレ削除', '削除してもよろしいでしょうか', { color: '#ed5249' })) {
            var res = await deleteDoc(doc(db, "test", item.gateway_id));
            console.log(res);
            this.$store.dispatch("toilet/delete_toilet", { id: item.id, $toast: this.$toast })
            .then(() => {
                this.$store.dispatch("toilet/fetch_list_toilet_by_facility_id", { facility_id: this.$route.params.id });
            }).catch(() => {});
        }
        else {
            this.$refs.cfmodal.cancel();
        }
    },
    downloadCSV() {
        this.$store.dispatch("download/csv", { url: `/api/toilet/download/csv/${this.facility_id}`}).then((res) => {
            const contentDisposition = res.headers['content-disposition'];
            let fileName = 'unknown';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }
            const download_url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = download_url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }).catch((e) => {
            console.log(e)
        });
    },
    browse_file() {
        this.$refs.input_csv.click();
    },
    handle_upload(event) {
        this.imported_file = event.target.files ? event.target.files[0] : null;
        if (this.imported_file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                /* Parse data */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

                this.handle_import(data);
            }

            reader.readAsText(this.imported_file);
        }
    },
    handle_import(data) {
        if(!data || !(data instanceof Array)) return;
        const current = this.toilet_data.concat([]);
        for(var i = 1; i < data.length; i++) {
            var [toilet_id, gateway_id, address, sex_display, number_floor] = data[i];
            if(current.map(e => e.toilet_id).indexOf(toilet_id) == -1) {
                var new_toilet = {
                    id: `temp_${new Date().getTime()}`,
                    created_at: null,
                    updated_at: null,
                    toilet_id: toilet_id,
                    gateway_id: gateway_id,
                    address: address,
                    sex_display: this.sex_text_to_sex_display(sex_display),
                    number_floor: number_floor,
                    // no: this.toilet_data ? this.toilet_data.length + 1 : 1,
                    is_new: true
                }
                this.toilet_data = this.toilet_data.concat([new_toilet]);
                this.itemUpdate = this.itemUpdate.concat([new_toilet]);
            } else {
                this.update_current_toilet_by_id(toilet_id, {gateway_id, address, sex_display, number_floor});
            }
        }
    },
    sex_text_to_sex_display(txt) {
        if(!this.list_sex) return null;
        for(var i = 0; i < this.list_sex.length; i++) {
            if(this.list_sex[i].name === txt) {
                return this.list_sex[i];
            }
        }
        return null;
    },
    update_current_toilet_by_id(toilet_id, data) {
        const { gateway_id, address, sex_display, number_floor } = data;
        for(var i = 0; i < this.toilet_data.length; i++) {
            if(this.toilet_data[i].toilet_id === toilet_id) {
                this.toilet_data[i]['gateway_id'] = gateway_id;
                this.toilet_data[i]['address'] = address;
                this.toilet_data[i]['sex_display'] = this.sex_text_to_sex_display(sex_display);
                this.toilet_data[i]['number_floor'] = number_floor;
                this.itemUpdate = this.itemUpdate.concat([this.toilet_data[i]]);
                return;
            }
        }
    }
  },
  mounted() {
      this.readData();
  },
  created() {
      this.$store.dispatch("toilet/fetch_list_sex");
      this.$store.dispatch("facility/fetch_facility_by_id", { id: this.$route.params.id });
      this.$store.dispatch("toilet/fetch_list_toilet_by_facility_id", { facility_id: this.$route.params.id });
  }
};
</script>

