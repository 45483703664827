<template>
  <v-container fluid>
    <Subheader />
    <loading :active.sync="isLoading" :is-full-page="true" color="#8089ff" loader="bars"></loading>
    <div class="tables-basic">
      <h4 class="page-title mt-10 mb-6"></h4>
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-form ref="form">
              <v-container>
                <v-row class="flex-column" align="center" justify="center">
                  <v-col>
                    <p class="login-slogan display-1 text-center font-weight-medium mt-6 mb-10">
                      キャンペーンを編集する
                    </p>
                  </v-col>
                  <v-col v-show="uuid" cols="6">
                    <v-text-field
                      v-model="name"
                      :rules="[v => !!v || '必須項目のため入力してください']"
                      label="キャンペーン名"
                      required
                    ></v-text-field>
                    <v-select
                        v-model="campaign_category"
                        :items="list_campaign_categories || []"
                        :rules="[v => !!v || '必須項目のため入力してください']"
                        item-text="name"
                        item-value="id"
                        label="キャンペーン区分"
                        required
                    ></v-select>
                    <!-- <v-select
                        v-model="campaign_type"
                        :items="list_campaign_types"
                        :rules="[v => !!v || '必須項目のため入力してください']"
                        item-text="name"
                        item-value="id"
                        label="キャンペーンタイプ"
                        required
                    ></v-select> -->
                    
                    <div class="d-flex create-campaign__group-date">
                        <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="start_date"
                                label="配信開始日"
                                v-bind="attrs"
                                v-on="on"
                                :rules="[v => !!v || '必須項目のため入力してください', v => new Date(v) <= new Date(end_date) || '配信開始日が配信終了日より後の日付になっています。']"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="start_date"
                                no-title
                                @input="menu1 = false"
                            ></v-date-picker>
                        </v-menu>
                        <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="end_date"
                                label="配信終了日"
                                v-bind="attrs"
                                v-on="on"
                                :rules="[v => !!v || '必須項目のため入力してください']"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="end_date"
                                no-title
                                @input="menu2 = false"
                            ></v-date-picker>
                        </v-menu>
                    </div>

                    
                    <v-text-field
                      v-model="imp"
                      type="number"
                      :rules="[v => !!v || '必須項目のため入力してください', v => parseInt(v) >= 1 || '1以上の値を入力してください']"
                      label="購入imp数"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="imp_price"
                      type="number"
                      label="imp単価"
                    ></v-text-field>

                  </v-col>


                  <v-col cols="6" v-if="campaign_category === 1">
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="place_str"
                          readonly
                          label="場所"
                          v-bind="attrs"
                          v-on="on"
                          :rules="[v => !!v || '必須項目のため入力してください']"
                        ></v-text-field>
                      </template>
                      <v-treeview
                        v-model="place"
                        selectable
                        item-disabled="locked"
                        :items="address"
                        class="bg-white"
                        return-object
                      ></v-treeview>
                    </v-menu>

                    <!-- <v-select
                        v-model="place"
                        :items="master.address || []"
                        :rules="[v => !!v.length > 0 || '必須項目のため入力してください']"
                        :menu-props="{ maxHeight: '400' }"
                        label="場所"
                        item-text="name"
                        item-value="id"
                        multiple
                    ></v-select> -->
                    <v-select
                        v-model="toilet"
                        :items="master.toiletType || []"
                        :rules="[v => !!v.length > 0 || '必須項目のため入力してください']"
                        :menu-props="{ maxHeight: '400' }"
                        label="トイレ区分"
                        item-text="name"
                        item-value="id"
                        multiple
                    ></v-select>
                    <v-select
                        v-model="classification"
                        :items="master.categoryType"
                        :rules="[v => !!v.length > 0 || '必須項目のため入力してください']"
                        :menu-props="{ maxHeight: '400' }"
                        label="施設区分"
                        item-text="name"
                        item-value="id"
                        multiple
                    ></v-select>

                    <div class="d-flex create-campaign__group-date">
                        <div style="width:50%">
                          <v-select
                              v-model="year"
                              :items="master.year || []"
                              :rules="[v => !!v.length > 0 || '必須項目のため入力してください']"
                              :menu-props="{ maxHeight: '400' }"
                              label="年代"
                              item-text="name"
                              item-value="id"
                              multiple
                          ></v-select>
                        </div>
                        <div style="width:50%">
                          <v-select
                              v-model="gender"
                              :items="master.sex || []"
                              :rules="[v => !!v.length > 0 || '必須項目のため入力してください']"
                              :menu-props="{ maxHeight: '400' }"
                              label="性別"
                              item-text="name"
                              item-value="id"
                              multiple
                          ></v-select>
                        </div>
                    </div>
                  </v-col>

                  <v-col cols="6" v-if="campaign_category === 2">
                                        <v-select
                        v-model="toilet"
                        :items="master.toiletType || []"
                        :rules="[v => !!v.length > 0 || '必須項目のため入力してください']"
                        :menu-props="{ maxHeight: '400' }"
                        label="トイレ区分"
                        item-text="name"
                        item-value="id"
                        multiple
                    ></v-select>
                                            <div style="width:50%">
                          <v-select
                              v-model="gender"
                              :items="master.sex || []"
                              :rules="[v => !!v.length > 0 || '必須項目のため入力してください']"
                              :menu-props="{ maxHeight: '400' }"
                              label="性別"
                              item-text="name"
                              item-value="id"
                              multiple
                          ></v-select>
                        </div>
                    <v-autocomplete
                        v-model="facilities"
                        :rules="[v => !!v.length > 0 || '必須項目のため入力してください']"
                        :items="list_facilities"
                        :search-input.sync="search_facilities"
                        dense
                        chips
                        small-chips
                        label="配信施設"
                        item-text="name"
                        item-value="id"
                        multiple
                    ></v-autocomplete>
                  </v-col>


                  <v-col cols="6">
                      <v-btn class="text-capitalize mt-6" color="primary" width="100%" @click="submit">
                        保存
                      </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Subheader from '@/components/Subheader/Subheader';
import Loading from 'vue-loading-overlay';
export default {
  name: "CreateCampaign",
  components: {
      Subheader, Loading
  },
  data() {
    return {
      isLoading: false,
      uuid: "",
      client_id: "",
      name: "",
      campaign_type: "",
      campaign_category: "",
      imp: 0,
      imp_price: 0,
      place: [],
      place_str: "",
      toilet: [],
      classification: [],
      year: [],
      gender: [],
      facilities: [],

      query_facility: '',
      list_facilities: [],

      start_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      menu3: false,
      list_campaign_categories: [{ name: "セグメント", id: 1 }, { name: "施設選択", id: 2 }],
      list_campaign_types: [{ name: "プレミアム", id: 1 }, { name: "ノーマル", id: 2 }],

    };
  },
  computed: {
      master() {
        return this.$store.getters['campaign/get_campaign_master'];
      },
      address() {
        const address = this.$store.getters['campaign/get_campaign_address'];

        const ret = []
        for(const i in address) {
          if(address[i].districts) {
            if(address[i].districts.length > 0) {
              ret.push({
                id: `location_${address[i].id}`,
                name: address[i].name,
                children: address[i].districts,
                is_location: true
              })
            }
          }
        }

        return ret;
      },
      search_facilities: {
        get() {
          return this.query_facility || ''
        },
        
        set(search_input) {
          if (this.query_facility !== search_input) {
            this.query_facility = search_input
            this.fetch_facilities()
          }
        }
      },
      campaign() {
          return this.$store.getters['campaign/get_campaign'];
      },
  },
  watch: {
    place(val) {
      // this.place_str = val.map(e => e.name).join(", ");
      var tmp = [];
      for(const i in val) {
        if(!val[i].is_location) {
          var location_name = this.get_location_name(val[i].location_id);
          tmp.push(`${location_name} ${val[i].name}`);
        } else {
          tmp.push(val[i].name);
        }
      }
      this.place_str = tmp.join("、");
    },
    campaign(val) {
      setTimeout(() => {
        this.uuid = val.uuid;
      }, 300)

      this.client_id = val.client_id;
      this.name = val.name;
      this.campaign_category = val.campaign_category;
      this.campaign_type = val.campaign_type;
      this.imp = val.imp_expected;
      this.imp_price = val.imp_price;
      this.start_date = val.release_start_at ? val.release_start_at.substr(0, 10) : '';
      this.end_date = val.release_end_at ? val.release_end_at.substr(0, 10) : '';

          
      var tmp_location = [];
      for(const i in val.locations) {
        tmp_location.push({
          id: `location_${val.locations[i].id}`,
          name: val.locations[i].name,
          is_location: true
        })
      }
      this.place = tmp_location && val.districts ? tmp_location.concat(val.districts) : [];

      this.toilet = val.toilet_type_display ? val.toilet_type_display.map(e => e.id) : [];
      this.classification = val.category_type_display ? val.category_type_display.map(e => e.id) : [];
      this.year = val.year_display ? val.year_display.map(e => e.id) : [];
      this.gender = val.sex_display ? val.sex_display.map(e => e.id) : [];
      this.facilities = val.facilities ? val.facilities.map(e => e.id) : [];

    }
  },
  methods: {
    fetch_master() {
      this.$store.dispatch("campaign/fetch_master");
    },
    submit() {
      if(this.$refs.form.validate()) {
        // console.log("params:", this.name, this.campaign_type, this.start_date, this.end_date, this.campaign_category, this.imp, this.place, this.stores, this.gender)
        const [sy, sm, sd] = this.start_date ? this.start_date.split("-") : ['', '', '']
        const [ey, em, ed] = this.end_date ? this.end_date.split("-") : ['', '', '']
        const formatted_start_date = `${sy}/${sm}/${sd}`;
        const formatted_end_date = `${ey}/${em}/${ed}`;

        var location_id = [];
        var district_id = [];
        for(var i in this.place) {
          if(this.place[i].is_location) {
            location_id.push(this.place[i].id.replace("location_", ""));
          } else {
            district_id.push(this.place[i].id);
          }
        }

        var data = {
          uuid: this.uuid, client_id: this.client_id, name: this.name, campaign_category: this.campaign_category,
          release_start_at: formatted_start_date, release_end_at: formatted_end_date, imp_expected: this.imp, imp_price: this.imp_price,
          location_id: location_id, district_id: district_id, toilet_type: this.toilet, category_type: this.classification, year: this.year, sex: this.gender, facility_id: this.facilities,
          $toast: this.$toast, $router: this.$router
        };
        // fixed params : se sua sau khi sua lai api create campaign
        if(this.campaign_category == 1) {
          delete data.facility_id;
        }
        if(this.campaign_category == 2) {
          delete data.location_id;
          delete data.district_id;
          delete data.category_type;
          delete data.year;
        }
        // end fixed
        this.$store.dispatch("campaign/update_campaign", data);
      }
    },
    async fetch_facilities () {
      this.list_facilities = await this.$store.dispatch("campaign/fetch_facilities", { q: this.query_facility });
    },
    async fetch_address () {
      this.$store.dispatch("campaign/fetch_address");
    },
    get_location_name(location_id) {
      const address = this.$store.getters['campaign/get_campaign_address'];
      for(const i in address) {
        if(address[i].id == location_id) {
          return address[i].name;
        }
      }
      return '';
    }
  },
  async created() {
    this.isLoading = true;
    await this.fetch_master();
    await this.fetch_facilities();
    await this.fetch_address();

    if(this.$route.params.id) {
        await this.$store.dispatch("campaign/fetch_campaign_by_id", { id: this.$route.params.id })
    }
    this.isLoading = false;
  }
};
</script>

<style src="./Campaign.scss" lang="scss"></style>