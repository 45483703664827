import ApiService from "@/services/api.service";
import config from "@/config";

export default {

    fetch_list_professions(context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/api/list-professions")
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_LIST_PROFESSIONS', response.data.data);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    fetch_list_clients(context, payload) {
        let { page, sort_by, sort_type } = payload;
        if(!sort_by) sort_by = 'id';
        if(!sort_type) sort_type = 'desc';
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/client/list?page=${page}&sort=${sort_by}&order_by=${sort_type}`)
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_LIST_CLIENTS', response.data.data);
                        context.commit('SET_CLIENTS_META', response.data.meta);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    fetch_client_by_id(context, payload) {
        const { id } = payload;
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/client/${id}`)
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_CLIENT', response.data.data);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    create_client(context, payload) {
        const { name, profession_id, $toast, $router } = payload;
        return new Promise((resolve, reject) => {
            ApiService.post("/api/client", { name, profession_id })
                .then(response => {
                    if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                        $router.go(-1);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    update_client(context, payload) {
        const { id, name, profession_id, $toast, $router } = payload;
        return new Promise((resolve, reject) => {
            ApiService.put(`/api/client/${id}`, { name, profession_id })
                .then(response => {
                    if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                        $router.go(-1);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    update_enable(context, payload) {
        const { id, $toast } = payload;
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/client/isUpdateEnable/${id}`)
                .then(response => {
                    if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                        // $router.go(-1);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    empty(context) {
        context.commit('SET_LIST_CLIENTS', []);
        context.commit('SET_CLIENTS_META', {});
    }
}
