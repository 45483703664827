<template>
    <v-dialog v-model="show" persistent max-width="600px">
        <v-card>
            <v-card-title>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row class="px-6 py-6 d-flex" style="flex-direction:column;">
                        <div class="text-center">
                            <h3>掲載可否</h3>
                        </div>
                        <div class="d-flex align-center">
                            <table>
                                <tr>
                                    <td :style="{width: '170px', padding: '8px'}">キャンペーン</td>
                                    <td>{{ item.name }}</td>
                                </tr>
                                <tr>
                                    <td :style="{width: '170px', padding: '8px'}">広告主 </td>
                                    <td>{{ item.client_name }}</td>
                                </tr>
                                <tr>
                                    <td :style="{width: '170px', padding: '8px'}">広告カテゴリ</td>
                                    <td>{{ item.profession_name }}</td>
                                </tr>
                                <tr>
                                    <td :style="{width: '170px', padding: '8px'}">申込日</td>
                                    <td>{{ new Date(item.registed_at).toLocaleDateString("ja-JP") }}</td>
                                </tr>
                                <tr>
                                    <td :style="{width: '170px', padding: '8px'}">確認期日</td>
                                    <td>{{ new Date(item.expired_at).toLocaleDateString("ja-JP") }}</td>
                                </tr>
                                <tr>
                                    <td :style="{width: '170px', padding: '8px'}"></td>
                                    <td>※確認期日を過ぎている場合は掲載を拒否できません。もし掲載不可としたい場合には、直接TOILET xAdbox相談窓口までご連絡ください。</td>
                                </tr>
                            </table>
                            
                        </div>
                        <div class="mt-10 d-flex justify-center gap-20">
                            <v-btn :disabled="item.confirm_status === 2" @click="confirm_status(2)">
                                掲載を拒否する
                            </v-btn>
                            <v-btn :disabled="item.confirm_status === 1" color="primary" @click="confirm_status(1)">
                                掲載を許可する
                            </v-btn>
                        </div>
                        <div class="mt-10 d-flex justify-center">
                            <v-btn @click="show=false">× 閉じる</v-btn>
                        </div>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>


export default {
    name: "DLModal",
    props: {
        value: Boolean,
        item: Object,
        facility_id: String
    },
    data() {
        return {
            menu1: false,
            dates: []
        }
    },
    computed: {
        show: {
            get () {
                return this.value;
            },
            set (value) {
                if(!value) this.dates = [];
                this.$emit('input', value);
            }
        },
        dateRangeText() {
            return this.dates.join(' ~ ')
        }
    },
    methods: {
        confirm_status(confirm_status) {
            this.$store.dispatch(`facility/update_confirm_status`, { facility_id: this.facility_id, uuid: this.item.uuid, confirm_status, $toast: this.$toast })
                .then(() => {
                    this.$emit('confirmed');
                    this.show = false;
                }).catch(() => {
                    this.show = false;
                })
        }
    }
};
</script>

