export default {
    get_list_toilets(state) {
        return state.list_toilets;
    },
    get_toilets_meta(state) {
        return state.toilets_meta;
    },
    get_list_toilet_by_facility_id(state) {
        return state.list_toilet_by_facility_id;
    },
    get_toilet_by_facility_meta(state) {
        return state.toilet_by_facility_meta;
    },
    get_toilet(state) {
        return state.toilet;
    },
    get_list_sex(state) {
        return state.list_sex;
    }
}
