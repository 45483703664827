<template>
  <v-container fluid>
      <Subheader :breadcrumbs="breadcrumbs" :disable-back="true" />
      <DLModal v-model="showDLModal" :url="downloadURL" :title="titleDL" :subtitle="subtitleDL" :extendLabel="extendLabelDL" :extendValue="extendValueDL" />
      <FilterModal v-model="showFilterModal" @onFilter="filter_list" />
    <div class="tables-basic">
      <v-row>
        <v-col cols="12">
            <v-tabs grow>
                <v-tabs-slider></v-tabs-slider>
                <v-tab :href="`#manager`">媒体施設一覧</v-tab>
                <!-- <v-tab :href="`#suggest`">提案機能</v-tab>
                <v-tab :href="`#report`">レポーティング</v-tab> -->

                <v-tab-item :value="'manager'" >
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <router-link to="/facilities/create"><v-btn color="primary"><v-icon>mdi-plus</v-icon>施設登録する</v-btn></router-link>
                            <v-spacer></v-spacer>
                            <v-btn @click="clickDLTotalFacilityReport" color="primary">施設一覧成果DL</v-btn>
                        </v-card-title>
                        <div class="my-4 mx-6">
                            <v-btn color="primary" @click="show_filter">絞り込み</v-btn>
                        </div>
                        <v-data-table
                        :headers="headers"
                        :items="list_facilities"
                        :loading="loading"
                        loading-text="読み込み中"
                        :options.sync="options"
                        @pagination="update_page"
                        :server-items-length="total"
                        :items-per-page="items_per_page"
                        :page="page"
                        :footer-props="{
                            'disable-items-per-page': true,
                            'items-per-page-options': [ 50, 100 ],
                            'items-per-page-text':'ページごとに表示する件数'
                        }"
                        item-key="id"
                        >
                            <template v-slot:[`item.status`]="{ item }">
                                {{ get_status_text(item.status) }}
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                <div class="text-truncate" style="max-width: 300px;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                                        </template>
                                        <span>{{ item.name }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.type_display`]="{ item }">
                                {{ item.type_display ? item.type_display.name : '' }}
                            </template>
                            <template v-slot:[`item.address`]="{ item }">
                                {{ item.locations ? item.locations.name : '' }} <br />
                                {{ item.districts ? item.districts.name : '' }}
                            </template>
                            <!-- <template v-slot:[`item.edit`]="{ item }">
                                <div class="d-flex gap-10">
                                    <router-link :to="`/clients/edit/${item.id}`" class="v-btn v-size--small primary v-btn--is-elevated">編集</router-link>
                                    <v-btn @click="download_row(item)" small>データDL</v-btn>
                                </div>
                            </template> -->
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-menu :key="item.id" offset-y open-on-hover>
                                    <template v-slot:activator="{ attrs, on }">
                                        <span class="text-actions" v-bind="attrs" v-on="on">アクション</span>
                                    </template>

                                    <v-list>
                                        <v-list-item v-for="act in [{text: '施設詳細', link: `/facilities/${item.id}/detail`}, {text: '出稿希望キャンペーン一覧', link: `/facilities/${item.id}/campaign/desired`}, {text: 'レポートDL', link: '', is_dl: true}]" :key="act.text" link :to="act.link">
                                            <v-list-item-title v-if="!act.is_dl">
                                                {{ act.text }}
                                            </v-list-item-title>
                                            <v-list-item-title v-else @click="clickDLFacilityByIdReport(item)">
                                                <span class="action-item">{{ act.text }}</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                            <template v-slot:no-data>
                                データがありません
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import Subheader from '@/components/Subheader/Subheader'
import DLModal from '@/components/DLModal'
import FilterModal from '@/components/Popup/FacilityFilter'

export default {
  name: "ListFacility",
  components: {
      Subheader,
      DLModal,
      FilterModal
  },
  data() {
    return {
        sampleSwitch: false,
        headers: [
            { text: "施設名", align: "start", value: "name", width: "300px", sortable: true },
            { text: "住所", value: "address", sortable: true },
            { text: "施設区分", value: "type_display", sortable: true },
            { text: "imp数", value: "sum_imp", sortable: true },
            { text: "ステータス", value: "status", sortable: true },
            { text: "出稿希望キャンペーン数", value: "desired", width: "200px", sortable: true },
            { text: "アクション", value: "actions", width: "120px", sortable: false },
        ],
        breadcrumbs: [
            {
                text: '媒体施設一覧',
                disabled: true,
                underline: false,
                href: '/facilities?page=1',
            },
        ],
        options: {},
        loading: false,
        showDLModal: false,
        downloadURL: '',
        showFilterModal: false,

        query_name: '',
        query_address: '',
        query_type: '',
        titleDL: '',
        subtitleDL: '',
        extendLabelDL: '',
        extendValueDL: '',
    };
  },
  computed: {
      list_facilities() {
          return this.$store.getters['facility/get_list_facilities'];
      },
      page() {
          if(this.$route.query.page) {
              return parseInt(this.$route.query.page);
          }
          const meta = this.$store.getters['facility/get_facilities_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.current_page) {
                      return meta.pagination.current_page;
                  }
              }
          }
          return 1;
      },
      total() {
          const meta = this.$store.getters['facility/get_facilities_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.total) {
                      return meta.pagination.total;
                  }
              }
          }
          return 0;
      },
      items_per_page() {
          const meta = this.$store.getters['facility/get_facilities_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.per_page) {
                      return meta.pagination.per_page;
                  }
              }
          }
          return 0;
      },
  },
  watch: {
      options: {
        handler () {
          this.fetch_list_facilities()
        },
        deep: true,
      },
  },
  methods: {
    fetch_list_facilities() {
        const self = this;
        self.loading = true;
        const page = this.$route.query.page || 1;
        // if(!page) return;
        const payload = { page };

        const { sortBy, sortDesc } = this.options
        payload.sort_by = sortBy[0] ? sortBy[0] : 'id';
        payload.sort_type = sortDesc[0] ? 'desc' : 'asc';
        payload.name = this.query_name;
        payload.address = this.query_address;
        payload.type = this.query_type;

        this.$router.push({
            name: "ListFacility",
            query: { page }
        }).catch(() => { });

        this.$store.dispatch("facility/fetch_list_facilities", payload).then(() => {
            self.loading = false;
        }).catch(() => {
            self.loading = false;
        });
    },
    update_page(pagination) {
        const page  = pagination.page;

        this.$router.push({
            name: "ListFacility",
            query: { page }
        }).catch(() => { });
        this.fetch_list_facilities();
    },
    get_status_text(status) {
        switch(status) {
            case 1:
                return "稼働中";
            case 2:
                return "停止中";
            default:
                return '';
        }
    },
    clickDLTotalFacilityReport() {
        this.downloadURL = '/api/facility/download/csv';
        this.showDLModal = true;
        this.titleDL = '全体レポートDL';
    },
    clickDLFacilityByIdReport(item) {
        this.downloadURL = `/api/facility/download/csv/${item.id}`;
        this.showDLModal = true;
        this.titleDL = '個別施設レポート　レポートDL';
        this.extendLabelDL = '施設名';
        this.extendValueDL = item.name;
    },
    show_filter() {
        this.showFilterModal = true;
    },
    filter_list(data) {
        this.showFilterModal = false;
        this.query_name = data ? data.name : '';
        this.query_address = data ? data.address : '';
        this.query_type = data ? (data.type ? data.type.join(',') : '') : '';
        this.fetch_list_facilities();
    }
  },
  mounted() {
      
  },
  created() {
      this.$store.dispatch("facility/empty");
  }
};
</script>

