const bank_options = [
    { id: 1, text: 'みずほ銀行', value: 'みずほ銀行' },
    { id: 2, text: '三菱ＵＦＪ銀行', value: '三菱ＵＦＪ銀行' },
    { id: 3, text: '三井住友銀行', value: '三井住友銀行' },
    { id: 4, text: 'りそな銀行', value: 'りそな銀行' },
    { id: 6, text: '三菱UFJ信託銀行', value: '三菱UFJ信託銀行' },
    { id: 7, text: 'みずほ信託銀行', value: 'みずほ信託銀行' },
    { id: 8, text: '三井住友信託銀行', value: '三井住友信託銀行' },
    { id: 9, text: 'バンクオブニューヨークメロン信託銀行', value: 'バンクオブニューヨークメロン信託銀行' },
    { id: 10, text: '日本マスタートラスト信託銀行', value: '日本マスタートラスト信託銀行' },
    { id: 11, text: '野村信託銀行', value: '野村信託銀行' },
];

const branch_options = [
    {
      parent_id: 1,
      list: [
        { text: '東京営業部', value: '東京営業部' },
        { text: '東京都庁公営企業出張所', value: '東京都庁公営企業出張所' },
        { text: '丸の内中央支店', value: '丸の内中央支店' },
        { text: '丸之内支店', value: '丸之内支店' },
        { text: '神田駅前支店', value: '神田駅前支店' },
        { text: '町村会館出張所', value: '町村会館出張所' },
        { text: '築地支店', value: '築地支店' },
        { text: '確定拠出年金支店', value: '確定拠出年金支店' },
        { text: '押上支店', value: '押上支店' },
        { text: '麹町支店', value: '麹町支店' },
        { text: '浅草橋支店', value: '浅草橋支店' },
      ]
    },
    {
      parent_id: 2,
      list: [
        { text: '本店', value: '本店' },
        { text: '丸の内支店', value: '丸の内支店' },
        { text: '瓦町支店', value: '瓦町支店' },
        { text: '船場支店', value: '船場支店' },
        { text: '大阪営業部', value: '大阪営業部' },
        { text: '歌島橋支店', value: '歌島橋支店' },
        { text: '玉造支店', value: '玉造支店' },
        { text: '神田駅前支店', value: '神田駅前支店' },
        { text: '大東支店', value: '大東支店' },
        { text: '伊勢支店', value: '伊勢支店' },
        { text: '神保町支店', value: '神保町支店' },
        { text: '市け谷支店', value: '市け谷支店' },
      ]
    },
    {
      parent_id: 3,
      list: [
        { text: '春日部支店', value: '春日部支店' },
        { text: '新潟支店', value: '新潟支店' },
        { text: '長野支店', value: '長野支店' },
        { text: '上田支店', value: '上田支店' },
        { text: '牛久支店', value: '牛久支店' },
        { text: '東京中央支店', value: '東京中央支店' },
        { text: '諏訪支店', value: '諏訪支店' },
        { text: '矢向支店', value: '矢向支店' },
        { text: '小山支店', value: '小山支店' },
        { text: '銀座支店', value: '銀座支店' },
      ]
    },
    {
      parent_id: 4,
      list: [
        { text: '天理支店', value: '天理支店' },
        { text: '吉野支店', value: '吉野支店' },
        { text: '東生駒支店', value: '東生駒支店' },
        { text: '小泉支店', value: '小泉支店' },
        { text: '学園大和町支店', value: '学園大和町支店' },
        { text: '年金管理サービス支店', value: '年金管理サービス支店' },
        { text: '新奈良営業部', value: '新奈良営業部' },
        { text: '大阪営業部', value: '大阪営業部' },
        { text: '大阪公務部', value: '大阪公務部' },
        { text: '大阪不動産部', value: '大阪不動産部' },
        { text: 'ＪＲ西日本出張所', value: 'ＪＲ西日本出張所' },
        { text: '証券信託業務支店', value: '証券信託業務支店' },
      ]
    },
    {
      parent_id: 5,
      list: [
        { text: '県庁支店', value: '県庁支店' },
        { text: '浦和中央支店', value: '浦和中央支店' },
        { text: '浦和東口支店', value: '浦和東口支店' },
        { text: '北浦和支店', value: '北浦和支店' },
        { text: '南越谷支店', value: '南越谷支店' },
        { text: '日高支店', value: '日高支店' },
        { text: '伊奈支店', value: '伊奈支店' },
        { text: '川口南平支店', value: '川口南平支店' },
        { text: '与野支店', value: '与野支店' },
        { text: '土呂出張所', value: '土呂出張所' },
        { text: 'せんげん台支店', value: 'せんげん台支店' },
      ]
    }
]

export { bank_options, branch_options }