<template>
  <v-container fluid>
      <Subheader :breadcrumbs="breadcrumbs" :backLink="backLink" />
      <DLModal v-model="showDLModal" :url="downloadURL" :title="titleDL" :subtitle="subtitleDL" />
      <ConfirmCampaignModal v-model="showConfirmModal" :item="campaign_item" :facility_id="facility_id" @confirmed="reload_list" />
    <div class="tables-basic">
      <v-row>
        <v-col cols="12">
            <v-tabs grow>
                <v-tabs-slider></v-tabs-slider>
                <v-tab :to="`/facilities/${facility_id}/campaign/desired`">出稿希望キャンペーン一覧</v-tab>
                <v-tab :to="`/facilities/${facility_id}/campaign/ok`">配信中キャンペーン一覧</v-tab>
                <v-tab :to="`/facilities/${facility_id}/campaign/ng`">NGキャンペーン一覧</v-tab>
            </v-tabs>

            <div>
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="clickDLReport">全体レポートDL</v-btn>
                        </v-card-title>
                        <v-data-table
                        :headers="headers"
                        :items="list_desired_campaign"
                        :loading="loading"
                        loading-text="読み込み中"
                        :options.sync="options"
                        :items-per-page="1000"
                        hide-default-footer
                        item-key="id"
                        >
                            <template v-slot:[`item.name`]="{ item }">
                                <div class="text-truncate" style="max-width: 200px;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                                        </template>
                                        <span>{{ item.name }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                {{ get_status_text(item.status) }}
                            </template>
                            <template v-slot:[`item.registed_at`]="{ item }">
                                {{ format_date(item.registed_at) }}
                            </template>
                            <template v-slot:[`item.expired_at`]="{ item }">
                                {{ format_date(item.expired_at) }}
                            </template>
                            <template v-slot:[`item.total_cost`]="{ item }">
                                ¥{{ item.total_cost }}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-menu :key="item.id" offset-y open-on-hover>
                                    <template v-slot:activator="{ attrs, on }">
                                        <span class="text-actions" v-bind="attrs" v-on="on">アクション</span>
                                    </template>

                                    <v-list>
                                        <v-list-item v-for="act in [{text: 'クリエイティブ一覧', link: `/facilities/${facility_id}/ok_creatives?client_id=${item.client_id}&campaign_uuid=${item.uuid}`}, {text: '配信停止', link: ``, is_popup: true}]" :key="act.text" link :to="act.link">
                                            <v-list-item-title v-if="!act.is_popup">
                                                {{ act.text }}
                                            </v-list-item-title>
                                            <v-list-item-title v-else @click="confirm_status(item, 2)">
                                                <span class="action-item">{{ act.text }}</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                            <template v-slot:no-data>
                                データがありません
                            </template>
                        </v-data-table>
                    </v-card>
                </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>


<script>
import ConfirmCampaignModal from '@/components/Popup/ConfirmCampaign'
import Subheader from '@/components/Subheader/Subheader'
import DLModal from '@/components/DLModal'

export default {
  name: "OkCampaign",
  components: {
      ConfirmCampaignModal,
      Subheader,
      DLModal
  },
  data() {
    return {
        sampleSwitch: false,
        headers: [
            { text: "キャンペーン", align: "start", value: "name", width: "200px", sortable: true },
            { text: "広告主", value: "client_name", sortable: true },
            { text: "広告カテゴリ", value: "profession_name", sortable: true },
            { text: "施設別 imp数", value: "sum_imp", sortable: true },
            { text: "施設別 支払い金額", value: "total_cost", sortable: true },
            { text: "アクション", value: "actions", sortable: false },
        ],
        options: {},
        loading: false,
        showConfirmModal: false,
        campaign_item: {},
        showDLModal: false,
        downloadURL: '',
        titleDL: '',
        subtitleDL: '',
    };
  },
  computed: {
      facility_id() {
          return this.$route.params.id;
      },
      list_desired_campaign() {
          return this.$store.getters['facility/get_desired_campaigns'];
      },
      facility_name() {
          return this.$store.getters['facility/get_facility'] ? this.$store.getters['facility/get_facility'].name : '';
      },
      breadcrumbs() {
          return [
            {
                text: '媒体施設一覧',
                disabled: false,
                underline: false,
                href: '/facilities?page=1',
            },
            {
                text: this.facility_name,
                disabled: false,
                underline: false,
                href: `/facilities/${this.facility_id}/detail`,
            },
            {
                text: '出稿希望キャンペーン一覧',
                disabled: true,
                underline: true,
                href: '#',
            },
        ]
      },
      backLink() {
        return `/facilities?page=1`;
      }
  },
  watch: {
      
  },
  methods: {
    fetch_desired_campaigns() {
        const self = this;
        self.loading = true;
        
        this.$store.dispatch("facility/fetch_desired_campaigns", {id: this.facility_id, confirm_status: 1}).then(() => {
            self.loading = false;
        }).catch(() => {
            self.loading = false;
        });
    },
    clickConfirmCampaign(item) {
        this.campaign_item = item;
        this.showConfirmModal = true;
    },
    format_date(date) {
        return new Date(date).toLocaleDateString("ja-JP");
    },
    reload_list() {
        this.fetch_desired_campaigns();
    },
    clickDLReport() {
        this.downloadURL = `/api/facility/${this.facility_id}/campaign/ok/download/csv`;
        this.showDLModal = true;
        this.titleDL = 'レポートDL';
    },
    confirm_status(item, confirm_status) {
        this.$store.dispatch(`facility/update_confirm_status`, { facility_id: this.facility_id, uuid: item.uuid, confirm_status, $toast: this.$toast })
        .then(() => {
            this.fetch_desired_campaigns();
        }).catch(() => {
        })
    }
  },
  mounted() {
      this.fetch_desired_campaigns();
      if(this.$route.params.id) {
          this.$store.dispatch("facility/fetch_facility_by_id", { id: this.$route.params.id })
      }
  },
  created() {
  }
};
</script>
