import ApiService from "@/services/api.service";
import config from "@/config";

export default {

    fetch_list_facilities(context, payload) {
        let { page, sort_by, sort_type, name, address, type } = payload;
        if(!sort_by) sort_by = 'id';
        if(!sort_type) sort_type = 'desc';
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/facility/list?page=${page}&sort=${sort_by}&order_by=${sort_type}&name=${name}&address=${address}&type=${type}`)
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_LIST_FACILITIES', response.data.data);
                        context.commit('SET_FACILITIES_META', response.data.meta);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },

    fetch_list_facilities_by_campaign(context, payload) {
        let { page, sort_by, sort_type, campaign_uuid } = payload;
        if(!sort_by) sort_by = 'id';
        if(!sort_type) sort_type = 'desc';
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/facility/list/${campaign_uuid}?page=${page}&sort=${sort_by}&order_by=${sort_type}`)
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_LIST_FACILITIES_BY_CAMPAIGN', response.data.data);
                        context.commit('SET_FACILITIES_META_BY_CAMPAIGN', response.data.meta);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },

    fetch_facility_by_id(context, payload) {
        const { id } = payload;
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/facility/${id}`)
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_FACILITY', response.data.data);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    
    create_facility(context, payload) {
        // const { name, type, profession_id, location_id, district_id, zip_code, house_number, building_name, working_hours, phone_number, email, person_in_charge, bank, branch_bank, currency, account_number, min_age, max_age, revenue_share_percent, ng_profession_id, $toast, $router } = payload;
        const { name, type, profession_id, location_id, district_id, zip_code, house_number, building_name, working_hours, phone_number, email, person_in_charge, bank, branch_bank, currency, account_number, start_business_hours, end_business_hours, target_age, revenue_share_percent, ng_profession_id, audio, $toast, $router } = payload;
        return new Promise((resolve, reject) => {
            // ApiService.post("/api/facility", { name, type, profession_id, location_id, district_id, zip_code, house_number, building_name, working_hours, phone_number, email, person_in_charge, bank, branch_bank, currency, account_number, min_age, max_age, revenue_share_percent, ng_profession_id })
            ApiService.post("/api/facility", { name, type, profession_id, location_id, district_id, zip_code, house_number, building_name, working_hours, phone_number, email, person_in_charge, bank, branch_bank, currency, account_number, start_business_hours, end_business_hours, target_age, revenue_share_percent, ng_profession_id, audio })
                .then(response => {
                    if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                        $router.go(-1);
                    }
                    resolve(response);
                })
                .catch(e => {
                    if(e.response.data) {
                        if(e.response.data.message) {
                            if(e.response.data.message.name) {
                                $toast.error(e.response.data.message.name[0], config.toast);
                            }
                        }
                    }
                    reject(e.response);
                });
        });
    },

    update_info(context, payload) {
        // const { id, type, location_id, district_id, zip_code, house_number, building_name, working_hours, phone_number, email, person_in_charge, bank, branch_bank, currency, account_number, min_age, max_age, revenue_share_percent, number_male_toilet, number_female_toilet, number_ostomate, $toast } = payload;
        const { id, type, location_id, district_id, zip_code, house_number, building_name, working_hours, phone_number, email, person_in_charge, bank, branch_bank, currency, account_number, start_business_hours, end_business_hours, target_age, revenue_share_percent, number_male_toilet, number_female_toilet, number_ostomate, audio, $toast } = payload;
        return new Promise((resolve, reject) => {
            // ApiService.put(`/api/facility/${id}`, { type, location_id, district_id, zip_code, house_number, building_name, working_hours, phone_number, email, person_in_charge, bank, branch_bank, currency, account_number, min_age, max_age, revenue_share_percent, number_male_toilet, number_female_toilet, number_ostomate })
            ApiService.put(`/api/facility/${id}`, { type, location_id, district_id, zip_code, house_number, building_name, working_hours, phone_number, email, person_in_charge, bank, branch_bank, currency, account_number, start_business_hours, end_business_hours, target_age, revenue_share_percent, number_male_toilet, number_female_toilet, number_ostomate, audio })
                .then(response => {
                    if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                    }
                    resolve(response);
                })
                .catch(e => {
                    if(e.response.data) {
                        if(e.response.data.message) {
                            if(e.response.data.message.name) {
                                $toast.error(e.response.data.message.name[0], config.toast);
                            }
                        }
                    }
                    reject(e.response);
                });
        });
    },

    creative_csv_import(context,payload) {
        const { id,csvData, $toast } = payload;
        return new Promise((resolve, reject) => {
            ApiService.put(`/api/facility/${id}/creative/import/csv`,{csvData})
            .then(response => {
                if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                }
                resolve(response);
            })
            .catch(e => {
                console.log("失敗")
                reject(e.response);
            });
        });
    },

    // fetch_list_facility_movie(context, payload) {
    //     let { page, sort_by, sort_type, type } = payload;
    //     if(!sort_by) sort_by = 'id';
    //     if(!sort_type) sort_type = 'desc';
    //     return new Promise((resolve, reject) => {
    //         ApiService.get(`/api/facility/list?page=${page}&sort=${sort_by}&order_by=${sort_type}&type=${type}`)
    //             .then(response => {
    //                 if(response.data.success) {
    //                     context.commit('SET_LIST_FACILITIES', response.data.data);
    //                     context.commit('SET_FACILITIES_META', response.data.meta);
    //                 }
    //                 resolve(response);
    //             })
    //             .catch(e => {
    //                 reject(e.response);
    //             });
    //     });
    // },


    update_professions(context, payload) {
        const { id, ng_profession_id, $toast } = payload;
        return new Promise((resolve, reject) => {
            ApiService.put(`/api/facility/${id}`, { ng_profession_id })
                .then(response => {
                    if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                    }
                    resolve(response);
                })
                .catch(e => {
                    if(e.response.data) {
                        if(e.response.data.message) {
                            $toast.error(e.response.data.message, config.toast);
                        }
                    }
                    reject(e.response);
                });
        });
    },

    update_status(context, payload) {
        const { id, status, $toast } = payload;
        return new Promise((resolve, reject) => {
            ApiService.put(`/api/facility/${id}/status`, { status })
                .then(response => {
                    if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                    }
                    resolve(response);
                })
                .catch(e => {
                    if(e.response.data) {
                        if(e.response.data.message) {
                            $toast.error(e.response.data.message, config.toast);
                        }
                    }
                    reject(e.response);
                });
        });
    },

    update_enable(context, payload) {
        const { id, campaign_uuid, enable, $toast } = payload;
        return new Promise((resolve, reject) => {
            ApiService.put(`/api/facility/${id}/${campaign_uuid}/enable`, { enable })
                .then(response => {
                    if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                        // $router.go(-1);
                    }
                    resolve(response);
                })
                .catch(e => {
                    if(e.response.data) {
                        if(e.response.data.message) {
                            $toast.error(e.response.data.message, config.toast);
                        }
                    }
                    reject(e.response);
                });
        });
    },

    fetch_desired_campaigns(context, payload) {
        const { id, confirm_status } = payload;
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/facility/desired_campaigns/${id}?status=${confirm_status}`)
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_DESIRED_CAMPAIGNS', response.data.data);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },

    fetch_campaign_by_uuid(context, payload) {
        const { uuid } = payload;
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/facility/campaign/${uuid}`)
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_FACILITY_CAMPAIGN', response.data.data);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },

    update_confirm_status(context, payload) {
        const { facility_id, uuid, confirm_status, $toast } = payload;
        return new Promise((resolve, reject) => {
            ApiService.put(`/api/facility/confirm_status/${facility_id}/${uuid}`, { confirm_status })
                .then(response => {
                    if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                    }
                    resolve(response);
                })
                .catch(e => {
                    console.log(e);
                    if(e.response.data) {
                        if(e.response.data.message) {
                            $toast.error(e.response.data.message, config.toast);
                        }
                    }
                    reject(e.response);
                });
        });
    },

    empty(context) {
        context.commit('SET_LIST_FACILITIES', []);
        context.commit('SET_FACILITIES_META', {});
    }
}
