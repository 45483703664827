import state from './moduleFacilityState.js'
import mutations from './moduleFacilityMutations.js'
import actions from './moduleFacilityActions.js'
import getters from './moduleFacilityGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}