export default {
    SET_CREATIVES_META(state, data) {
        state.creatives_meta = data;
    },
    SET_LIST_CREATIVES(state, data) {
        state.list_creatives = data;
    },
    SET_CREATIVES_META_FROM_OK_CAMPAIGN(state, data) {
        state.creatives_meta_from_ok_campaign = data;
    },
    SET_LIST_CREATIVES_FROM_OK_CAMPAIGN(state, data) {
        state.list_creatives_from_ok_campaign = data;
    },
    SET_CREATIVE(state, data) {
        state.creative = data;
    },
}