import Vue from 'vue'
import App from './App.vue'
import router from '@/Routes'
import store from '@/store/index'
import vuetify from '@/plugins/vuetify'
import ApiService from '@/services/api.service';
import * as VueGoogleMaps from 'vue2-google-maps';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Toast);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg',
  },
});

// API service init
ApiService.init();

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App), store
}).$mount('#app')
