<template>
  <v-container fluid>
    <Subheader :breadcrumbs="breadcrumbs" />
    <div class="tables-basic">
      <h4 class="page-title mt-10 mb-6"></h4>
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-form ref="form">
              <v-container>
                <v-row class="flex-column" align="center" justify="center">
                  <v-col>
                    <p
                      class="
                        login-slogan
                        display-1
                        text-center
                        font-weight-medium
                        mt-6
                        mb-10
                      "
                    >
                      媒体施設 新規追加
                    </p>
                  </v-col>
                  <v-col cols="6" class="mb-10">
                    <v-text-field
                      v-model="name"
                      :rules="[v => !!v || '必須項目のため入力してください']"
                      label="施設名*"
                      required
                    ></v-text-field>

                    <!-- zip_code -->
                    <v-text-field
                      v-model="zip_code"
                      type="number"
                      :rules="[v => !!v || '必須項目のため入力してください']"
                      @keypress="validate_number"
                      label="郵便番号*"
                      required
                    ></v-text-field>

                    <!-- location -->
                    <v-select
                        v-model="location_id"
                        :items="location_options"
                        :rules="[v => !!v || '必須項目のため入力してください']"
                        item-text="name"
                        item-value="id"
                        label="都道府県*"
                        no-data-text="データがありません"
                        required
                    ></v-select>

                    <!-- district -->
                    <v-select
                        v-model="district_id"
                        :items="district_options"
                        :rules="[v => !!v || '必須項目のため入力してください']"
                        item-text="name"
                        item-value="id"
                        label="市区町村*"
                        no-data-text="データがありません"
                        :disabled="!location_id"
                        required
                    ></v-select>

                    <!-- house_number -->
                    <v-text-field
                      v-model="house_number"
                      label="番地"
                    ></v-text-field>

                    <!-- building_name -->
                    <v-text-field
                      v-model="building_name"
                      label="建物名"
                    ></v-text-field>

                    <!-- facility type -->
                    <v-select
                        v-model="type"
                        :items="facility_type_options"
                        :rules="[v => !!v || '必須項目のため入力してください']"
                        item-text="text"
                        item-value="value"
                        label="施設区分（カテゴリ）*"
                        required
                    ></v-select>

                    <!-- business_hours -->
                      <v-row>
                        <v-col>
                          <v-select
                            v-model="start_business_hours"
                            :items="business_hours"
                            :rules="[v => !!v || '必須項目のため入力してください']"
                            item-text="text"
                            item-value="value"
                            label="営業開始時間*"
                            required
                        ></v-select>
                        </v-col>
                        <v-col>
                          <v-select
                          v-model="end_business_hours"
                          :items="business_hours"
                          :rules="[v => !!v || '必須項目のため入力してください']"
                          item-text="text"
                          item-value="value"
                          label="営業終了時間*"
                          required
                      ></v-select>
                        </v-col>
                      </v-row>

                    <!-- working_hours -->
                    <v-text-field
                      v-model="working_hours"
                      type="number"
                      :rules="[v => !!v || '必須項目のため入力してください']"
                      @keypress="validate_number"
                      label="週稼働時間*"
                      required
                    ></v-text-field>

                    <!-- phone_number -->
                    <v-text-field
                      v-model="phone_number"
                      type="number"
                      :rules="[v => !!v || '必須項目のため入力してください']"
                      @keypress="validate_phone_number"
                      label="電話番号*"
                      required
                    ></v-text-field>

                    <!-- email -->
                    <v-text-field
                      v-model="email"
                      type="email"
                      :rules="[v => !!v || '必須項目のため入力してください', v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'メールは有効である必要があります']"
                      label="メールアドレス*"
                      required
                    ></v-text-field>

                    <!-- person_in_charge -->
                    <v-text-field
                      v-model="person_in_charge"
                      label="担当者名"
                    ></v-text-field>

                    <!-- bank -->
                    <v-select
                        v-model="bank"
                        :items="bank_select_options"
                        :rules="[v => !!v || '必須項目のため入力してください']"
                        item-text="text"
                        item-value="value"
                        label="銀行名*"
                        required
                    ></v-select>

                    <!-- branch_bank -->
                    <v-select
                        v-model="branch_bank"
                        :items="branch_select_options"
                        :rules="[v => !!v || '必須項目のため入力してください']"
                        item-text="text"
                        item-value="value"
                        label="支店名*"
                        :disabled="!bank"
                        required
                    ></v-select>

                    <!-- currency -->
                    <v-select
                        v-model="currency"
                        :items="currencies_options"
                        :rules="[v => !!v || '必須項目のため入力してください']"
                        item-text="text"
                        item-value="value"
                        label="預金種目*"
                        required
                    ></v-select>

                    <!-- account_number -->
                    <v-text-field
                      v-model="account_number"
                      type="number"
                      :rules="[v => !!v || '必須項目のため入力してください']"
                      @keypress="validate_account_number"
                      label="口座番号*"
                      required
                    ></v-text-field>
                    
                    <!-- min_age -->
                    <!-- <v-text-field
                      v-model="min_age"
                      type="number"
                      :rules="min_age_validation"
                      @keypress="validate_number"
                      @input="validate_min_max_age"
                      label="最小年代*"
                      required
                    ></v-text-field> -->

                    <!-- max_age -->
                    <!-- <v-text-field
                      v-model="max_age"
                      type="number"
                      :rules="max_age_validation"
                      @keypress="validate_number"
                      @input="validate_min_max_age"
                      label="最大年代*"
                      required
                    ></v-text-field> -->
                    <!-- target_age -->
                    <v-select
                        v-model="target_age"
                        :items="target_age_options"
                        label="年代*"
                        :rules="[v => !!v || '必須項目のため入力してください']"
                        no-data-text="データがありません"
                    ></v-select>

                    <!-- revenue_share_percent -->
                    <v-text-field
                      v-model="revenue_share_percent"
                      type="number"
                      :rules="[v => !!v || '必須項目のため入力してください']"
                      @keypress="validate_number"
                      label="レベニューシェア率*"
                      required
                    ></v-text-field>

                    <!-- ng_category -->
                    <v-select
                        v-model="ng_profession_id"
                        :items="ng_category_options"
                        item-text="name"
                        item-value="id"
                        label="NGカテゴリ"
                        no-data-text="データがありません"
                        multiple
                    ></v-select>
                    <v-select
                        v-model="audio"
                        :items="audio_options"
                        :rules="[v => !!v || '必須項目のため入力してください']"
                        item-text="text"
                        item-value="value"
                        label="音声の有無*"
                        required
                    ></v-select>
                    <v-btn
                      class="text-capitalize mt-6"
                      color="primary"
                      width="100%"
                      @click="submit"
                    >
                      登録する</v-btn
                    >

                    <v-btn
                      class="text-capitalize mt-6"
                      color="primary"
                      width="100%"
                    >
                      施設一覧に戻る</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import Subheader from '@/components/Subheader/Subheader'
import { bank_options, branch_options } from '@/constants/bank'

export default {
  name: "CreateFacility",
  components: {
      Subheader
  },
  data() {
    return {
      breadcrumbs: [
            {
                text: '媒体施設一覧',
                disabled: false,
                underline: true,
                href: '/facilities?page=1',
            },
            {
                text: '媒体施設登録',
                disabled: true,
                underline: false,
                href: '#',
            },
        ],

      name: "",
      type: "",
      location_id: null,
      district_id: null,
      zip_code: null,
      house_number: "",
      building_name: "",
      working_hours: null,
      phone_number: null,
      email: "",
      person_in_charge: "",
      bank: "",
      branch_bank: "",
      currency: null,
      start_business_hours: "",
      end_business_hours: "",
      account_number: null,
      // min_age: null,
      // max_age: null,
      target_age: "",
      revenue_share_percent: null,
      ng_profession_id: [],
      facility_type_options: [
        // { text: '飲食店', value: 1 },
        // { text: 'オフィスビル', value: 2 },
        // { text: '商業施設', value: 3 },
        { text: '商業', value: 1 },
        { text: 'オフィス', value: 2 },
        { text: 'コンビニ', value: 3 },
        { text: 'カフェ', value: 4 },
        { text: 'パチンコ', value: 5 },
        { text: '飲食', value: 6 },
      ],
      currencies_options: [
        { text: '普通', value: '普通' },
        { text: '当座', value: '当座' },
      ],
      district_options: [],
      branch_select_options: [],
      audio:"",
      audio_options: [
         {text:'有り',value: 1},
         {text:'無し',value: 2},
      ],

      min_age_validation: [v => !!v || '必須項目のため入力してください'],
      max_age_validation: [v => !!v || '必須項目のため入力してください'],
    };
  },
  watch: {
    location_id(val) {
      const list_locations = this.$store.getters['campaign/get_campaign_address'];
      for(var i = 0; i < list_locations.length; i++) {
        if(list_locations[i].id == val) {
          this.district_options = list_locations[i].districts;
          break;
        }
      }
    },
    bank(val) {
      var bank_id = null;
      for(var i = 0; i < bank_options.length; i++) {
        if(bank_options[i].value == val) {
          bank_id = bank_options[i].id;
          break;
        }
      }
      if(bank_id) {
        for(var j = 0; j < branch_options.length; j++) {
          if(branch_options[j].parent_id == bank_id) {
            this.branch_select_options = branch_options[j].list;
            break;
          }
        }
      } else {
        this.branch_select_options = [];
      }
    }
  },
  computed: {
      ng_category_options() {
          return this.$store.getters['client/get_list_professions'];
      },
      location_options() {
        let locations = this.$store.getters['campaign/get_campaign_address'];
        locations = locations.filter(e => e.districts && e.districts.length > 0);
        return locations;
      },
      bank_select_options() {
        return bank_options;
      },
      business_hours() {
        var business_hours = [];
        for (let index = 7; index < 24; index++) {
          business_hours.push(index+":"+"00",index+":"+"30");
        }
        return business_hours;
      },
      target_age_options() {
        return ["10代","20代","30代","40代","50代","60代","70代","80代",]
      }
  },
  methods: {
    fetch_list_professions() {
      this.$store.dispatch("client/fetch_list_professions");
    },
    fetch_address () {
      this.$store.dispatch("campaign/fetch_address");
    },
    validate_number: function(evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!/^[0-9]*$/.test(expect) || expect.length >= 10) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validate_min_max_age: function() {
      const valid = parseInt(this.min_age) < parseInt(this.max_age) || this.min_age === null || this.max_age === null;
      console.log('valid', valid, this.min_age, this.max_age)
      if(valid) {
        this.min_age_validation = [v => !!v || '必須項目のため入力してください'];
        this.max_age_validation = [v => !!v || '必須項目のため入力してください'];
      } else {
        this.min_age_validation = [v => !!v || '必須項目のため入力してください', '最小年代を最大年代より小さく入力してください'];
        this.max_age_validation = [v => !!v || '必須項目のため入力してください', '最大年代を 最小年代より大きく入力してください'];
      }
    },
    validate_phone_number: function(evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!/^[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validate_account_number: function(evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!/^[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    submit() {
        if(this.$refs.form.validate()) {
            this.$store.dispatch("facility/create_facility", 
            {
              name: this.name,
              type: this.type,
              location_id: this.location_id,
              district_id: this.district_id,
              zip_code: this.zip_code,
              house_number: this.house_number,
              building_name: this.building_name,
              working_hours: this.working_hours,
              phone_number: this.phone_number,
              email: this.email,
              person_in_charge: this.person_in_charge,
              bank: this.bank,
              branch_bank: this.branch_bank,
              currency: this.currency,
              start_business_hours: this.start_business_hours,
              end_business_hours: this.end_business_hours,
              account_number: this.account_number,
              // min_age: this.min_age,
              // max_age: this.max_age,
              target_age: this.target_age,
              revenue_share_percent: this.revenue_share_percent,
              ng_profession_id: this.ng_profession_id,
              audio: this.audio,
              $toast: this.$toast,
              $router: this.$router
            }
          );
        }
    }
  },
  created() {
      this.fetch_list_professions();
      this.fetch_address();
  }
};
</script>

