<template>
  <v-container fluid>
      <loading :active.sync="isLoading" :is-full-page="true" color="#8089ff" loader="bars"></loading>
      <Subheader :breadcrumbs="breadcrumbs" :backLink="backLink" />
      <DLModal v-model="showDLModal" :url="downloadURL" :title="titleDL" :subtitle="subtitleDL" />
      <ConfirmShutdown v-model="showConfirmShutdown" @stop="stopState" :stopItem="stopItem"/>
    <div class="tables-basic">
      <v-row>
        
        

        <v-dialog
        v-model="video_dialog"
        persistent
        max-width="600px"
        >
            <v-card>
                <v-card-title>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <v-row>
                        <iframe width="600" height="400" frameBorder="0" :src="embed_link"></iframe>
                    </v-row>
                </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close_video_modal"
                >
                    閉じる
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>




        <v-col cols="12">
            <v-tabs grow>
                <v-tabs-slider></v-tabs-slider>
                <v-tab :href="`#manager`">クリエイティブ一覧</v-tab>
                <!-- <v-tab :href="`#suggest`">提案機能</v-tab>
                <v-tab :href="`#report`">レポーティング</v-tab> -->

                <v-tab-item :value="'manager'" >
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                        <router-link :to="`/creatives/create?client_id=${client_id}&campaign_uuid=${campaign_uuid}&campaign_id=${campaign_id}`"><v-btn color="primary"><v-icon>mdi-plus</v-icon>クリエイティブ登録する</v-btn></router-link>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="clickDLCampaignReportById">全体レポートDL</v-btn>
                        </v-card-title>

                        <div class="pa-6 pb-3">表示期間 <br>{{ formatDate(campaign_info.release_start_at) }} ~ {{ formatDate(campaign_info.release_end_at) }}</div>

                        <v-data-table
                        :headers="headers"
                        :items="list_creatives"
                        :loading="loading"
                        loading-text="読み込み中"
                        :options.sync="options"
                        @pagination="update_page"
                        :server-items-length="total"
                        :items-per-page="items_per_page"
                        :page="page"
                        :footer-props="{
                            'disable-items-per-page': true,
                            'items-per-page-options': [ 50, 100 ],
                            'items-per-page-text':'ページごとに表示する件数'
                        }"
                        item-key="id"
                        >
                            <!-- <template v-for="h in headers"  v-slot:[`header.${h.value}`]="{ }">
                                <span :key="h.text">
                                    <span v-if="!h.text">表示期間 <br>{{ formatDate(campaign_info.release_start_at) }} ~ {{ formatDate(campaign_info.release_end_at) }}</span>
                                    <span v-else>{{ h.text === 'edit' ? '' : h.text }}</span>
                                </span>
                            </template> -->
                            <template v-slot:[`item.state`]="{ item }">
                                <v-switch
                                    v-model="item.enable"
                                    @change="changeState(item)"
                                ></v-switch>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                {{ get_status_text(item.status) }}
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                <div>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <a @click="show_video(item.id)" style="display:flex;align-items:center;">
                                                <v-img :src="item.urlThumbnail" max-width="100" max-height="200"></v-img>
                                                <span  class="ml-2 text-truncate" style="max-width: 150px;" v-bind="attrs" v-on="on">{{ item.name }}</span>
                                            </a>
                                        </template>
                                        <span>{{ item.name }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.edit`]="{ item }">
                                <div class="d-flex gap-10">
                                    <router-link :to="`/creatives/edit/${item.id}`" class="v-btn v-size--small primary v-btn--is-elevated">編集</router-link>
                                    <v-btn @click="download_row(item)" small>データDL</v-btn>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-menu :key="item.id" offset-y open-on-hover>
                                    <template v-slot:activator="{ attrs, on }">
                                        <span class="text-actions" v-bind="attrs" v-on="on">アクション</span>
                                    </template>

                                    <v-list>
                                        <v-list-item v-for="act in [{text: 'クリエイティブ詳細', link: ``, is_detail: true}, {text: 'クリエイティブ編集', link: `/creatives/edit/${item.id}`}]" :key="act.text" link :to="act.link">
                                            <v-list-item-title v-if="!act.is_detail">
                                                {{ act.text }}
                                            </v-list-item-title>
                                            <v-list-item-title v-else @click="show_video(item.id)">
                                                <span class="action-item">{{ act.text }}</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                            <template v-slot:[`item.avg_img`]="{ item }">
                                <span>{{ Math.round(parseFloat(item.avg_img) * 100 * 100) / 100 }}%</span>
                            </template>
                            <template v-slot:[`item.imp_month`]="{ item }">
                                <span>{{ Math.round(parseFloat(item.imp_month) * 100 * 100) / 100 }}%</span>
                            </template>
                            <template v-slot:no-data>
                                データがありません
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import Subheader from '@/components/Subheader/Subheader'
import Loading from 'vue-loading-overlay';
import config from "@/config";
import DLModal from '@/components/DLModal'
import ConfirmShutdown from '@/components/Popup/ConfirmShutdown'

export default {
  name: "ListCreative",
  components: {
      Subheader,
      Loading,
      DLModal,
      ConfirmShutdown
  },
  data() {
    return {
        sampleSwitch: false,
        headers: [
            { text: "クリエイティブ id", align: "id", value: "id",width: "150px", sortable: true },
            { text: "クリエイティブ名", align: "start", value: "name", width: "250px", sortable: true },
            { text: "ステータス", value: "status", sortable: true },
            { text: "imp数", value: "sum_imp_this_month", sortable: true },
            // { text: "imp消化率（絶対数）", value: "avg_img", sortable: true },
            // { text: "imp消化率（対日付）", value: "imp_month", sortable: true },
            { text: "緊急停止ボタン", value: "state", sortable: false },
            // { text: "edit", value: "edit", sortable: false },
            { text: "アクション", value: "actions", width: "120px", sortable: false },
        ],
        loading: false,
        isLoading: false,
        video_dialog: false,
        embed_link: "",

        downloadURL: '',
        showDLModal: false,
        titleDL: '',
        subtitleDL: '',

        options: {},
        showConfirmShutdown:false,
        stopItem:{}
    };
  },
  computed: {
      client_id() {
          return this.$route.query.client_id;
      },
      client_name() {
          const client_info = this.$store.getters['client/get_client'];
          return client_info ? client_info.name : '';
      },
      campaign_id() {
          return this.$route.query.campaign_id;
      },
      campaign_uuid() {
          return this.$route.query.campaign_uuid;
      },
      campaign_name() {
          const campaign_info = this.$store.getters['campaign/get_campaign'];
          return campaign_info ? campaign_info.name : '';
      },
      campaign_info() {
          return this.$store.getters['campaign/get_campaign'];
      },
      breadcrumbs() {
          return [
            {
                text: '広告登録・数値管理',
                disabled: true,
                href: '#',
            },
            {
                text: this.client_name,
                disabled: false,
                underline: false,
                href: `/clients?page=1`,
            },
            {
                text: this.campaign_name,
                disabled: false,
                underline: false,
                href: `/campaigns?page=1&client_id=${this.client_id}`,
            },
            {
                text: 'クリエイティブ一覧',
                disabled: false,
                underline: true,
                href: `/creatives?page=1&client_id=${this.client_id}&campaign_id=${this.campaign_id}&campaign_uuid=${this.campaign_uuid}`,
            },
          ]
      },
      backLink() {
          return `/campaigns?page=1&client_id=${this.client_id}`;
      },
      list_creatives() {
          return this.$store.getters['creative/get_list_creatives'];
      },
      page() {
          if(this.$route.query.page) {
              return parseInt(this.$route.query.page);
          }
          const meta = this.$store.getters['creative/get_creatives_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.current_page) {
                      return meta.pagination.current_page;
                  }
              }
          }
          return 1;
      },
      total() {
          const meta = this.$store.getters['creative/get_creatives_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.total) {
                      return meta.pagination.total;
                  }
              }
          }
          return 0;
      },
      items_per_page() {
          const meta = this.$store.getters['creative/get_creative_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.per_page) {
                      return meta.pagination.per_page;
                  }
              }
          }
          return 0;
      }
  },
  watch: {
      options: {
        handler () {
          this.fetch_list_creatives()
        },
        deep: true,
      },
  },
  methods: {
    fetch_list_creatives() {
        const self = this;
        self.loading = true;
        const page = this.$route.query.page || 1;
        // if(!page) return;
        const payload = { page, client_id: this.client_id, campaign_uuid: this.campaign_uuid };
        
        const { sortBy, sortDesc } = this.options
        payload.sort_by = sortBy[0] ? sortBy[0] : 'id';
        payload.sort_type = sortDesc[0] ? 'desc' : 'asc';

        this.$router.push({
            name: "ListCreative",
            query: { page, client_id: this.client_id, campaign_id: this.campaign_id, campaign_uuid: this.campaign_uuid }
        }).catch(() => { });

        this.$store.dispatch("creative/fetch_list_creatives", payload).then(() => {
            self.loading = false;
        }).catch(() => {
            self.loading = false;
        });
    },
    changeState(item) {
        const self = this;
        self.loading = true;
        if(item.enable) {
            this.$store.dispatch("creative/update_enable", { id: item.id, $toast: this.$toast }).then(() => {
                self.loading = false;
                self.fetch_list_clients();
            }).catch(() => {
                self.loading = false;
            });
            item.enable = true;
        } else {
            this.stopItem = item;
            this.show_confirmShutdown();
            self.loading = false;
        }
    },
    stopState(data) {
        if(data.confirm) {
            this.$store.dispatch("creative/update_enable", { id: data.item.id, $toast: this.$toast }).then(() => {
            self.loading = false;
            self.fetch_list_clients();
            }).catch(() => {
                self.loading = false;
            });
            data.item.enable = false;
        } else {
            this.showConfirmShutdown = false;
            data.item.enable = true;
        }
        this.showConfirmShutdown = false;
    },
    show_confirmShutdown() {
        this.showConfirmShutdown = true;
    },
    update_page(pagination) {
        const page  = pagination.page;

        this.$router.push({
            name: "ListCreative",
            query: { page, client_id: this.client_id, campaign_id: this.campaign_id, campaign_uuid: this.campaign_uuid }
        }).catch(() => { });
        this.fetch_list_creatives();
    },
    get_status_text(status) {
        switch(status) {
            case 1:
                return "配信中";
            case 2:
                return "掲載終了（期間）";
            case 3:
                return "強制停止中";
            case 4:
                return "未配信";
            default:
                return '';
        }
    },
    show_video(id) {
        const self = this;
        self.isLoading = true;
        self.video_dialog = "";
        this.$store.dispatch("creative/fetch_s3_link", { creative_id: id }).then((res) => {
            if(res.data) {
                if(res.data.data.TemporaryFileVideo) {
                    self.embed_link = res.data.data.TemporaryFileVideo;
                    self.video_dialog = true;
                } else {
                    this.$toast.error("ビデオ情報を取得できません", config.toast);
                }
            } else {
                this.$toast.error("ビデオ情報を取得できません", config.toast);
            }
            self.isLoading = false;
        }).catch(() => {
            this.$toast.error("ビデオ情報を取得できません", config.toast);
            self.isLoading = false;
        });
    },
    download() {
        this.$store.dispatch("download/csv", { url: `/api/creative/download/csv/${this.client_id}/${this.campaign_uuid}`}).then((res) => {
            const contentDisposition = res.headers['content-disposition'];
            let fileName = 'unknown';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }
            const download_url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = download_url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }).catch((e) => {
            console.log(e)
        });
    },
    formatDate(date) {
        if(!date) return '';
        return `${new Date(date).getFullYear()}/${new Date(date).getMonth() < 9 ? '0' + (new Date(date).getMonth() + 1) : (new Date(date).getMonth() + 1)}/${new Date(date).getDate() < 10 ? '0' + new Date(date).getDate() : new Date(date).getDate()}`;
    },
    close_video_modal() {
        this.video_dialog = false;
        this.embed_link = false;
    },
    download_row(item) {
        this.$store.dispatch("download/csv", { url: `/api/creative/download/csv/${this.client_id}/${this.campaign_uuid}/${item.id}`}).then((res) => {
            const contentDisposition = res.headers['content-disposition'];
            let fileName = 'unknown';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }
            const download_url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = download_url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }).catch((e) => {
            console.log(e)
        });
    },
    clickDLCampaignReportById() {
        this.downloadURL = `/api/campaign/download/csv/${this.client_id}/${this.campaign_uuid}`;
        this.showDLModal = true;
        this.titleDL = this.campaign_name;
        this.subtitleDL = 'レポートDL';
    }
  },
  created() {
    this.$store.dispatch("creative/empty")
    this.$store.dispatch("client/fetch_client_by_id", { id: this.client_id })
    this.$store.dispatch("campaign/fetch_campaign_by_id", { id: this.campaign_id })
  }
};
</script>

<style src="./Creative.scss" lang="scss"></style>
