export default {
    get_list_creatives(state) {
        return state.list_creatives;
    },
    get_list_creatives_from_ok_campaign(state) {
        return state.list_creatives_from_ok_campaign;
    },
    get_creative(state) {
        return state.creative;
    },
    get_creatives_meta(state) {
        return state.creatives_meta;
    },
    get_creatives_meta_from_ok_campaign(state) {
        return state.creatives_meta_from_ok_campaign;
    },
}
