import ApiService from "@/services/api.service";
import config from "@/config";

export default {

    fetch_list_sex(context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/api/campaign/getMaster/sex")
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_LIST_SEX', response.data.data ? response.data.data.sex : []);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },

    fetch_list_toilets(context, payload) {
        let { sort_by, sort_type, name, address, type, sex } = payload;
        if(!sort_by) sort_by = 'id';
        if(!sort_type) sort_type = 'desc';
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/toilet/list?sort=${sort_by}&order_by=${sort_type}&name=${name}&address=${address}&type=${type}&sex=${sex}`)
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_LIST_TOILETS', response.data.data);
                        context.commit('SET_TOILETS_META', response.data.meta);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },

    fetch_list_toilet_by_facility_id(context, payload) {
        let { facility_id, sort_by, sort_type } = payload;
        if(!sort_by) sort_by = 'id';
        if(!sort_type) sort_type = 'desc';
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/toilet/list/${facility_id}?sort=${sort_by}&order_by=${sort_type}`)
                .then(response => {
                    if(response.data.success) {
                        context.commit('SET_LIST_TOILET_BY_FACILITY_ID', response.data.data);
                        context.commit('SET_TOILET_BY_FACILITY_ID_META', response.data.meta);
                    }
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
    
    create_toilet(context, payload) {
        const { toilet_id, gateway_id, address, sex, number_floor, facility_id, $toast } = payload;
        return new Promise((resolve, reject) => {
            ApiService.post("/api/toilet", { toilet_id, gateway_id, address, sex, number_floor, facility_id, })
                .then(response => {
                    resolve(response);
                })
                .catch(e => {
                    if(e.response.data) {
                        if(e.response.data.message) {
                            if(e.response.data.message.name) {
                                $toast.error(e.response.data.message.name[0], config.toast);
                            }
                        }
                    }
                    reject(e.response);
                });
        });
    },

    update_toilet(context, payload) {
        const { id, toilet_id, gateway_id, address, sex, number_floor, facility_id, $toast } = payload;
        return new Promise((resolve, reject) => {
            ApiService.put(`/api/toilet/${id}`, { toilet_id, gateway_id, address, sex, number_floor, facility_id })
                .then(response => {
                    resolve(response);
                })
                .catch(e => {
                    if(e.response.data) {
                        if(e.response.data.message) {
                            if(e.response.data.message.name) {
                                $toast.error(e.response.data.message.name[0], config.toast);
                            }
                        }
                    }
                    reject(e.response);
                });
        });
    },

    delete_toilet(context, payload) {
        const { id, $toast } = payload;
        return new Promise((resolve, reject) => {
            ApiService.delete(`/api/toilet/${id}`)
                .then(response => {
                    if(response.data.success) {
                        $toast.clear();
                        $toast.success("正常に処理が完了しました!", config.toast);
                    }
                    resolve(response);
                })
                .catch(e => {
                    if(e.response.data) {
                        if(e.response.data.message) {
                            if(e.response.data.message.name) {
                                $toast.error(e.response.data.message.name[0], config.toast);
                            }
                        }
                    }
                    reject(e.response);
                });
        });
    },

    empty(context) {
        context.commit('SET_LIST_TOILETS', []);
        context.commit('SET_TOILETS_META', {});
    }
}
