export default {
    SET_CAMPAIGN_MASTER(state, data) {
        state.campaign_master = data;
    },
    SET_CAMPAIGN_ADDRESS(state, data) {
        state.campaign_address = data;
    },
    SET_CAMPAIGN_MASTER_BRANCH(state, data) {
        state.campaign_master_branch = data;
    },
    SET_CAMPAIGNS_META(state, data) {
        state.campaigns_meta = data;
    },
    SET_LIST_CAMPAIGNS(state, data) {
        state.list_campaigns = data;
    },
    SET_CAMPAIGN(state, data) {
        state.campaign = data;
    },
}