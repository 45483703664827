export default {
    get_list_professions(state) {
        return state.list_professions;
    },
    get_list_clients(state) {
        return state.list_clients;
    },
    get_client(state) {
        return state.client;
    },
    get_clients_meta(state) {
        return state.clients_meta;
    },
}
