export default {
    get_list_facilities(state) {
        return state.list_facilities;
    },
    get_facilities_meta(state) {
        return state.facilities_meta;
    },
    get_list_facilities_by_campaign(state) {
        return state.list_facilities_by_campaign;
    },
    get_facilities_meta_by_campaign(state) {
        return state.facilities_meta_by_campaign;
    },
    get_facility(state) {
        return state.facility;
    },
    get_desired_campaigns(state) {
        return state.desired_campaigns;
    },
    get_facility_campaign(state) {
        return state.facility_campaign;
    }
}
