import ApiService from "@/services/api.service";

export default {

    to_s3(context, payload) {
        const { url, data, content_type } = payload;
        return new Promise((resolve, reject) => {
            ApiService.put_to_s3(url, data, content_type)
                .then(response => {
                    resolve(response);
                })
                .catch(e => {
                    reject(e.response);
                });
        });
    },
}
