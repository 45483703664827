export default {
    SET_LIST_PROFESSIONS(state, data) {
        state.list_professions = data;
    },
    SET_CLIENTS_META(state, data) {
        state.clients_meta = data;
    },
    SET_LIST_CLIENTS(state, data) {
        state.list_clients = data;
    },
    SET_CLIENT(state, data) {
        state.client = data;
    },
}