<template>
  <v-container fluid>
      <Subheader :breadcrumbs="breadcrumbs" :disable-back="true" />
      <DLModal v-model="showDLModal" :url="downloadURL" :title="titleDL" :subtitle="subtitleDL" />
      <ConfirmShutdown v-model="showConfirmShutdown" @stop="stopState" :stopItem="stopItem"/>
    <div class="tables-basic">
      <v-row>
        <v-col cols="12">
            <v-tabs grow>
                <v-tabs-slider></v-tabs-slider>
                <v-tab :href="`#manager`">広告主一覧</v-tab>
                <!-- <v-tab :href="`#suggest`">提案機能</v-tab>
                <v-tab :href="`#report`">レポーティング</v-tab> -->

                <v-tab-item :value="'manager'" >
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <router-link to="/clients/create"><v-btn color="primary"><v-icon>mdi-plus</v-icon>広告主を登録する</v-btn></router-link>
                            <v-spacer></v-spacer>
                            <v-btn @click="clickDLTotalClientReport" color="primary">全体レポートDL</v-btn>
                        </v-card-title>
                        <v-data-table
                        :headers="headers"
                        :items="list_clients"
                        :loading="loading"
                        loading-text="読み込み中"
                        :options.sync="options"
                        @pagination="update_page"
                        :server-items-length="total"
                        :items-per-page="items_per_page"
                        :page="page"
                        :footer-props="{
                            'disable-items-per-page': true,
                            'items-per-page-options': [ 50, 100 ],
                            'items-per-page-text':'ページごとに表示する件数'
                        }"
                        item-key="id"
                        >
                            <template v-slot:[`item.state`]="{ item }">
                                <v-switch
                                    v-model="item.enable"
                                    @change="changeState(item)"
                                ></v-switch>
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                <div class="text-truncate" style="max-width: 200px;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                                        </template>
                                        <span>{{ item.name }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                {{ get_status_text(item.status) }}
                            </template>
                            <!-- <template v-slot:[`item.edit`]="{ item }">
                                <div class="d-flex gap-10">
                                    <router-link :to="`/clients/edit/${item.id}`" class="v-btn v-size--small primary v-btn--is-elevated">編集</router-link>
                                    <v-btn @click="download_row(item)" small>データDL</v-btn>
                                </div>
                            </template> -->
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-menu :key="item.id" offset-y open-on-hover>
                                    <template v-slot:activator="{ attrs, on }">
                                        <span class="text-actions" v-bind="attrs" v-on="on">アクション</span>
                                    </template>

                                    <v-list>
                                        <v-list-item v-for="act in [{text: '広告主編集', link: `/clients/edit/${item.id}`}, {text: 'キャンペーン一覧', link: `/campaigns?page=1&client_id=${item.id}`}, {text: '個別レポートDL', link: '', is_dl: true}]" :key="act.text" link :to="act.link">
                                            <v-list-item-title v-if="!act.is_dl">
                                                {{ act.text }}
                                            </v-list-item-title>
                                            <v-list-item-title v-else @click="clickDLClientReportById(item)">
                                                <span class="action-item">{{ act.text }}</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                            <template v-slot:[`item.avg_img`]="{ item }">
                                <span>{{ Math.round(parseFloat(item.avg_img) * 100 * 100) / 100 }}%</span>
                            </template>
                            <template v-slot:[`item.imp_month`]="{ item }">
                                <span>{{ Math.round(parseFloat(item.imp_month) * 100 * 100) / 100 }}%</span>
                            </template>
                            <template v-slot:no-data>
                                データがありません
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import Subheader from '@/components/Subheader/Subheader'
import DLModal from '@/components/DLModal'
import ConfirmShutdown from '@/components/Popup/ConfirmShutdown'

export default {
  name: "ListClient",
  components: {
      Subheader,
      DLModal,
      ConfirmShutdown
  },
  data() {
    return {
        sampleSwitch: false,
        headers: [
            { text: "広告主名", align: "start", value: "name", width: "200px", sortable: true },
            { text: "ステータス", value: "status", sortable: true },
            { text: "imp数", value: "sum_imp_this_month", sortable: true },
            { text: "imp消化率（絶対数）", value: "avg_img", sortable: true },
            { text: "imp消化率（対日付）", value: "imp_month", sortable: true },
            { text: "緊急停止ボタン", value: "state", sortable: false },
            { text: "アクション", value: "actions", width: "120px", sortable: false },
        ],
        breadcrumbs: [
            {
                text: '広告登録・数値管理',
                disabled: true,
                underline: false,
                href: '#',
            },
            {
                text: '広告主一覧',
                disabled: false,
                underline: true,
                href: '/clients?page=1',
            },
        ],
        options: {},
        loading: false,
        showDLModal: false,
        downloadURL: '',
        titleDL: '',
        subtitleDL: '',
        showConfirmShutdown:false,
        stopItem:{}
    };
  },
  computed: {
      list_clients() {
          return this.$store.getters['client/get_list_clients'];
      },
      page() {
          if(this.$route.query.page) {
              return parseInt(this.$route.query.page);
          }
          const meta = this.$store.getters['client/get_clients_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.current_page) {
                      return meta.pagination.current_page;
                  }
              }
          }
          return 1;
      },
      total() {
          const meta = this.$store.getters['client/get_clients_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.total) {
                      return meta.pagination.total;
                  }
              }
          }
          return 0;
      },
      items_per_page() {
          const meta = this.$store.getters['client/get_clients_meta'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.per_page) {
                      return meta.pagination.per_page;
                  }
              }
          }
          return 0;
      }
  },
  watch: {
      options: {
        handler () {
          this.fetch_list_clients()
        },
        deep: true,
      },
  },
  methods: {
    fetch_list_clients() {
        const self = this;
        self.loading = true;
        const page = this.$route.query.page || 1;
        // if(!page) return;
        const payload = { page };

        const { sortBy, sortDesc } = this.options
        payload.sort_by = sortBy[0] ? sortBy[0] : 'id';
        payload.sort_type = sortDesc[0] ? 'desc' : 'asc';

        this.$router.push({
            name: "ListClient",
            query: { page }
        }).catch(() => { });

        this.$store.dispatch("client/fetch_list_clients", payload).then(() => {
            self.loading = false;
        }).catch(() => {
            self.loading = false;
        });
    },
    changeState(item) {
        const self = this;
        self.loading = true;
        if(item.enable) {
            this.$store.dispatch("client/update_enable", { id: item.id, $toast: this.$toast }).then(() => {
                self.loading = false;
                self.fetch_list_clients();
            }).catch(() => {
                self.loading = false;
            });
            item.enable = true;
        } else {
            this.stopItem = item;
            this.show_confirmShutdown();
            self.loading = false;
        }
    },
    stopState(data) {
        if(data.confirm) {
            this.$store.dispatch("client/update_enable", { id: data.item.id, $toast: this.$toast }).then(() => {
            self.loading = false;
            self.fetch_list_clients();
            }).catch(() => {
                self.loading = false;
            });
            data.item.enable = false;
        } else {
            this.showConfirmShutdown = false;
            data.item.enable = true;
        }
        this.showConfirmShutdown = false;
    },
    show_confirmShutdown() {
        this.showConfirmShutdown = true;
    },
    update_page(pagination) {
        const page  = pagination.page;

        this.$router.push({
            name: "ListClient",
            query: { page }
        }).catch(() => { });
        this.fetch_list_clients();
        // window.location.href = `/clients/?page=${page}`
    },
    get_status_text(status) {
        switch(status) {
            case 1:
                return "配信中";
            case 2:
                return "掲載終了（期間）";
            case 3:
                return "強制停止中";
            case 4:
                return "未配信";
            default:
                return '';
        }
    },
    download() {
        this.$store.dispatch("download/csv", { url: '/api/client/download/csv'}).then((res) => {
            const contentDisposition = res.headers['content-disposition'];
            let fileName = 'unknown';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }
            const download_url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = download_url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }).catch((e) => {
            console.log(e)
        });
    },
    download_row(item) {
        this.$store.dispatch("download/csv", { url: `/api/client/download/csv/${item.id}`}).then((res) => {
            const contentDisposition = res.headers['content-disposition'];
            let fileName = 'unknown';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }
            const download_url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = download_url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }).catch((e) => {
            console.log(e)
        });
    },
    clickDLTotalClientReport() {
        this.downloadURL = '/api/client/download/csv';
        this.showDLModal = true;
        this.titleDL = 'クライアントレポートDL';
    },
    clickDLClientReportById(item) {
        this.downloadURL = `/api/client/download/csv/${item.id}`;
        this.showDLModal = true;
        this.titleDL = item.name;
        this.subtitleDL = '個別レポート　レポートDL';
    },
  },
  mounted() {
    //   const page = this.$route.query.page
    //   if(!page) {
    //         window.location.href = '/clients/?page=1';
    //   } else {
    //       this.fetch_list_clients();
    //   }
    // this.fetch_list_clients();
  },
  created() {
      this.$store.dispatch("client/empty");
  }
};
</script>

<style src="./Client.scss" lang="scss"></style>
