export default {
    light: {
      primary: "#536DFE",
      secondary: "#FF5C93",
      error: "#FF4081",
      warning: "#FFC260",
      success: "#3CD4A0",
      info: "#9013FE",
      textColor: "#B9B9B9",
      iconColor: "#FFFFFF59",
      grayBold: '#4a4a4a'
  },
  toast: {
    position: "top-right",
    timeout: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true
  },
  translateText: {
    "password is not correct!": "パスワードが正しくありません。",
    "email is not correct!": "メールが正しくありません。"
  }
};

