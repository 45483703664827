<template>
  <v-container fluid>
      <Subheader :breadcrumbs="breadcrumbs" :backLink="backLink" />
      <DLModal v-model="showDLModal" :url="downloadURL" :title="titleDL" :subtitle="subtitleDL" />
    <div class="tables-basic">
      <v-row>
        <v-col cols="12">
            <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <span>配信期間：{{ release_date_text }}</span>
                            <v-spacer></v-spacer>
                            <v-btn @click="clickDLTotalFacilityReport" color="primary">施設一覧成果DL</v-btn>
                        </v-card-title>
                        <v-data-table
                        :headers="headers"
                        :items="list_facilities"
                        :loading="loading"
                        loading-text="読み込み中"
                        :options.sync="options"
                        @pagination="update_page"
                        :server-items-length="total"
                        :items-per-page="items_per_page"
                        :page="page"
                        :footer-props="{
                            'disable-items-per-page': true,
                            'items-per-page-options': [ 50, 100 ],
                            'items-per-page-text':'ページごとに表示する件数'
                        }"
                        item-key="id"
                        >
                            <template v-slot:[`item.campaign_status`]="{ item }">
                                {{ get_status_text(item.campaign_status, item.enable) }}
                            </template>
                            <template v-slot:[`item.type_display`]="{ item }">
                                {{ item.type_display ? item.type_display.name : '' }}
                            </template>
                            <template v-slot:[`item.address`]="{ item }">
                                {{ item.locations ? item.locations.name : '' }} <br />
                                {{ item.districts ? item.districts.name : '' }}
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                <div class="text-truncate" style="max-width: 200px;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                                        </template>
                                        <span>{{ item.name }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.enable`]="{ item }">
                                <v-switch
                                    v-model="item.enable"
                                    @change="updateEnable(item)"
                                ></v-switch>
                            </template>
                            <!-- <template v-slot:[`item.edit`]="{ item }">
                                <div class="d-flex gap-10">
                                    <router-link :to="`/clients/edit/${item.id}`" class="v-btn v-size--small primary v-btn--is-elevated">編集</router-link>
                                    <v-btn @click="download_row(item)" small>データDL</v-btn>
                                </div>
                            </template> -->
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-menu :key="item.id" offset-y open-on-hover>
                                    <template v-slot:activator="{ attrs, on }">
                                        <span class="text-actions" v-bind="attrs" v-on="on">アクション</span>
                                    </template>

                                    <v-list>
                                        <v-list-item v-for="act in [{text: '個別レポートDL', link: '#', is_dl: true}]" :key="act.text" link>
                                            <v-list-item-title v-if="!act.is_dl">
                                                <router-link class="action-item" :to="act.link">{{ act.text }}</router-link>
                                            </v-list-item-title>
                                            <v-list-item-title v-else @click="clickDLFacilityByIdReport(item.id)">
                                                <span class="action-item">{{ act.text }}</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                            <template v-slot:no-data>
                                データがありません
                            </template>
                        </v-data-table>
                    </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import Subheader from '@/components/Subheader/Subheader'
import DLModal from '@/components/DLModal'

export default {
  name: "ListFacility",
  components: {
      Subheader,
      DLModal,
  },
  data() {
    return {
        sampleSwitch: false,
        headers: [
            { text: "施設名", align: "start", value: "name", width: "200px", sortable: true },
            { text: "住所", value: "address", sortable: true },
            { text: "施設区分", value: "type_display", sortable: true },
            { text: "ステータス", value: "campaign_status", sortable: true },
            { text: "imp数", value: "sum_imp", sortable: true },
            { text: "緊急停止ボタン", value: "enable", sortable: false },
            { text: "アクション", value: "actions", width: "120px", sortable: false },
        ],
        breadcrumbs: [
            {
                text: '配信先一覧',
                disabled: true,
                underline: false,
                href: '#',
            },
        ],
        options: {},
        loading: false,
        showDLModal: false,
        downloadURL: '',
        titleDL: '',
        subtitleDL: '',
    };
  },
  computed: {
      list_facilities() {
          return this.$store.getters['facility/get_list_facilities_by_campaign'];
      },
      page() {
          if(this.$route.query.page) {
              return parseInt(this.$route.query.page);
          }
          const meta = this.$store.getters['facility/get_facilities_meta_by_campaign'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.current_page) {
                      return meta.pagination.current_page;
                  }
              }
          }
          return 1;
      },
      total() {
          const meta = this.$store.getters['facility/get_facilities_meta_by_campaign'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.total) {
                      return meta.pagination.total;
                  }
              }
          }
          return 0;
      },
      items_per_page() {
          const meta = this.$store.getters['facility/get_facilities_meta_by_campaign'];
          if(meta) {
              if(meta.pagination) {
                  if(meta.pagination.per_page) {
                      return meta.pagination.per_page;
                  }
              }
          }
          return 0;
      },
      release_date_text() {
        var list = this.$store.getters['facility/get_list_facilities_by_campaign'];
        if(!list) return ''
        if(!list[0]) return ''
        return `${new Date(list[0].release_start_at).toLocaleDateString("ja-JP")} 〜 ${new Date().toLocaleDateString("ja-JP")}`
      },
      campaign() {
          return this.$store.getters['facility/get_facility_campaign'];
      },
      backLink() {
        return this.campaign ? `/campaigns?page=1&client_id=${this.campaign.client_id}` : ''
      }
  },
  watch: {
      options: {
        handler () {
          this.fetch_list_facilities()
        },
        deep: true,
      },
  },
  methods: {
    fetch_list_facilities() {
        const self = this;
        self.loading = true;
        const page = this.$route.query.page || 1;
        // if(!page) return;
        const payload = { page };

        const { sortBy, sortDesc } = this.options
        payload.sort_by = sortBy[0] ? sortBy[0] : 'id';
        payload.sort_type = sortDesc[0] ? 'desc' : 'asc';
        payload.campaign_uuid = this.$route.params.campaign_uuid;

        this.$router.push({
            name: "ListFacilityByCampaign",
            query: { page }
        }).catch(() => { });

        this.$store.dispatch("facility/fetch_list_facilities_by_campaign", payload).then(() => {
            self.loading = false;
        }).catch(() => {
            self.loading = false;
        });
    },
    update_page(pagination) {
        const page  = pagination.page;

        this.$router.push({
            name: "ListFacilityByCampaign",
            query: { page }
        }).catch(() => { });
        this.fetch_list_facilities();
    },
    get_status_text(status, enable) {
        if(!enable) {
            return '強制停止中';
        }
        switch(status) {
            case 1:
                return "配信中";
            case 2:
                return "掲載終了（期間）";
            case 3:
                return "強制停止中";
            case 4:
                return "未配信";
            default:
                return '';
        }
    },
    clickDLTotalFacilityReport() {
        this.downloadURL = `/api/facility/download_by_campaign_uuid/${this.$route.params.campaign_uuid}`;
        this.showDLModal = true;
        this.titleDL = this.campaign ? this.campaign.name : '';
        this.subtitleDL = '配信先レポートDL';
    },
    clickDLFacilityByIdReport(facility_id) {
        this.downloadURL = `/api/campaign/download_by_uuid/${facility_id}/${this.$route.params.campaign_uuid}`;
        this.showDLModal = true;
        this.titleDL = this.campaign ? this.campaign.name : '';
        this.subtitleDL = '個別レポート　レポートDL ';
    },
    updateEnable(item) {
        const self = this;
        self.loading = true;
        let enable = item.enable ? true : false;
        this.$store.dispatch("facility/update_enable", { id: item.id, campaign_uuid: this.$route.params.campaign_uuid, enable, $toast: this.$toast }).then(() => {
            self.loading = false;
            self.fetch_list_facilities();
        }).catch(() => {
            self.loading = false;
        });
    }
  },
  mounted() {
      if(this.$route.params.campaign_uuid) {
          this.$store.dispatch("facility/fetch_campaign_by_uuid", { uuid: this.$route.params.campaign_uuid })
      }
  },
  created() {
      this.$store.dispatch("facility/empty");
  }
};
</script>

