<template>
    <v-dialog v-model="show" persistent max-width="600px">
        <v-card>
            <v-card-title>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row class="px-6 py-6 d-flex" style="flex-direction:column;">
                        <div class="text-center">
                            <h3>本当に緊急停止して良いでしょうか？</h3>
                        </div>
                        <div class="mt-10 d-flex justify-center gap-20">
                            <v-btn @click="stop(false)">
                                キャンセル
                            </v-btn>
                            <v-btn color="primary" @click="stop(true)">
                                緊急停止
                            </v-btn>
                        </div>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>


export default {
    name: "ConfirmShutdown",
    props: {
        value: Boolean,
        stopItem: Object
    },
    data() {
        return {
        }
    },
    computed: {
        show: {
            get () {
                return this.value;
            },
            set (value) {
                this.$emit('input', value);
            }
        },
    },
    methods: {
        stop(confirm) {
            this.$emit('stop',{item:this.stopItem,confirm:confirm});
        }
    }
};
</script>

