<template>
  <v-container fluid>
    <Subheader />
    <div class="tables-basic">
      <h4 class="page-title mt-10 mb-6"></h4>
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-form ref="form">
              <v-container>
                <v-row class="flex-column" align="center" justify="center">
                  <v-col>
                    <p
                      class="
                        login-slogan
                        display-1
                        text-center
                        font-weight-medium
                        mt-6
                        mb-10
                      "
                    >
                      クライアント新規追加
                    </p>
                  </v-col>
                  <v-col cols="6" class="mb-10">
                    <v-text-field
                      v-model="name"
                      :rules="[v => !!v || '必須項目のため入力してください']"
                      label="クライアント名"
                      required
                    ></v-text-field>
                    <v-select
                        v-model="profession_id"
                        :items="list_professions"
                        :rules="[v => !!v || '必須項目のため入力してください']"
                        item-text="name"
                        item-value="id"
                        label="業種・業界"
                        required
                    ></v-select>
                    <v-btn
                      class="text-capitalize mt-6"
                      color="primary"
                      width="100%"
                      @click="submit"
                    >
                      クライアント登録する</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import Subheader from '@/components/Subheader/Subheader'

export default {
  name: "CreateClient",
  components: {
      Subheader
  },
  data() {
    return {
      name: "",
      profession_id: null,
    };
  },
  computed: {
      list_professions() {
          return this.$store.getters['client/get_list_professions'];
      }
  },
  methods: {
    fetch_list_professions() {
      this.$store.dispatch("client/fetch_list_professions");
    },
    submit() {
        if(this.$refs.form.validate()) {
            this.$store.dispatch("client/create_client", { name: this.name, profession_id: this.profession_id, $toast: this.$toast, $router: this.$router });
        }
    }
  },
  created() {
      this.fetch_list_professions();
  }
};
</script>

<style src="./Client.scss" lang="scss"></style>
