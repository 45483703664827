var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('Subheader'),_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":true,"color":"#8089ff","loader":"bars"},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"tables-basic"},[_c('h4',{staticClass:"page-title mt-10 mb-6"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"employee-list mb-1"},[_c('v-form',{ref:"form"},[_c('v-container',[_c('v-row',{staticClass:"flex-column",attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('p',{staticClass:"login-slogan display-1 text-center font-weight-medium mt-6 mb-6"},[_vm._v(" クリエイティブ新規追加 ")])]),_c('v-col',{staticClass:"mb-10",attrs:{"cols":"6"}},[_c('div',{staticClass:"create-creative__info"},[_vm._v(" 登録先クライアント名: "+_vm._s(_vm.client_info ? _vm.client_info.name : "")+" ")]),_c('div',{staticClass:"create-creative__info"},[_vm._v(" 登録先キャンペーン名: "+_vm._s(_vm.campaign_info ? _vm.campaign_info.name : "")+" ")]),_c('div',{staticClass:"create-creative__info"},[_vm._v(" 配信期間: "+_vm._s(_vm.campaign_info ? `${_vm.formatDate( _vm.campaign_info.release_start_at )} - ${_vm.formatDate(_vm.campaign_info.release_end_at)}` : "")+" ")]),_c('v-text-field',{attrs:{"rules":[(v) => !!v || '必須項目のため入力してください'],"label":"クリエイティブ名","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-file-input',{attrs:{"accept":"image/*","label":"サムネイルアップロード","append-icon":"mdi-paperclip","prepend-icon":"","rules":[
                      (v) => !!v || '必須項目のため入力してください',
                      (v) =>
                        v
                          ? v.type.indexOf('image') > -1 ||
                            '画像ファイル形式以外のファイルが選択されています。'
                          : '',
                    ]},on:{"change":_vm.handle_upload_thumbnail}}),_c('v-file-input',{attrs:{"accept":"audio/*","label":"音声ファイル","append-icon":"mdi-paperclip","prepend-icon":""},on:{"change":_vm.handle_upload_file_audio}}),_c('v-file-input',{attrs:{"accept":"video/mp4,video/x-m4v,video/*","label":"クリエイティブファイルアップロード1","append-icon":"mdi-paperclip","prepend-icon":"","rules":[
                      (v) => !!v || '必須項目のため入力してください',
                      (v) =>
                        v
                          ? v.type.indexOf('video') > -1 ||
                            '動画ファイル形式以外のファイルが選択されています。'
                          : '',
                    ]},on:{"change":_vm.handle_upload_file}}),_vm._l((14),function(n){return _c('v-file-input',{key:n,attrs:{"accept":"video/mp4,video/x-m4v,video/*","label":`クリエイティブファイルアップロード ${n + 1}`,"append-icon":"mdi-paperclip","prepend-icon":""},on:{"change":function($event){return _vm.handle_upload_files($event, n + 1)}}})}),_c('v-btn',{staticClass:"text-capitalize mt-6",attrs:{"color":"primary","width":"100%"},on:{"click":_vm.submit}},[_vm._v(" クリエイティブ登録する")])],2)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }