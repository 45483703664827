<template>
  <v-container fluid>
      <Subheader :breadcrumbs="breadcrumbs" :disable-back="true" />
      <ConfirmCampaignModal v-model="showConfirmModal" :item="campaign" />
        <DLModal v-model="showDLModal" :url="downloadURL" :title="titleDL" :subtitle="subtitleDL" />

      <v-dialog
        v-model="video_dialog"
        persistent
        max-width="600px"
        >
            <v-card>
                <v-card-title>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <v-row>
                        <iframe width="600" height="400" frameBorder="0" :src="embed_link"></iframe>
                    </v-row>
                </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close_video_modal"
                >
                    閉じる
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    <div class="tables-basic">
      <v-row>
        <v-col cols="12">
            <v-card class="employee-list mb-1">
                <v-card-title class="pa-6 pb-3">
                            <span>{{ facility_name }} &nbsp;&nbsp; {{ campaign ? campaign.name : '' }}</span>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="clickDLReport">全体レポートDL</v-btn>
                        </v-card-title>
                        <v-data-table
                        :headers="headers"
                        :items="list_creatives"
                        :loading="loading"
                        loading-text="読み込み中"
                        :options.sync="options"
                        :items-per-page="1000"
                        hide-default-footer
                        item-key="id"
                        >
                            <template v-slot:[`item.name`]="{ item }">
                                <div class="text-truncate" style="max-width: 200px;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                                        </template>
                                        <span>{{ item.name }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.total_cost`]="{ item }">
                                ¥{{ item.total_cost }}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-menu :key="item.id" offset-y open-on-hover>
                                    <template v-slot:activator="{ attrs, on }">
                                        <span class="text-actions" v-bind="attrs" v-on="on">アクション</span>
                                    </template>

                                    <v-list>
                                        <v-list-item v-for="act in [{text: 'クリエイティブ確認', link: ``, show_video: true}]" :key="act.text" link>
                                            <v-list-item-title v-if="!act.show_video">
                                                <router-link class="action-item" :to="act.link">{{ act.text }}</router-link>
                                            </v-list-item-title>
                                            <v-list-item-title v-else @click="show_video(item.id)">
                                                <span class="action-item">{{ act.text }}</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                            <template v-slot:no-data>
                                データがありません
                            </template>
                        </v-data-table>

                        <div class="d-flex justify-center pa-8 pb-4">
                            <router-link :to="`/facilities/${facility_id}/campaign/ok`"><v-btn color="primary" style="width:300px;" >配信中キャンペーン一覧に戻る</v-btn></router-link>
                        </div>
            </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>


<script>
import ConfirmCampaignModal from '@/components/Popup/ConfirmCampaign'
import Subheader from '@/components/Subheader/Subheader'
import config from "@/config";
import DLModal from '@/components/DLModal'

export default {
  name: "FacilityCampaignCreative",
  components: {
      Subheader,
      ConfirmCampaignModal,
      DLModal
  },
  data() {
    return {
        loading: false,
        showConfirmModal: false,
        campaign_item: null,
        video_dialog: false,
        embed_link: "",
        headers: [
            { text: "クリエイティブ", align: "start", value: "name", width: "200px", sortable: true },
            { text: "表示期間", value: "release_date", sortable: true },
            { text: "施設別 imp数", value: "sum_imp", sortable: true },
            { text: "施設別 支払い金額", value: "total_cost", sortable: true },
            { text: "アクション", value: "actions", sortable: false },
        ],
        options: {},
        showDLModal: false,
        downloadURL: '',
        titleDL: '',
        subtitleDL: '',
    };
  },
  computed: {
      client_id() {
          return this.$route.query.client_id;
      },
      campaign_uuid() {
          return this.$route.query.campaign_uuid;
      },
      facility_id() {
          return this.$route.params.id;
      },
      list_creatives() {
          return this.$store.getters['creative/get_list_creatives_from_ok_campaign'];
      },
      facility_name() {
          return this.$store.getters['facility/get_facility'] ? this.$store.getters['facility/get_facility'].name : '';
      },
      campaign() {
          return this.$store.getters['facility/get_facility_campaign'];
      },
      breadcrumbs() {
          return [
            {
                text: '媒体施設一覧',
                disabled: false,
                underline: true,
                href: '/facilities?page=1',
            },
            {
                text: '施設詳細',
                disabled: false,
                underline: true,
                href: `/facilities/${this.facility_id}/detail`,
            },
            {
                text: '配信中キャンペーン一覧',
                disabled: false,
                underline: true,
                href: `/facilities/${this.facility_id}/campaign/ok`,
            },
            {
                text: '配信中クリエイティブ一覧',
                disabled: true,
                underline: true,
                href: `#`,
            },
        ]
      }
  },
  watch: {
      
  },
  methods: {
    fetch_list_creative() {
        const self = this;
        self.loading = true;

        const payload = { page: 1, per_page: 1000, client_id: this.client_id, facility_id: this.facility_id, campaign_uuid: this.campaign_uuid };
        this.$store.dispatch("creative/fetch_list_creatives_from_ok_campaign", payload).then(() => {
            self.loading = false;
        }).catch(() => {
            self.loading = false;
        });
    },
    clickDLReport() {
        this.downloadURL = `/api/facility/${this.facility_id}/${this.campaign_uuid}/creative/ok/download/csv`;
        this.showDLModal = true;
        this.titleDL = this.campaign ? this.campaign.name : '';
        this.subtitleDL = 'レポートDL';
    },
    show_video(id) {
        const self = this;
        self.isLoading = true;
        self.video_dialog = "";
        this.$store.dispatch("creative/fetch_s3_link", { creative_id: id }).then((res) => {
            if(res.data) {
                if(res.data.data.TemporaryFileVideo) {
                    self.embed_link = res.data.data.TemporaryFileVideo;
                    self.video_dialog = true;
                } else {
                    this.$toast.error("ビデオ情報を取得できません", config.toast);
                }
            } else {
                this.$toast.error("ビデオ情報を取得できません", config.toast);
            }
            self.isLoading = false;
        }).catch(() => {
            this.$toast.error("ビデオ情報を取得できません", config.toast);
            self.isLoading = false;
        });
    },
    close_video_modal() {
        this.video_dialog = false;
        this.embed_link = false;
    },
  },
  mounted() {
      this.fetch_list_creative();
      if(this.$route.params.id) {
          this.$store.dispatch("facility/fetch_facility_by_id", { id: this.$route.params.id })
      }

      if(this.$route.query.campaign_uuid) {
          this.$store.dispatch("facility/fetch_campaign_by_uuid", { uuid: this.$route.query.campaign_uuid })
      }
  },
  created() {
  }
};
</script>
