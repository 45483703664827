<template>
  <v-container fluid>
      <Subheader :breadcrumbs="breadcrumbs" :disable-back="true" />
      <ConfirmCampaignModal v-model="showConfirmModal" :item="campaign_item" :facility_id="facility_id" />


      <v-dialog
        v-model="video_dialog"
        persistent
        max-width="600px"
        >
            <v-card>
                <v-card-title>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <v-row>
                        <iframe width="600" height="400" frameBorder="0" :src="embed_link"></iframe>
                    </v-row>
                </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close_video_modal"
                >
                    閉じる
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    <div class="tables-basic">
      <v-row>
        <v-col cols="12">
            <v-card class="employee-list mb-1">
                <div class="d-flex py-8 align-center flex-column justify-center">
                    <div>
                        <v-btn color="primary" @click="clickConfirmCampaign">掲載可否を回答する</v-btn>
                    </div>
                    <div class="list-creative mt-8" :style="{width: '600px'}">
                        <div v-for="item in list_creatives" :key="item.id" class="px-6 py-6 text-truncate" :style="{textAlign: 'center', border: '1px solid #ddd'}">
                            <a @click="show_video(item.id)">
                                <span  class="ml-2 text-truncate" style="max-width: 150px;">{{ item.name }}</span>
                            </a>
                        </div>
                    </div>
                    <div class="mt-8">
                        <router-link :to="`/facilities/${this.facility_id}/campaign/desired`" :style="{textDecoration: 'none'}"><v-btn>出稿希望キャンペーン一覧に戻る</v-btn></router-link>
                    </div>
                </div>
            </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>


<script>
import ConfirmCampaignModal from '@/components/Popup/ConfirmCampaign'
import Subheader from '@/components/Subheader/Subheader'
import config from "@/config";

export default {
  name: "FacilityCampaignCreative",
  components: {
      Subheader,
      ConfirmCampaignModal
  },
  data() {
    return {
        loading: false,
        showConfirmModal: false,
        campaign_item: null,
        video_dialog: false,
        embed_link: ""
    };
  },
  computed: {
      client_id() {
          return this.$route.query.client_id;
      },
      campaign_uuid() {
          return this.$route.query.campaign_uuid;
      },
      facility_id() {
          return this.$route.params.id;
      },
      list_creatives() {
          return this.$store.getters['creative/get_list_creatives'];
      },
      facility_name() {
          return this.$store.getters['facility/get_facility'] ? this.$store.getters['facility/get_facility'].name : '';
      },
      campaign() {
          return this.$store.getters['facility/get_facility_campaign'];
      },
      breadcrumbs() {
          return [
            {
                text: '媒体施設一覧',
                disabled: false,
                underline: true,
                href: '/facilities?page=1',
            },
            {
                text: this.facility_name,
                disabled: false,
                underline: true,
                href: `/facilities/${this.facility_id}/detail`,
            },
            {
                text: '出稿希望キャンペーン一覧',
                disabled: false,
                underline: true,
                href: `/facilities/${this.facility_id}/campaign/desired`,
            },
            {
                text: this.campaign ? this.campaign.name : '',
                disabled: true,
                underline: true,
                href: `#`,
            },
        ]
      }
  },
  watch: {
      
  },
  methods: {
    fetch_list_creative() {
        const self = this;
        self.loading = true;

        const payload = { page: 1, limit: 1000, client_id: this.client_id, campaign_uuid: this.campaign_uuid };
        this.$store.dispatch("creative/fetch_list_creatives", payload).then(() => {
            self.loading = false;
        }).catch(() => {
            self.loading = false;
        });
    },
    clickConfirmCampaign() {
        this.campaign_item = this.campaign;
        this.showConfirmModal = true;
    },
    show_video(id) {
        const self = this;
        self.isLoading = true;
        self.video_dialog = "";
        this.$store.dispatch("creative/fetch_s3_link", { creative_id: id }).then((res) => {
            if(res.data) {
                if(res.data.data.TemporaryFileVideo) {
                    self.embed_link = res.data.data.TemporaryFileVideo;
                    self.video_dialog = true;
                } else {
                    this.$toast.error("ビデオ情報を取得できません", config.toast);
                }
            } else {
                this.$toast.error("ビデオ情報を取得できません", config.toast);
            }
            self.isLoading = false;
        }).catch(() => {
            this.$toast.error("ビデオ情報を取得できません", config.toast);
            self.isLoading = false;
        });
    },
    close_video_modal() {
        this.video_dialog = false;
        this.embed_link = false;
    },
  },
  mounted() {
      this.fetch_list_creative();
      if(this.$route.params.id) {
          this.$store.dispatch("facility/fetch_facility_by_id", { id: this.$route.params.id })
      }

      if(this.$route.query.campaign_uuid) {
          this.$store.dispatch("facility/fetch_campaign_by_uuid", { uuid: this.$route.query.campaign_uuid })
      }
  },
  created() {
  }
};
</script>
