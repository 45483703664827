import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    Vue.axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      if (error.response.status === 401) {
        // clear localStorage and redirect to login
        JwtService.destroyToken();
        window.location.href = "/login";
      }
      if (error.response.status === 403) {
        // clear localStorage and redirect to 403 page
        JwtService.destroyToken();
        window.location.href = "/403";
      }
      return Promise.reject(error)
    })
    
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(url) {
    return new Promise((resolve, reject) => {
      Vue.axios.get(`${url}`, { headers: { 'Authorization': `Bearer ${JwtService.getToken() ? JwtService.getToken(): ''}` }}).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return new Promise((resolve, reject) => {
      Vue.axios.post(`${resource}`, params, { headers: { 'Authorization': `Bearer ${JwtService.getToken() ? JwtService.getToken(): ''}` }}).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return new Promise((resolve, reject) => {
      Vue.axios.put(`${resource}`, params, { headers: { 'Authorization': `Bearer ${JwtService.getToken() ? JwtService.getToken(): ''}` }}).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },

  /**
   * Send the PUT HTTP request - no authorization
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
   put_to_s3(resource, params, content_type) {
    return new Promise((resolve, reject) => {
      Vue.axios.put(`${resource}`, params, { headers: { 'Content-Type': content_type,'Cache-Control':'max-age=604800' } }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },

  /**
   * Send the GET HTTP request for download
   * @param resource
   * @param slug
   * @returns {*}
   */
   get_download(url) {
    return new Promise((resolve, reject) => {
      Vue.axios.get(`${url}`, { headers: { 'Authorization': `Bearer ${JwtService.getToken() ? JwtService.getToken(): ''}` }, responseType: 'blob'}).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    })
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource, { headers: { 'Authorization': `Bearer ${JwtService.getToken() ? JwtService.getToken(): ''}` }}).catch(error => {
      // console.log(error);
      throw new Error(`[ADBOX] ApiService ${error}`);
    });
  }
};

export default ApiService;
