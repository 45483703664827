export default {
    get_campaign_master(state) {
        return state.campaign_master;
    },
    get_campaign_address(state) {
        return state.campaign_address;
    },
    get_campaign_master_branch(state) {
        return state.campaign_master_branch;
    },
    get_list_campaigns(state) {
        return state.list_campaigns;
    },
    get_campaigns_meta(state) {
        return state.campaigns_meta;
    },
    get_campaign(state) {
        return state.campaign;
    },
}
