import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/Layout/Layout';

// Pages
import Error from "@/pages/Error/Error";
import Login from "@/pages/Login/Login";

import ListClient from '@/pages/Client/List'
import EditClient from '@/pages/Client/Edit'
import CreateClient from '@/pages/Client/Create'
import ListCampaign from '@/pages/Campaign/List'
import EditCampaign from '@/pages/Campaign/Edit'
import CreateCampaign from '@/pages/Campaign/Create'
import ListCreative from '@/pages/Creative/List'
import EditCreative from '@/pages/Creative/Edit'
import CreateCreative from '@/pages/Creative/Create'
import CreateFacility from '@/pages/Facility/Create'
import ListFacility from '@/pages/Facility/List'
import ListFacilityByCampaign from '@/pages/Facility/ListByCampaign'
import DetailFacility from '@/pages/Facility/Detail'
import Toilet from '@/pages/Facility/Toilet'
import DesiredCampaign from '@/pages/Facility/Campaign/Desired'
import MovieFacility from '@/pages/Facility/Movie'
import OkCampaign from '@/pages/Facility/Campaign/Ok'
import NGCampaign from '@/pages/Facility/Campaign/NG'
import DesiredCreative from '@/pages/Facility/Campaign/DesiredCreative'
import OkCreative from '@/pages/Facility/Campaign/OkCreative'
import ListToilet from '@/pages/Toilet/List'

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/',
      redirect: 'login',
      name: 'Layout',
      component: Layout,
      children: [
        {
          path: '/clients',
          name: 'Clients',
          redirect: '/clients',
          component: () => import("@/pages/Client/Layout.vue"),
          children: [
            {
              path: '/',
              name: 'ListClient',
              component: ListClient,
            },
            {
              path: 'create',
              name: 'CreateClient',
              component: CreateClient,
            },
            {
              path: 'edit/:id',
              name: 'EditClient',
              component: EditClient,
            },
            {
              path: 'campaign/facilities/:campaign_uuid',
              name: 'ListFacilityByCampaign',
              component: ListFacilityByCampaign,
            },
          ]
        },
        {
          path: '/facilities',
          name: 'Facilities',
          redirect: '/facilities',
          component: () => import("@/pages/Facility/Layout.vue"),
          children: [
            {
              path: '/',
              name: 'ListFacility',
              component: ListFacility,
            },
            {
              path: 'create',
              name: 'CreateFacility',
              component: CreateFacility,
            },
            {
              path: ':id/detail',
              name: 'DetailFacility',
              component: DetailFacility,
            },
            {
              path: ':id/toilet',
              name: 'Toilet',
              component: Toilet,
            },
            {
              path: ':id/campaign/desired',
              name: 'DesiredCampaign',
              component: DesiredCampaign,
            },
            {path: ':id/movie',
              name: 'MovieFacility',
              component: MovieFacility,
            },
            {
              path: ':id/campaign/ok',
              name: 'OkCampaign',
              component: OkCampaign,
            },
            {
              path: ':id/campaign/ng',
              name: 'NGCampaign',
              component: NGCampaign,
            },
            {
              path: ':id/desired_creatives',
              name: 'DesiredCreative',
              component: DesiredCreative,
            },
            {
              path: ':id/ok_creatives',
              name: 'OkCreative',
              component: OkCreative,
            },
          ]
        },
        {
          path: '/campaigns',
          name: 'Campaigns',
          redirect: '/campaigns',
          component: () => import("@/pages/Campaign/Layout.vue"),
          children: [
            {
              path: '/',
              name: 'ListCampaign',
              component: ListCampaign,
            },
            {
              path: 'create',
              name: 'CreateCampaign',
              component: CreateCampaign,
            },
            {
              path: 'edit/:id',
              name: 'EditCampaign',
              component: EditCampaign,
            },
          ]
        },
        {
          path: '/creatives',
          name: 'Creatives',
          redirect: '/creatives',
          component: () => import("@/pages/Creative/Layout.vue"),
          children: [
            {
              path: '/',
              name: 'ListCreative',
              component: ListCreative,
            },
            {
              path: 'create',
              name: 'CreateCreative',
              component: CreateCreative,
            },
            {
              path: 'edit/:id',
              name: 'EditCreative',
              component: EditCreative,
            },
          ]
        },
        {
          path: '/toilets',
          name: 'Toilets',
          redirect: '/toilets',
          component: () => import("@/pages/Toilet/Layout.vue"),
          children: [
            {
              path: '/',
              name: 'ListToilet',
              component: ListToilet,
            },
          ]
        },
      ],
    },
    {
      path: '*',
      name: 'Error',
      component: Error
    }
  ],
});
